import React, { createContext, useContext, useEffect, useState, ReactNode, useRef  } from 'react';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { useAuth } from "@clerk/clerk-react";
import { Upload } from 'tus-js-client';
import va from '@vercel/analytics';
import { forEach } from 'lodash';

// Replace these with your Supabase project details
const projectURL = "https://auth.vetworks.io";
const projectKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZmbHVmanBveXRqZXhjbXBvbmlrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDM0NTI5NTcsImV4cCI6MjAxOTAyODk1N30.pGs-6h3KMjXq3wWfdwJQXHVDnF1A_Zjweq02fYLBFHc";

interface SupabaseContextType {
    uploadToSupabaseSignedURL: (file: any, fileName: string, bucket: string, token: string) => Promise<void>;
    uploadRecordingToSupabase: (file: any, fileName: string, bucket: string, contentType: string, token:string, sessionId:string, setUploadPercentage:(value:number) => void) => Promise<void>;
    getCurrentStateOfNotes: (session:string) => Promise<string>,
    getCurrentStateOfDischarge: (session:string) => Promise<string>,
    getCurrentStateOfPreHistory: (session:string) => Promise<string>,
    subscribeToNotesStream: (session:string, method:(value:string) => void, completed:() => void) => Promise<void>,
    removeNotesSubscriptions: () => Promise<void>,
    subscribeToPreHistoryStream: (session:string, method:(value:string) => void, completed:() => void) => Promise<void>,
    removePreHistorySubscriptions: () => Promise<void>,
    subscribeToDischargeStream: (session:string, method:(value:string) => void, completed:() => void) => Promise<void>,
    removeDischargeSubscriptions: () => Promise<void>,
}

const SupabaseContext = createContext<SupabaseContextType>({
    uploadToSupabaseSignedURL: async () => { throw new Error('uploadToSupabaseSignedURL not implemented'); },
    uploadRecordingToSupabase: async () => { throw new Error('uploadRecordingToSupabase not implemented'); },
    getCurrentStateOfNotes: async () => { throw new Error('getCurrentStateOfNotes not implemented'); },
    getCurrentStateOfDischarge: async () => { throw new Error('getCurrentStateOfDischarge not implemented'); },
    getCurrentStateOfPreHistory: async () => { throw new Error('getCurrentStateOfPreHistory not implemented'); },
    subscribeToNotesStream: async () => { throw new Error('subscribeToNotesStream not implemented'); },
    removeNotesSubscriptions: async () => { throw new Error('removeSubscriptions not implemented'); },
    subscribeToPreHistoryStream: async () => { throw new Error('subscribeToPreHistoryStream not implemented'); },
    removePreHistorySubscriptions: async () => { throw new Error('removePreHistorySubscriptions not implemented'); },
    subscribeToDischargeStream: async () => { throw new Error('subscribeToDischargeStream not implemented'); },
    removeDischargeSubscriptions: async () => { throw new Error('removeDischargeSubscriptions not implemented'); },
});

export const useSupabase = () => useContext(SupabaseContext);

interface SupabaseProviderProps {
    children: ReactNode; // Using ReactNode for the type of children
}

export const SupabaseProvider: React.FC<SupabaseProviderProps> = ({ children }) => {
    const { getToken } = useAuth(); // Using Clerk's useAuth hook


    const getSupabase = async () : Promise<SupabaseClient> => {
        const token = await getToken({ template: 'supabase' }); // Implement this function based on how you fetch the token
        const supabaseClient = createClient(projectURL, projectKey, {
            auth: { autoRefreshToken: true },
            global: { headers: { Authorization: `Bearer ${token}` } },
        });
        supabaseClient.realtime.accessToken = token;
        return supabaseClient
    }
    
    const uploadRecordingToSupabase = async (file: any, fileName:string, bucket:string, contentType:string, token:string, sessionId:string, setUploadPercentage:(value:number)=>void) => {
        if (!token) {
            console.error('Error uploading recording: token is undefined');
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: sessionId,
            }
            va.track("Signed_Token_Undefined", properties)
        }
        else{
            return new Promise<void>(async (resolve, reject) => {
                var upload = new Upload(file, {
                    endpoint: projectURL + `/storage/v1/upload/resumable/sign`,
                    retryDelays: [0, 3000, 5000, 10000, 20000],
                    headers: {
                        'x-signature': token ?? "",
                        'x-upsert': 'true',
                    },
                    uploadDataDuringCreation: true,
                    removeFingerprintOnSuccess: true,
                    metadata: {
                        bucketName: bucket,
                        objectName: fileName,
                        contentType: contentType,
                        cacheControl: "3600",
                    },
                    chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
                    onError: function (error: any) {
                        reject(error)
                        throw new Error(`Error uploading files: ${error}`);
                    },
                    onProgress: function (bytesUploaded: number, bytesTotal: number) {
                        setUploadPercentage(Math.round((bytesUploaded / bytesTotal) * 100))
                    },
                    onSuccess: function () {
                        resolve()
                    },
                })
    
    
                // Check if there are any previous uploads to continue.
                return upload.findPreviousUploads().then(function (previousUploads: string | any[]) {
                    // Found previous uploads so we select the first one.
                    if (previousUploads.length) {
                        upload.resumeFromPreviousUpload(previousUploads[0])
                    }
    
                    // Start the upload
                    upload.start()
                })
            })
        }
    }   

    const uploadToSupabaseSignedURL = async (file: any, fileName:string, bucket:string, token:string) => {
        try {
            let supabase = await getSupabase()
            const { error } = await supabase.storage.from(bucket).uploadToSignedUrl(fileName, token, file, {
                upsert: true,
            });
            if (error) {
            console.error('Error uploading file:', error.message);
            throw new Error(error.message); // Properly throw an error on failure
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            throw new Error(`Error uploading file: ${error}`);
        }
    }   

    const getCurrentStateOfNotes =  async (session:string): Promise<string> => {
        let supabase = await getSupabase()
        const { data, error } = await supabase.from('notes_changes').select().eq("session_id", session)
        if(!data || data[0]['text'] == ""){
            return "Writing notes..."
        }
        return data[0]['text'] == "" ? "Writing notes..." : data[0]['text']
    }

    const getCurrentStateOfDischarge =  async (session:string): Promise<string> => {
        let supabase = await getSupabase()
        const { data, error } = await supabase.from('discharge_changes').select().eq("session_id", session)
        if(!data || data[0]['text'] == ""){
            return "Writing notes..."
        }
        return data[0]['text'] == "" ? "Writing notes..." : data[0]['text']
    }

    const getCurrentStateOfPreHistory =  async (session:string): Promise<string> => {
        let supabase = await getSupabase()
        const { data, error } = await supabase.from('prehistory_changes').select().eq("session_id", session)
        if(!data || data[0]['text'] == ""){
            return "{}"
        }
        return data[0]['text'] == "" ? "{}" : data[0]['text']
    }

    const subscribeToNotesStream = async (session:string, method: (value:string) => void, completed: () => void) => {
        let supabase = await getSupabase()
        let channels = supabase.getChannels()
        forEach(channels, async (value, key) => {
            if(value.topic === 'notes_changes'){
                await supabase.removeChannel(value)
            }
        })
        supabase.channel('notes_changes').on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'notes_changes', filter: `session_id=eq.${session}`}, async payload => {
            if(payload['new']['text']){
                method(payload['new']['text'])
            }
            if(payload['new']['completed'] == true){
                let channels = supabase.getChannels()
                forEach(channels, async (value, key) => {
                    if(value.topic.includes('notes_changes')){
                        await supabase.removeChannel(value)
                    }
                })
                completed()
            }
        }).subscribe()
    }

    const subscribeToPreHistoryStream = async (session:string, method: (value:string) => void, completed: () => void) => {
        let supabase = await getSupabase()
        let channels = supabase.getChannels()
        forEach(channels, async (value, key) => {
            if(value.topic.includes('prehistory_changes')){
                await supabase.removeChannel(value)
            }
        })
        supabase.channel('prehistory_changes').on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'prehistory_changes', filter: `session_id=eq.${session}`}, async payload => {
            if(payload['new']['text']){
                method(payload['new']['text'])
            }
            if(payload['new']['completed'] == true){
                let channels = supabase.getChannels()
                forEach(channels, async (value, key) => {
                    if(value.topic === 'prehistory_changes'){
                        await supabase.removeChannel(value)
                    }
                })
                completed()
            }
        }).subscribe()
    }

    const subscribeToDischargeStream = async (session:string, method: (value:string) => void, completed: () => void) => {
        let supabase = await getSupabase()
        let channels = supabase.getChannels()
        forEach(channels, async (value, key) => {
            if(value.topic === 'discharge_changes'){
                await supabase.removeChannel(value)
            }
        })
        supabase.channel('discharge_changes').on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'discharge_changes', filter: `session_id=eq.${session}`}, async payload => {
            if(payload['new']['text']){
                method(payload['new']['text'])
            }
            if(payload['new']['completed'] == true){
                let channels = supabase.getChannels()
                forEach(channels, async (value, key) => {
                    if(value.topic.includes('discharge_changes')){
                        await supabase.removeChannel(value)
                    }
                })
                completed()
            }
        }).subscribe()
    }

    const removeNotesSubscriptions = async () => {
        let supabase = await getSupabase()
        let channels = supabase.getChannels()
        forEach(channels, async (value, key) => {
            if(value.topic.includes('notes_changes')){
                await supabase.removeChannel(value)
            }
        })
    }

    const removePreHistorySubscriptions = async () => {
        let supabase = await getSupabase()
        let channels = supabase.getChannels()
        forEach(channels, async (value, key) => {
            if(value.topic.includes('prehistory_changes')){
                await supabase.removeChannel(value)
            }
        })
    }

    const removeDischargeSubscriptions = async () => {
        let supabase = await getSupabase()
        let channels = supabase.getChannels()
        forEach(channels, async (value, key) => {
            if(value.topic.includes('discharge_changes')){
                await supabase.removeChannel(value)
            }
        })
    }

    const value = {
        uploadToSupabaseSignedURL,
        uploadRecordingToSupabase,
        getCurrentStateOfNotes,
        getCurrentStateOfPreHistory,
        subscribeToNotesStream,
        subscribeToPreHistoryStream,
        removeNotesSubscriptions,
        removePreHistorySubscriptions,
        getCurrentStateOfDischarge,
        subscribeToDischargeStream,
        removeDischargeSubscriptions
      };

    return <SupabaseContext.Provider value={value}>{children}</SupabaseContext.Provider>;
};