export const ItemTypes = {
    SECTION: 'section',
    RENDERED_SECTION: 'rendered_section',
    RENDERED_ENTRY: "rendered_entry",
    ENTRY: 'entry',
    VITALS: 'vitals',
    PHYSICAL_EXAM: 'physical_exam',
    SUBJECTIVE: 'subjective',
    ASSESSMENT: 'assessment',
    PLAN: 'plan',
    FEAR_FREE: 'fear_free',
    OWNER_DISCUSSION: "owner_discussion",
    CURRENT_MEDICATION: "current_medication",
    CURRENT_DIET: "current_diet",
    CURRENT_PREVENTATIVES: "current_preventatives",
    CHRONIC_ISSUES: "chronic_issues",
    DIAGNOSTIC_TESTS: "diagnostic_tests",
    TREATMENTs: "treatments",
    VACCINES: "vaccines",
    MEDICATION: "medication",
    DIET: "diet"
}
