import { Fragment, useState, useEffect } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { deleteTemplate, fetchOrgTemplates, fetchTemplateLibrary, fetchTemplates, postDefaultDischargeTemplate, postDefaultTemplate, templateRequest } from './ServerActions';
import { DocumentDuplicateIcon, StarIcon, TrashIcon, VideoCameraIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { TemplateObject } from './utils/TemplateObject';
import { Spinner } from './utils/Spinner';
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react"
import bgAnimals from './assets/joey writer.png'
import va from '@vercel/analytics';
import { classNames } from './utils/ClassUtils';
import { useNavigate } from 'react-router-dom';
import Remainder from './components/remainder';
import MoreMenu from './components/moreMenu';
import TemplateDeleteModal from './components/templates/TemplateDeleteModal';
import ErrorAlert from './components/Error';
import { tab } from '@testing-library/user-event/dist/tab';
import { filter, set } from 'lodash';
import { TemplateType } from './components/templates/TemplateUtils';
import { preBuiltDischargeTemplate, preBuiltTemplate } from './utils/prebuiltTemplates';

// Define the types for your props
interface TemplatesProps {

}

enum TemplateSource {
    custom = "custom",
    soap_template = "basic",
    soap_advanced = "advanced"
}

export default function Templates(props:TemplatesProps) {
    const [loadingTemplates , setLoadingTemplates] = useState<string|undefined>(undefined)
    const [templates, setTemplates] = useState<TemplateObject[]>()
    const [filteredTemplates, setFilteredTemplates] = useState<TemplateObject[]>()
    const [orgTemplates, setOrgTemplates] = useState<TemplateObject[]>()
    const [filteredOrgTemplates, setFilteredOrgTemplates] = useState<TemplateObject[]>()
    const [templateLibrary, setTemplateLibrary] = useState<TemplateObject[]>()
    const [templateLibraryFiltered, setTemplateLibraryFiltered] = useState<TemplateObject[]>()
    const [preBuiltTemplatesFiltered, setPreBuiltTemplatesFiltered] = useState<TemplateObject[]>()
    const [error, setError] = useState<string|undefined>(undefined)
    const [errorTimer, setErrorTimer] = useState<number>(0);
    const { getToken, orgId} = useAuth();
    const { user } = useUser()
    const {organization} = useOrganization()
    const [sending, setSending] = useState<boolean>(false)
    const [sent, setSent] = useState<boolean>(false)
    const [request, setRequest] = useState<boolean>(false)
    const [templateDelete, setTemplateDelete] = useState<boolean>(false)
    const [templateToDelete, setTemplateToDelete] = useState<string>()
    const [defaultTemplate, setDefaultTemplate] = useState<string>((user?.publicMetadata['default_template_id'] as string) ?? 'dynamic_soap_notes');
    const [defaultDischargeTemplate, setDefaultDischargeTemplate] = useState<string>((user?.publicMetadata['default_discharge_template_id'] as string) ?? 'dynamic_simple_discharge');
    const [tab, setTab] = useState<string>("yours")
    const [type, setType] = useState<string>("all")

    const  navigate = useNavigate()

    const filterTemplates = (templates:TemplateObject[], type:string) => {
        if(type == "all"){
            return templates
        }
        else if(type == "medical"){
            return templates.filter(template => template.type == "medical")
        }
        else if(type == "communications"){
            return templates.filter(template => template.type == "communications")
        }
    }

    const getTemplates = async () => {
        setLoadingTemplates("Loading templates 📃...")
        let temp_templates : TemplateObject[] = await fetchTemplates(await getToken({template:"supabase"}) ?? "")
        if(organization)
        {
            let temp_org_template = await getOrganizationTemplates()
            if (temp_org_template && temp_templates){
                temp_templates = temp_templates.filter(template => !temp_org_template.some(template2 => template2.id === template.id))
            }
        }
        let template_library:TemplateObject[] = await fetchTemplateLibrary(await getToken({template:"supabase"}) ?? "")
        let english_templates = template_library.filter(template => !template.id.includes("spanish"))
        setTemplateLibraryFiltered(filterTemplates(english_templates, type))
        setTemplateLibrary(english_templates)
        setTemplates(temp_templates)
        setFilteredTemplates(filterTemplates(temp_templates, type))
        setLoadingTemplates(undefined)
    }

    const getOrganizationTemplates = async () => {
        setLoadingTemplates("Loading templates 📃...")
        let temp_templates : TemplateObject[] = await fetchOrgTemplates(await getToken({template:"supabase"}) ?? "")
        setOrgTemplates(temp_templates)
        setFilteredOrgTemplates(filterTemplates(temp_templates, type))
        setLoadingTemplates(undefined)
        return temp_templates
    }

    useEffect(() => {
        getTemplates()
    }, [orgId])

    useEffect(() => {
        setFilteredTemplates(filterTemplates(templates ?? [], type))
        setFilteredOrgTemplates(filterTemplates(orgTemplates ?? [], type))
        setTemplateLibraryFiltered(filterTemplates(templateLibrary ?? [], type))
        setPreBuiltTemplatesFiltered(filterTemplates([...preBuiltTemplate, ...preBuiltDischargeTemplate] ?? [], type))
    }, [type])

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (error) {
            setErrorTimer(0); // Reset progress
            timer = setTimeout(() => {
                setError(undefined);
            }, 3000); // 5000ms = 5 seconds
    
            // Update progress every 50ms
            interval = setInterval(() => {
                setErrorTimer((oldProgress) => {
                    if (oldProgress < 100) {
                        return oldProgress + 1;
                    } else {
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, 30);
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [error]);

    function createTemplateBuilder(): void {
        navigate('/templateBuilder')
    }

    async function editTemplate(template: TemplateObject): Promise<void> {
        if(template.owner === "VetRec" || template.owner == "VetRec_Comms"){
            navigate(`/templateBuilder?templateId=${template.id.split("dynamic_")[1]}&duplicate=true`)
        }
        else{
            navigate(`/templateBuilder?templateId=${template.id.split("dynamic_")[1]}`)
        }
    }

    async function duplicateTemplate(template: TemplateObject, type:TemplateSource): Promise<void> {
        if(template.owner === "VetRec" || template.owner == "VetRec_Comms"){
            navigate(`/templateBuilder?templateId=${template.id.split("dynamic_")[1]}&duplicate=true`)
            let properties =  {
                date:(new Date()).toUTCString(),
                template: template.id,
            }
            va.track(`Template_Library_Dupe`, properties)
        }
        else{
            navigate(`/templateBuilder?templateId=${template.id.split("dynamic_")[1]}&duplicate=true`)
        }
    }

    async function handleDeleteTemplate(template_id:string): Promise<void> {
        setTemplateDelete(true)
        setTemplateToDelete(template_id)
    }

    async function confirmDeleteTemplate() {
        await deleteTemplate(templateToDelete ?? "", await getToken({template:"supabase"}) ?? "" )
        setTemplateDelete(false)
        setTemplateToDelete(undefined)
        getTemplates()
        getOrganizationTemplates()
    }

    async function handleDefaultTemplate(template_id:string, type:string): Promise<void>{
        if(type == TemplateType.MEDICAL){
            setDefaultTemplate(template_id)
            await postDefaultTemplate(template_id,  await getToken({template:"supabase"}) ?? "")   
        } else if(type == TemplateType.COMMUNICATIONS){
            setDefaultDischargeTemplate(template_id)
            await postDefaultDischargeTemplate(template_id,  await getToken({template:"supabase"}) ?? "")
        }
    }

    function sleep(ms: number | undefined) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function handleTemplateRequest(){
        setRequest(true)
        setSending(true)
        await templateRequest(await getToken({template:"supabase"}) ?? "")
        await sleep(500)
        setSent(true)
        setSending(false)
        await sleep(500)
        setSent(false)
        let properties =  {
            date:(new Date()).toUTCString(),
        }
        va.track(`Template_Request`, properties)
    }

    function capitalize(s: string) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    return(<>
        <div className="border-b border-gray-400 pb-5 mb-5 flex flex-col justify-between gap-y-2 py-4 sm:pt-10">
            <div className='flex flex-row justify-between items-center'>
                <div>
                    <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                        Templates
                    </h2>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">
                        Create new templates or modify presets to let VetRec AI know how to organize and present your notes.
                    </p>
                </div>
                <button
                type="button"
                className="ml-3 inline-flex gap-x-2 items-center rounded-md bg-main-button px-3 py-2 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 h-12"
                onClick={() => createTemplateBuilder()}
                >
                    <PlusIcon className="-mr-0.5 sm:h-5 sm:w-5 h-8 w-8" aria-hidden="true" />
                    <div className=''>Create template</div>
                </button>
            </div>
        </div>
        <div className='bg-accent w-full shadow-lg rounded-lg py-2 px-4 flex flex-col sm:flex-row justify-between items-center gap-x-8 overflow-hidden gap-y-8'>
            <div className='flex flex-col sm:flex-row gap-x-6 items-center justify-between gap-y-6 h-full grow w-full sm:w-2/3'>
                <div className='text-center lg:text-left'>
                    <span className='font-semibold'>Customize your templates to fit your needs.</span> VetRec supports templates from all specialties and practices.
                </div>
                <div className='flex gap-x-4'>
                    <a
                        href='https://www.youtube.com/watch?v=zHQYnEBsBU0&pp=ygUJdmV0cmVjIGlv'
                        className="inline-flex items-center gap-x-3 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center min-w-36  sm:min-w-48"
                        target='_blank'
                    >
                        <VideoCameraIcon className="-mr-0.5 sm:h-5 sm:w-5 h-8 w-8" aria-hidden="true" />
                        Watch Tutorial
                    </a>
                    <a
                        className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center min-w-36 sm:min-w-48"
                        onClick={() => handleTemplateRequest()}
                        target='_blank'
                        href='https://calendly.com/kevincohen-vetrec/vetrec-intro-call-with-founder'
                    >
                        {sending  ? "Notifying" : sent  ? "Notified" : "Request Demo"}
                    </a>
                </div>
            </div>
        </div>
        {loadingTemplates && <div className='flex flex-col justify-center items-center pt-12 gap-y-6'>
            {loadingTemplates}
            <Spinner size='w-10 h-10' timer={false}/>
        </div>}
        {!loadingTemplates && <div className='flex flex-col gap-y-10 mb-28 mt-8'>
            <TemplateTabs tab={tab} handleTabChange={setTab} type={type} handleTypeChange={setType}/>
            {tab == "yours" && <div className='flex flex-col gap-y-10'>
                {filteredTemplates && filteredTemplates?.length > 0 && <div>
                    <h2 className="text-sm font-medium text-gray-500">Your templates</h2>
                    <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        {filteredTemplates.map((template) => (
                        <li key={template.name} className="col-span-1 flex flex-row items-center rounded-md shadow-sm h-20">
                            <div className="bg-main flex w-16 h-full flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-main-text">
                                {template.id == defaultTemplate || template.id == defaultDischargeTemplate ? <StarIcon className='h-5 w-5' /> : template.name[0]}
                            </div>
                            <div className={`flex flex-1 min-w-0 h-full pl-4 pr-2 py-2 items-center justify-between rounded-r-md border-b border-r border-t border-gray-400 hover:bg-gray-200 ${template.id.includes("vetworks") || !template.id.includes("dynamic") ? "text-gray-500 bg-gray-200" : "bg-white cursor-pointer "}`}>
                                <div className={`flex-1 min-w-0 truncate text-sm ${template.id.includes("vetworks") || !template.id.includes("dynamic") ? "pointer-events-none" : ""} flex flex-col gap-y-1`} onClick={() => editTemplate(template)}>
                                    {template.name}
                                    <span className={`px-2 py-0.5 ${template.type == "medical" || !template.type ? "bg-red-100" : "bg-blue-100"} rounded-md w-fit`}>{capitalize(template.type == "communications" ? "Client" : "notes")}</span>
                                    <span className='text-xs'>{template.id.includes("vetworks") || !template.id.includes("dynamic") ? "Not available for editing. Contact support@vetrec.io." : ""}</span>
                                </div>
                                {(!template.id.includes("vetworks") && template.id.includes("dynamic")) && <MoreMenu options={[{
                                    name:"Duplicate",
                                    action: () => duplicateTemplate(template, TemplateSource.custom),
                                    icon:<DocumentDuplicateIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                },
                                {
                                    name:"Delete",
                                    action: () => handleDeleteTemplate(template.id),
                                    icon:<TrashIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                },
                                {
                                    name:"Set as default",
                                    action: () => handleDefaultTemplate(template.id, template.type ?? TemplateType.MEDICAL),
                                    icon:<StarIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                }]} height="h-12 border-0"/>}
                                {(template.id.includes("vetworks") && !template.id.includes("dynamic")) && <MoreMenu options={[
                                {
                                    name:"Set as default",
                                    action: () => handleDefaultTemplate(template.id, template.type ?? TemplateType.MEDICAL),
                                    icon:<StarIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                }]} height="h-12 border-0"/>}
                            </div>
                        </li>
                        ))}
                    </ul>
                </div>}
                {filteredOrgTemplates && filteredOrgTemplates?.length > 0 && <div>
                    <h2 className="text-sm font-medium text-gray-500">Team templates</h2>
                    <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        {filteredOrgTemplates.map((template) => (
                        <li key={template.name} className="col-span-1 flex flex-row items-center rounded-md shadow-sm h-20">
                            <div className="bg-main flex w-16 h-full flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-main-text">
                                {template.id == defaultTemplate || template.id == defaultDischargeTemplate ? <StarIcon className='h-5 w-5' /> : template.name[0]}
                            </div>
                            <div className={`flex flex-1 min-w-0 h-full pl-4 pr-2 py-2 items-center justify-between rounded-r-md border-b border-r border-t border-gray-400 hover:bg-gray-200 ${template.id.includes("vetworks") || !template.id.includes("dynamic") ? "text-gray-500 bg-gray-200" : "bg-white cursor-pointer "}`}>
                                <div className={`flex-1 min-w-0 truncate text-sm ${template.id.includes("vetworks") || !template.id.includes("dynamic") ? "pointer-events-none" : ""} flex flex-col gap-y-1`} onClick={() => editTemplate(template)}>
                                    {template.name}
                                    <span className={`px-2 py-0.5 ${template.type == "medical" || !template.type ? "bg-red-100" : "bg-blue-100"} rounded-md w-fit`}>{capitalize(template.type == "communications" ? "Client" : "notes")}</span>
                                    <span className='text-xs'>{template.id.includes("vetworks") || !template.id.includes("dynamic") ? "Not available for editing. Contact support@vetrec.io." : `Owner: ${template.owner.split('_Comms')[0]}`}</span>
                                </div>
                                {(!template.id.includes("vetworks") && template.id.includes("dynamic")) && <MoreMenu options={[{
                                    name:"Duplicate",
                                    action: () => duplicateTemplate(template, TemplateSource.custom),
                                    icon:<DocumentDuplicateIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                },
                                {
                                    name:"Delete",
                                    action: () => handleDeleteTemplate(template.id),
                                    icon:<TrashIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                },
                                {
                                    name:"Set as default",
                                    action: () => handleDefaultTemplate(template.id, template.type ?? TemplateType.MEDICAL),
                                    icon:<StarIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                }]} height="h-12 border-0"/>}
                                {(template.id.includes("vetworks") && !template.id.includes("dynamic")) && <MoreMenu options={[
                                {
                                    name:"Set as default",
                                    action: () => handleDefaultTemplate(template.id, template.type ?? TemplateType.MEDICAL),
                                    icon:<StarIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                }]} height="h-12 border-0"/>}
                            </div>
                        </li>
                        ))}
                    </ul>
                </div>}
                {preBuiltTemplatesFiltered && <div>
                    <h2 className="text-sm font-medium text-gray-500">Pre-Built templates</h2>
                    <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        {preBuiltTemplatesFiltered.map((template) => (
                        <li key={template.name} className="col-span-1 flex flex-row items-center rounded-md shadow-sm h-20">
                            <div className="bg-main flex w-16 h-full flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-main-text">
                                {template.id == defaultTemplate || template.id == defaultDischargeTemplate ? <StarIcon className='h-5 w-5' /> : template.name[0]}
                            </div>
                            <div className={`flex flex-1 min-w-0 h-full pl-4 pr-2 py-2 items-center justify-between rounded-r-md border-b border-r border-t border-gray-400 hover:bg-gray-200 ${template.id.includes("vetworks") || !template.id.includes("dynamic") ? "text-gray-500 bg-gray-200" : "bg-white cursor-pointer "}`}>
                                <div className={`flex-1 min-w-0 truncate text-sm ${template.id.includes("vetworks") || !template.id.includes("dynamic") ? "pointer-events-none" : ""} flex flex-col gap-y-1`} onClick={() => editTemplate(template)}>
                                    {template.name}
                                    <span className={`px-2 py-0.5 ${template.type == "medical" || !template.type ? "bg-red-100" : "bg-blue-100"} rounded-md w-fit`}>{capitalize(template.type == "communications" ? "Client" : "notes")}</span>
                                    <span className='text-xs'>{template.id.includes("vetworks") || !template.id.includes("dynamic") ? "Not available for editing. Contact support@vetrec.io." : `Owner: ${template.owner.split('_Comms')[0]}`}</span>
                                </div>
                                {(!template.id.includes("vetworks") && template.id.includes("dynamic")) && <MoreMenu options={[{
                                    name:"Duplicate",
                                    action: () => duplicateTemplate(template, TemplateSource.custom),
                                    icon:<DocumentDuplicateIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                },
                                {
                                    name:"Delete",
                                    action: () => handleDeleteTemplate(template.id),
                                    icon:<TrashIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                },
                                {
                                    name:"Set as default",
                                    action: () => handleDefaultTemplate(template.id, template.type ?? TemplateType.MEDICAL),
                                    icon:<StarIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                }]} height="h-12 border-0"/>}
                                {(template.id.includes("vetworks") && !template.id.includes("dynamic")) && <MoreMenu options={[
                                {
                                    name:"Set as default",
                                    action: () => handleDefaultTemplate(template.id, template.type ?? TemplateType.MEDICAL),
                                    icon:<StarIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                }]} height="h-12 border-0"/>}
                            </div>
                        </li>
                        ))}
                    </ul>
                </div>}
            </div>}
            {tab == "library" && <div className='flex flex-col gap-y-10'>  
                {templateLibraryFiltered && <div>
                    <h2 className="text-sm font-medium text-gray-500">Template Library</h2>
                    <p className='text-sm font-regular text-gray-500 mt-2 mb-2'>The template library includes curated templates developed by our team to help you get started. Choose from our selection and add them to your account. Customize them using our template builder to fit your style.</p>
                    <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
                        {templateLibraryFiltered.map((template) => (
                        <li key={template.name} className="col-span-1 flex rounded-md shadow-sm h-16">
                            <div className={classNames(
                                "bg-main", 'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-main-text'
                            )}
                            >
                                {template.id == defaultTemplate ? <StarIcon className='h-5 w-5' /> : template.name[0]}
                            </div>
                            <div className={`flex flex-1 min-w-0 h-full pl-4 pr-2 py-2 items-center justify-between rounded-r-md border-b border-r border-t border-gray-400 hover:bg-gray-200 ${template.id.includes("vetworks") || !template.id.includes("dynamic") ? "text-gray-500 bg-gray-200" : "bg-white cursor-pointer "}`}>
                                <div className="flex-1 truncate text-sm flex flex-col gap-y-1" onClick={() => editTemplate(template)}>
                                    {template.name}
                                    <span className={`px-2 py-0.5 ${template.type == "medical" || !template.type ? "bg-red-100" : "bg-blue-100"} rounded-md w-fit`}>{capitalize(template.type == "communications" ? "Client" : "notes")}</span>
                                </div>
                                
                                <MoreMenu height='h-12' options={[{
                                    name:"Add to account",
                                    action: () => duplicateTemplate(template, TemplateSource[template.id as keyof typeof TemplateSource]),
                                    icon:<DocumentDuplicateIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                }]} />
                            </div>
                        </li>
                        ))}
                    </ul>
                </div>}
            </div>}
        </div>}
        {error && <ErrorAlert error={error} setError={setError}/>}
        <Remainder show={request} setShow={setRequest} title='Notification Sent' text='The team will get back to you in 24H with instructions.'/>
        <TemplateDeleteModal open={templateDelete} setOpen={setTemplateDelete} delete={confirmDeleteTemplate}/>
    </>)
}

interface TemplateTabsProps {
    tab: string,
    handleTabChange: (tab:string) => void,
    type:string
    handleTypeChange: (type:string) => void
}

const TemplateTabs = (props:TemplateTabsProps) => {
    const tabs = [
        {
            value: "yours",
            label: "Your Templates",
        },
        {
            value: "library",
            label: "Library",
        }
    ]

    const types = [
        {
            value: "all",
            label: "All",
        },
        {
            value: "medical",
            label: "Notes",
        },
        {
            value: "communications",
            label: "Client",
        },
    ]

    return(
        <div className="">
            <div className="xl:hidden relative">
                <label
                htmlFor="tabs"
                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker"
                >
                    Section
                </label>
                <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-main-lighter focus:ring-main-lighter"
                defaultValue={"Overview"}
                value={props.tab}
                onChange={(value) => props.handleTabChange(value.target.value)}
                >
                    {tabs.map((tab) => (
                        <option key={tab.value} value={tab.value}>{tab.label}</option>
                    ))}
                </select>
            </div>
            <div className="hidden xl:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex flex-row space-x-6 w-full" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <div
                                key={tab.value}
                                onClick={() => props.handleTabChange(tab.value)}
                                className={classNames(
                                props.tab == tab.value
                                    ? 'border-main-lighter text-main'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                                )}
                                aria-current={props.tab == tab.value ? 'page' : undefined}
                            >
                                <span>{tab.label}</span>
                            </div>
                        ))}
                    </nav>
                </div>
            </div>
            <div className='flex flex-row items-center gap-x-4 mt-4'>
                <div className='mr-4'>
                    Type:
                </div>
                {types.map((type) => (
                    <div
                        key={type.value}
                        onClick={() => props.handleTypeChange(type.value)}
                        className={classNames(
                        props.type == type.value
                            ? 'text-white bg-blue-600'
                            : 'text-gray-900 hover:bg-blue-300 bg-blue-100',
                        'group inline-flex items-center py-1 px-2 text-sm font-medium cursor-pointer rounded-md'
                        )}
                        aria-current={props.type == type.value ? 'page' : undefined}
                    >
                        <span>{type.label}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}