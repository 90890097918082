import { BoltIcon, BookOpenIcon, CalendarIcon, DocumentTextIcon, LanguageIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../utils/ClassUtils"
import { Spinner } from "../../utils/Spinner";
import { useHistory } from "../../providers/HistoryProvider";

interface HistoryTabsProps {

}

export const HistoryTabs: React.FC<HistoryTabsProps> = () => {
    const {tab, activeNotes, transcriptProcessing, feedProcessing, notesProcessing, dischargeProcessing, handleTabChange} = useHistory()
    
    return(<div className="">
        <div className="xl:hidden relative">
            <label
            htmlFor="tabs"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker"
            >
                Section
            </label>
            <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-main-lighter focus:ring-main-lighter"
            value={tab}
            onChange={(value) => handleTabChange(value.target.value)}
            >
                <option key={"overview"} value={"overview"}>Overview</option>
                <option key={"prehistory"} value={"prehistory"}>Records Recap</option>
                <option key={"feed"} value={"feed"}>Feed</option>
                <option key={"notes"} value={"notes"}>Notes</option>
                <option key={"discharge"} value={"discharge"}>Client</option>
                <option key={"transcript"} value={"transcript"}>Transcript</option>
            </select>
        </div>
        <div className="hidden xl:block">
            <div className="border-b border-gray-200">
                <nav className="-mb-px flex flex-row space-x-6 w-full" aria-label="Tabs">
                    <div
                        key={"overview"}
                        onClick={() => handleTabChange("overview")}
                        className={classNames(
                        tab == "overview"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab == "overview" ? 'page' : undefined}
                    >
                        <BookOpenIcon
                        className={classNames(
                            tab == "overview" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />
                        <span>{"Overview"}</span>
                    </div>
                    <div
                        key={"prehistory"}
                        onClick={() => handleTabChange("prehistory")}
                        className={classNames(
                        tab == "prehistory"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab == "prehistory" ? 'page' : undefined}
                    >
                        <BoltIcon
                        className={classNames(
                            tab == "prehistory" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />
                        <span>{"Records Recap"}</span>
                    </div>
                    <div
                        key={"feed"}
                        onClick={() => handleTabChange("feed")}
                        className={classNames(
                        tab == "feed"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab == "overview" ? 'page' : undefined}
                    >
                        {transcriptProcessing || feedProcessing ? <Spinner size="w-4 h-4 mr-2" timer={false}/> : <CalendarIcon
                        className={classNames(
                            tab == "feed" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />}
                        <span>{"Feed"}</span>
                    </div>
                    <div
                        key={"notes"}
                        onClick={() => handleTabChange("notes")}
                        className={classNames(
                        tab == "notes"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab == "notes" ? 'page' : undefined}
                    >
                        {(notesProcessing || (transcriptProcessing && !activeNotes)) ? <Spinner size="w-4 h-4 mr-2" timer={false}/> : <DocumentTextIcon
                        className={classNames(
                            tab == "notes" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />}
                        <span>{"Notes"}</span>
                    </div>
                    <div
                        onClick={() => handleTabChange("discharge")}
                        key={"discharge"}
                        className={classNames(
                        tab == "discharge"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab == "discharge"? 'page' : undefined}
                    >
                        {(dischargeProcessing) ? <Spinner size="w-4 h-4 mr-2" timer={false}/> :   <UserCircleIcon
                        className={classNames(
                            tab == "discharge" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />}
                        <span>{"Client"}</span>
                    </div>
                    <div
                        key={"transcript"}
                        onClick={() => handleTabChange("transcript")}
                        className={classNames(
                        tab == "transcript"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab == "transcript" ? 'page' : undefined}
                    >
                        {transcriptProcessing ? <Spinner size="w-4 h-4 mr-2" timer={false}/> : <LanguageIcon
                        className={classNames(
                            tab == "transcript" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />}
                        <span>{"Transcript"}</span>
                    </div>
                </nav>
            </div>
        </div>
    </div>)
}