import { SignUp } from "@clerk/clerk-react"
import { useEffect } from "react";
 
export default function SignUpPage() {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      const button = document.querySelector('.cl-socialButtonsBlockButton__apple') as HTMLButtonElement;
      if (button) {
        button.style.display = 'none';
        observer.disconnect(); // Stop observing after the button is hidden
      }
    });

    // Start observing the body for changes in children
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);
  
  return <div className="flex flex-col h-screen w-screen items-center justify-center">
    <SignUp signInUrl="/login" afterSignUpUrl={"/scribe"}/>
    <div className="text-xs mt-2">By signing up, you are agreeing to our <a href="https://www.vetrec.io/privacy" className="underline">privacy</a> and <a href="https://www.vetrec.io/terms-of-service" className="underline">terms of service</a>.</div>
  </div>
}