import type { CSSProperties, FC } from 'react'
import { useDrag } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { useTemplate } from './TemplateProvider'

const style: CSSProperties = {
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  cursor: 'move',
  float: 'left',
}

export interface EntryBoxProps {
  name: string
  description:string
  type: string
}

export const EntryBox: FC<EntryBoxProps> = function Box({ name, description, type}) {
  const { handleEntryOver, handleSectionOver, handleEntryDragged } = useTemplate()
  const [{ isDragging }, drag] = useDrag(() => ({
    type: type,
    item: { name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    end: (item, monitor) => {
      handleEntryDragged(false)
      handleEntryOver(undefined)
      handleSectionOver(undefined)
    }
  }))

  const opacity = isDragging ? 0.4 : 1
  return (
    <div ref={drag} className="rounded-md w-full border border-gray-300 flex flex-col gap-y-2 shadow-blue-600 shadow" style={{ ...style, opacity }} data-testid={`box`}>
      <span>{name}</span>
      <span className='text-gray-500 text-sm'>{description}</span>
    </div>
  )
}