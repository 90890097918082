import { v4 as uuidv4 } from 'uuid';
import { ContentType, EntryType, SectionType, SectionTypes } from './TemplateUtils'

export const simpleSection:SectionType = {
    sectionKey: uuidv4(),
    name:undefined,
    type: SectionTypes.NORMAL,
    entries: [{
      entryKey:uuidv4(),
      name:undefined,
      description:undefined,
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    }]
}

export const vitals:SectionType = {
    sectionKey: uuidv4(),
    name:"Vitals",
    type: SectionTypes.COLLECTION,
    entries: [{
      entryKey:uuidv4(),
      name:"Weight",
      description:"Weight of patient in pounds",
      defaultValue:"No significant findings",
      type:ContentType.NUMBER,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Temperature",
      description:"Body temperature of the patient in degrees fahrenheit",
      defaultValue:"No significant findings",
      type:ContentType.NUMBER,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Heart Rate",
      description:"Patient's heart rate in bpm",
      defaultValue:"No significant findings",
      type:ContentType.NUMBER,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Respiratory Rate",
      description:"Patient's respiratory rate in BPM",
      defaultValue:"No significant findings",
      type:ContentType.NUMBER,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Blood Pressure",
      description:"Patient's blood pressure. Formatted as 120/80.",
      defaultValue:"No significant findings",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"CRT",
      description:"Patient's capillary refill time as one of the following values: <1 sec , 1-2 sec, >2 sec",
      defaultValue:"1-2 sec",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"MM",
      description:"Patient's mucous membranes condition as one of the following values: Blue, Grey, White, Pale, Yellow, Pigmented, Tacky, Pale Pink, Pink, Injected, Red",
      defaultValue:"Pink",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Pulse Quality",
      description:"Patient's pulse quality. Choose from: Bounding, Normal, Fair, Difficult to Palpate, Weak, No Pulse Detected, Dropped Pulse",
      defaultValue:"No significant findings",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Respiratory Effort",
      description:"Patient's respiratory effort. Choose from: Normal, Laboured Breathing, Shallow",
      defaultValue:"No significant findings",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Additional Vitals",
      description:"Any additional notes vitals information collected.",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    }]
}

export const physical_exam:SectionType = {
    sectionKey: uuidv4(),
    name:"Physical Exam",
    type: SectionTypes.COLLECTION,
    entries: [{
      entryKey:uuidv4(),
      name:"Body Condition",
      description:"Document objective findings regarding the patient's body condition score.",
      defaultValue: "Ideal body condition score (5/9)",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"General Appearance",
      description:"Document objective findings regarding the patient's general appearance.",
      defaultValue:"Bright, alert and responsive. (BAR)",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Eyes",
      description:"Document objective findings from the patient's eyes (OU, OD, OS) examination.",
      defaultValue:"NSF, corneas appear clear and free of discharge, pupils normal size, globes symmetrical OU",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Ears",
      description:"Document objective findings from the patient's ears (AU, AD, AS) examination.",
      defaultValue:"NSF, no exudate observed, no redness present AU.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Oral Cavity",
      description:"Document objective findings from the patient's oral cavity examination. Include grade for calculus, tartar or gingivitis in a scale of I to IV. Format as: Grade II/IV.",
      defaultValue:"NSF, no tartar or gingivitis present, pink and moist mucous membranes, CRT <2 sec.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Nasal Cavity",
      description:"Document objective findings from the patient's nasal cavity examination.",
      defaultValue:"NSF, free of discharge, no obvious abnormalities observed.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Integument",
      description:"Document objective findings from the patient's skin, pads and digits examination. Include specifics like condition, quality, presence of parasites, masses, tumors, peeling, lesions, blisters, burns, etc. and the location in the body. (e.g. NSF, skin appears normal; hair coat in good condition, no ectoparasites noted.)",
      defaultValue:"NSF, skin appears normal; hair coat in good condition, no ectoparasites noted.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Lymphatics",
      description:"Document objective findings from the patient's lymphatic examination.",
      defaultValue:"NSF, no peripheral lymphadenopathy.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Cardiovascular",
      description:"Document objective findings from the patient's cardiovascular system examination including heart sounds, pulse and rhythm.",
      defaultValue:"NSF, no murmurs or arrhythmias auscultated, pulses strong and synchronous.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Respiratory",
      description:"Document objective findings from the patient's respiratory system examination including lungs and trachea.",
      defaultValue:"NSF, eupneic, lungs auscultate clear bilaterally; trachea clear.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Gastrointestinal",
      description:"Document objective findings from the patient's gastrointestinal system examination including abdomen.",
      defaultValue:"NSF, abdomen palpates normally; no masses or organomegaly noted.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Rectal",
      description:"Document objective findings from the patient's rectal examination including palpations of anal glands and discharges.",
      defaultValue:"NSF, did not perform rectal exam.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Urogenital",
      description:"Document objective findings from the patient's urogenital system examination.",
      defaultValue:"NSF, external genitalia and bladder palpates appears normal.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Musculoskeletal",
      description:"Document objective findings from the patient's musculoskeletal system examination including bones and muscles. Include detailed information for each area: muscles, spine, carpus, stifle, tarsus, hips, joints, limbs, elbows and shoulders.",
      defaultValue:"NSF, normal ambulation x4, normal muscle mass.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Neurologic",
      description:"Document objective findings from the patient neurologic system examination including brain, nerves, reflexes, gait / posture and pain.",
      defaultValue:"NSF, normal mentation, full neurologic exam not performed.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Additional Notes",
      description:"Any additional findings from the patient's physical examination.",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    }]
}

export const simpleSubjective:SectionType = {
    sectionKey:uuidv4(),
    name: "Subjective",
    type: SectionTypes.NORMAL,
    entries: [{
      entryKey:uuidv4(),
      name:"Chief Complaint",
      description:"Document reason for visit including if the patient is seeing the doctor for a wellness check or vaccines. (Ex. Fluffy presenting with diarrhea for 2 days. or Fluffy presenting for vaccines).",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"History of Illness",
      description:"Detailed information about the patient's condition and medical history, including when the symptoms started, any changes in diet or behavior, any traumas, presence of vomiting or blood in stool, existing allergies, pre-existing chronic issues and any previous treatments / preventatives. Limit to details provided by owner regarding the pets condition before the consultation.",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    }]
}

export const simpleObjective:SectionType = {
    sectionKey:uuidv4(),
    name: "Objective",
    type: SectionTypes.NORMAL,
    entries: [{
      entryKey:uuidv4(),
      name:"Physical Examination",
      description:"Record findings from the physical exam performed by the doctor including: vital signs, appearance, hydration status, body condition score, assessment of body systems (e.g. Cardiovascular, Respiratory, Integument, Musculoskeletal, etc.), pads, digits, abdominal palpations, assessment of eyes (OU, OD, OS), ears (AU, AD, AS), nose, mouth (including tartar / calculus grade like III/IV), rectal exam and any other comments provide by the doctor during the physical exam.",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    }]
}

export const simpleAssessment:SectionType = {
    sectionKey:uuidv4(),
    name: "Assessment",
    type: SectionTypes.LIST,
    entries: [{
      entryKey:uuidv4(),
      name:"Problem",
      description:"Patient issues identified by the doctor based on the patient history and physical exam. Include evidence as to why this is a problem.",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"DDX",
      description:"Diagnosis provided by the doctor for the problem based on the findings and plan. For example, 'Gastroenteritis suspected.' ONLY include DIFFERENTIAL DIAGNOSIS explicitly provided by the doctor for each potential diagnosis in a list format ordered from most likely to least likely. (e.g. Gastroenteritis vs. Pancreatitis vs. Foreign Body)",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    }]
}

export const simplePlan:SectionType = {
    sectionKey:uuidv4(),
    name: "Plan",
    type: SectionTypes.NORMAL,
    entries: [{
      entryKey:uuidv4(),
      name:"Owner Discussion",
      description:"Detailed summary of information or instructions communicated to the client as part of the plan for the patient. Include quantitative information provided including prognosis, cost estimates ($), treatment duration. Add each topic of conversation on a separate bullet point. (e.g. Discussed the treatment options for Y. Treatment requires 5 sessions done once a week. Treatment cost estimated to be $5000. Explained benefits of using X medication for inflammation. Medication needs to be applied 5 times a week.)",
      defaultValue:undefined,
      type:ContentType.LIST,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Diagnostic Tests",
      description:"Provide a detailed LIST of diagnostic tests performed, including the following details for each test: type of test (e.g., fecal examination, blood work, X-rays, or other imaging studies), sedation details if applicable (type, dosage, concentration), owner’s decision (approved, declined), and test results if available. For example, 'Blood Work - Approved - Results: Pending' or 'X-ray - Declined' or 'X-ray with sedation (10mg, IV) - Performed - Results: No fractures.'",
      defaultValue:undefined,
      type:ContentType.LIST,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Treatments",
      description:"LIST of recommended treatments for the patient and approval / decline decision by owner. (e.g. Spay and Neuter - Approved). Include additional information provided about the treatments like side effects.",
      defaultValue:undefined,
      type:ContentType.LIST,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Medication",
      description:"LIST of drugs / medications prescribed or administered by the doctor, including dosage, frequency, concentration and duration. Include any side effects discussed. Provide instructions for administration. (e.g. 2mg/kg of Cerenia once daily)",
      defaultValue:undefined,
      type:ContentType.LIST,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Vaccines",
      description:"LIST of required vaccines by the patient, whether it was approved or declined by the owner and the body location where it was administered. Include any discussed side effects. (e.g. DAPP - Administered - SQ Left Hind Leg) ",
      defaultValue:undefined,
      type:ContentType.LIST,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Diet",
      description:"Recommended dietary changes provided by doctor. Include any recommended foods as well as any foods to be avoided. Include feeding instructions like servings.",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Monitoring",
      description:"Doctor provided specifications for how often the owner should monitor the pet's condition and what signs to watch for.",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Follow Up",
      description:"Capture follow up information like additional appointments, surgery or procedure prescribed by the doctor and approved by the owner.",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Additional Instructions",
      description:"Any additional information, recommendations, instructions and complication warnings provided by the doctor for the owner. (e.g. Keeping the pet hydrated, Isolating the pet from other animals, Post-operation instructions or potential complications like infection.) Be verbose and thorough based on the information provided by the doctor.",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    }]
}

export const advancedSubjective:SectionType = {
  sectionKey:uuidv4(),
  name: "Subjective",
  type: SectionTypes.NORMAL,
  entries: [{
    entryKey:uuidv4(),
    name:"Chief Complaint",
    description:"Document reason for visit including if the patient is seeing the doctor for a wellness check or vaccines. (Ex. Fluffy presenting with diarrhea for 2 days. or Fluffy presenting for vaccines).",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Onset",
    description:"How long has the illness being present?",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"History of Illness",
    description:"Detailed information about the patient's condition and medical history, including when the symptoms started, any changes in diet or behavior, any traumas, presence of vomiting or blood in stool, existing allergies, pre-existing chronic issues and any previous treatments / preventatives. Limit to details provided by owner regarding the pets condition before the consultation. ",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Chronic Issues",
    description:"Any other chronic issues to note?",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Current Diet",
    description:"What is the current diet of the patient? Include type of food, brand and servings if available.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Current Medications",
    description:"What current medications is the patient taking? Including dosage and frequency.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Current Preventatives",
    description:"Any actions or treatments that have been taken to deal with the issue?",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  }]
}

export const advancedPlan:SectionType = {
  sectionKey:uuidv4(),
  name: "Plan",
  type: SectionTypes.NORMAL,
  entries: [{
    entryKey:uuidv4(),
    name:"Owner Discussion",
    description:"Detailed summary of information or instructions communicated to the client as part of the plan for the patient. Include quantitative information provided including prognosis, cost estimates ($), treatment duration. Add each topic of conversation on a separate bullet point. (e.g. Discussed the treatment options for Y. Treatment requires 5 sessions done once a week. Treatment cost estimated to be $5000. Explained benefits of using X medication for inflammation. Medication needs to be applied 5 times a week.)",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Diagnostic Tests",
    description:"Provide a detailed LIST of diagnostic tests performed, including the following details for each test: type of test (e.g., fecal examination, blood work, X-rays, or other imaging studies), sedation details if applicable (type, dosage, concentration), owner’s decision (approved, declined), and test results if available. For example, 'Blood Work - Approved - Results: Pending' or 'X-ray - Declined' or 'X-ray with sedation (10mg, IV) - Performed - Results: No fractures.'",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Treatments",
    description:"LIST of recommended treatments for the patient and approval / decline decision by owner. (e.g. Spay and Neuter - Approved). Include additional information provided about the treatments like side effects.",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Medication",
    description:"LIST of drugs / medications prescribed or administered by the doctor, including dosage, frequency, concentration and duration. Include any side effects discussed. Provide instructions for administration. (e.g. 2mg/kg of Cerenia once daily)",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Vaccines",
    description:"LIST of required vaccines by the patient, whether it was approved or declined by the owner and the body location where it was administered. Include any discussed side effects. (e.g. DAPP - Administered - SQ Left Hind Leg)",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Diet",
    description:"Recommended dietary changes provided by doctor. Include any recommended foods as well as any foods to be avoided. Include feeding instructions like servings.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Monitoring",
    description:"Doctor provided specifications for how often the owner should monitor the pet's condition and what signs to watch for.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Follow Up",
    description:"Capture follow up information like additional appointments, surgery or procedure prescribed by the doctor and approved by the owner.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Additional Instructions",
    description:"Any additional information, recommendations, instructions and complication warnings provided by the doctor for the owner. (e.g. Keeping the pet hydrated, Isolating the pet from other animals, Post-operation instructions or potential complications like infection.) Be verbose and thorough based on the information provided by the doctor.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  }]
}

export const fearfree:SectionType = {
  sectionKey:uuidv4(),
  name: "Fear Free",
  type: SectionTypes.NORMAL,
  entries: [{
    entryKey:uuidv4(),
    name:"Declined Items",
    description:"LIST of items that the owner declined during the consult including labwork, radiographs, ultrasounds, treatments, procedures and surgery, medications, vaccines.",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Forward Booking",
    description:"Any appointments or procedures that were scheduled for the patient during the appointment. Include rechecks, surgeries, procedures, and other appointments.",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }]
}