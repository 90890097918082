import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowPathIcon, Cog6ToothIcon } from '@heroicons/react/24/solid'
import PiMSPicker from '../integrations/PIMSPicker'
import { EzyVetConfig } from '../integrations/EzyVetConfig'
import { SectionType } from './TemplateUtils'
import { useTemplate } from './TemplateProvider'
import { InstinctConfig } from '../integrations/InstinctConfig'

interface TemplateConfigModalProps{
    open:boolean,
    setOpen: (value: boolean) => void
    items: SectionType[]
}

export default function TemplateConfigModal(props:TemplateConfigModalProps) {
    const {integration, updateIntegration} = useTemplate()
    
    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-[10]" onClose={props.setOpen}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <Cog6ToothIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5 flex flex-col items-center justify-center gap-y-4">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Configure integration
                                </Dialog.Title>
                                <div className="">
                                <p className="text-sm text-gray-500">
                                    Pick a PiMS to configure the template for and configure the values.
                                </p>
                                </div>
                                <PiMSPicker PiMS={integration} setPiMS={updateIntegration} className='w-full' labelBackground=''/>
                            </div>
                        </div>
                        {integration == "EzyVet" && <EzyVetConfig items={props.items} open={props.setOpen}/>}
                        {integration == "Instinct" && <InstinctConfig items={props.items} open={props.setOpen}/>}
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}