import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface TooltipProps {
  direction: 'right' | 'left' | 'top' | 'bottom';
  content: React.ReactNode;
  children: React.ReactElement;
}

const InputTooltip: React.FC<TooltipProps> = ({ direction, content, children }) => {
  const [show, setShow] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLElement>(null);
  const [tooltipPos, setTooltipPos] = useState({ top: 0, left: 0 });

  const updatePosition = () => {
    if (!childRef.current || !tooltipRef.current) return;
    const rect = childRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    let newTop = 0;
    let newLeft = 0;

    switch (direction) {
      case 'right':
        newTop = rect.top + window.scrollY + (rect.height - tooltipRect.height) / 2;
        newLeft = rect.right + window.scrollX + 10;
        break;
      case 'left':
        newTop = rect.top + window.scrollY + (rect.height - tooltipRect.height) / 2;
        newLeft = rect.left + window.scrollX - tooltipRect.width - 10;
        break;
      case 'top':
        newTop = rect.top + window.scrollY - tooltipRect.height - 10;
        newLeft = rect.left + window.scrollX + (rect.width - tooltipRect.width) / 2;
        break;
      case 'bottom':
        newTop = rect.bottom + window.scrollY + 10;
        newLeft = rect.left + window.scrollX + (rect.width - tooltipRect.width) / 2;
        break;
      default:
        break;
    }

    setTooltipPos({ top: newTop, left: newLeft });
  };

  useEffect(() => {
    const handleShow = () => {
      setShow(true);
      updatePosition();
    };
    const handleHide = () => setShow(false);

    const child = childRef.current;
    if (child) {
      child.addEventListener('focus', handleShow);
      child.addEventListener('blur', handleHide);

      const resizeObserver = new ResizeObserver(updatePosition);
      resizeObserver.observe(child);

      return () => {
        child.removeEventListener('focus', handleShow);
        child.removeEventListener('blur', handleHide);
        resizeObserver.disconnect();
      };
    }
  }, [direction]);

  useEffect(() => {
    if (show) {
      updatePosition();
    }
  }, [show]);

  const tooltipStyle: React.CSSProperties = {
    top: `${tooltipPos.top}px`,
    left: `${tooltipPos.left}px`,
    position: 'absolute',
    zIndex: 1000
  };

  const tooltipElement = show ? (
    <div ref={tooltipRef} style={tooltipStyle} className="p-2 text-gray-900 bg-gray-100 rounded-md shadow-lg border border-gray-300 max-w-[500px]">
      {content}
    </div>
  ) : null;

  return (
    <>
      {React.cloneElement(children, { ref: childRef })}
      {tooltipElement && ReactDOM.createPortal(tooltipElement, document.body)}
    </>
  );
};

export default InputTooltip;
