import { CheckIcon, InformationCircleIcon, MicrophoneIcon } from "@heroicons/react/24/solid";
import { AllConsentTypes } from "../../utils/Categories";
import { Loading } from "../../utils/Loading";
import PetFeed from "../../PetFeed";
import { Confirmation } from "../../utils/ConsentsUtils";
import { SessionObject } from "../../utils/SessionUtils";
import { useHistory } from "../../providers/HistoryProvider";
import { Spinner } from "../../utils/Spinner";

interface FeedProps {

}

export default function Feed() {
    const {feedLoading, activeConsents, activeSession, handleMarkComplete, handleAddToSession, loadingSessionInfo, transcriptProcessing, feedProcessing} = useHistory()
    return(
        <div>
            {(feedLoading || loadingSessionInfo) && !activeConsents && !transcriptProcessing && !feedProcessing && <Loading text={feedLoading ?? loadingSessionInfo ?? ""}/>}
            {(transcriptProcessing || feedProcessing) && !feedLoading && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-6 mb-8'>
                {transcriptProcessing && !feedProcessing && <div>Transcribing recording 🤖...</div>}
                {feedProcessing && <div>Generating feed for session 🤖...</div>}
                <Spinner size="w-12 h-12" timer={true}/>
            </div>}
            {activeConsents && <>
                <div  className="flex xl:flex-row flex-col gap-y-4 justify-between gap-x-4 mb-4 xl:items-center items-end">
                    {!feedLoading && activeConsents && <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
                        <div className="flex grow items-center justify-center">
                            <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                                <div className="flex flex-row gap-x-4 items-center">
                                    <div>
                                        <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                    </div>
                                    <div>
                                        <span className="font-semibold">Session feed</span> is still in beta. Send feedback to <a className="underline" href="mailto:support@vetrec.io">support@vetrec.io</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="flex flex-row gap-x-2 w-full sm:w-auto">
                        {!feedLoading && activeConsents && <button
                            type="button"
                            title={activeSession && activeSession.status.general && activeSession.status.general == "Completed" ? "This session is completed" : "Mark this session as completed"}
                            className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52 ${activeSession && activeSession.status.general && activeSession.status.general == "Completed" ? 'bg-green-600 hover:bg-green-500 font-semibold text-white' : 'bg-white'}`}
                            onClick={() => handleMarkComplete()}
                        >
                            {activeSession && activeSession.status.general && activeSession.status.general == "Completed" && <CheckIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />}
                            {activeSession && activeSession.status.general && activeSession.status.general == "Completed" ?  "Completed" : "Mark as completed"}
                        </button>}
                        {!feedLoading && activeConsents && <button
                            type="button"
                            title="Record additional information for this session which will be added to the notes."
                            className="whitespace-nowrap inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52"
                            onClick={() => handleAddToSession()}
                        >
                            <MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                            Add to visit
                        </button>}
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-y-8">
                    <div className="flex flex-col gap-y-4 w-full sm:w-[90%] sm:pr-4">
                        <div>
                            <h3 className="text-base font-semibold leading-7 text-gray-900">Feed events</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Highlights from the session like consents, answers to questions and more. Organized in order.</p>
                        </div>
                        <div className="pt-4 pr-4 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 min-h-[50vh] h-[50vh] max-h-[50vh] overflow-y-auto thin-scrollbar">
                            {activeConsents.length > 0 && <PetFeed activeConsents={activeConsents} />}
                            {activeConsents.length == 0 && <div className="text-gray-500 text-center flex flex-col items-center justify-center h-full">
                                <span>No highlights for this session.</span>
                            </div>}
                        </div>
                    </div>
                    <div className="flex flex-col justify-start sm:px-6 gap-y-2 min-h-full sm:border-l sm:border-t-0 border-t border-gray-300 pt-4">
                        <h3 className="text-base font-semibold text-gray-900 pb-2">Categories</h3>
                        <AllConsentTypes />
                    </div>
                </div>
            </>}
        </div>
    )
}