import config from '../config.json'

const url = process.env.REACT_APP_ENDPOINT ?? config.ENDPOINT;

interface SessionStatus {
    recording:string,
    transcript:string,
    notes:string,
    comms:string,
    manual_notes:string
}

interface SessionObject {
    id:string,
    created_timestamp:number,
    modified_timestamp:number,
    name:string,
    organization:string|undefined,
    owner:string,
    status:SessionStatus
}

export async function GetNotesSignedURL(session:string, jwt:string){
    const endpoint = `${url}/v2/uploadNotes/${session}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error getting the signed URL for notes from the API.`);
    }
}

export async function GetDischargeSignedURL(session:string, jwt:string){
    const endpoint = `${url}/v3/uploadDischarge/${session}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error getting the signed URL for discharge from the API.`);
    }
}

export async function GetTemplateSignedURL(template_id:string, jwt:string){
    const endpoint = `${url}/v2/uploadTemplate/${template_id}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error getting the signed URL for template from the API.`);
    }
}

export async function GetNotes(session:string, jwt:string){
    const endpoint = url + '/v2/notes/' + session;
    let response: Response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const notes = await response.text();
        return notes;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading notes from the API.`);
    }
}

export async function GetTranscript(session:string, jwt:string){
    const endpoint = url + '/v2/transcript/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const transcript = await response.text();
        return transcript;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading transcript from the API.`);
    }
}

export async function GetDischarge(session:string, jwt:string){
    const endpoint = url + '/v3/discharge/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const discharge = await response.text();
        return discharge;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading discharge from the API.`);
    }
}

export async function GetFeed(session:string, jwt:string){
    const endpoint = url + '/v2/feed/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const feed = await response.text();
        return feed;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading feed from the API.`);
    }
}

export async function GetAuditLogs(session:string, jwt:string){
    const endpoint = url + '/v2/audit_logs/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const audit_logs = await response.json();
        return audit_logs;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading audit logs from the API. E: ${error instanceof Error ? error.message : error}`);
    }
}

export async function GetPreHistory(session:string, jwt:string){
    const endpoint = url + '/v2/prehistory/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const feed = await response.text();
        return feed;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading feed from the API.`);
    }
}

export async function GetTemplate(template_id:string, jwt:string){
    const endpoint = url + '/v2/template_definition/' + template_id;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const feed = await response.text();
        return feed;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading template from the API.`);
    }
}

export async function GetQuestionaire(session:string, jwt:string){
    const endpoint = url + '/v2/questionaire/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const feed = await response.text();
        return feed;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading questionaire from the API.`);
    }
}

export async function CheckRecordings(session:string, jwt:string){
    const endpoint = url + '/v2/check_recordings/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error getting signed urls for recordings from the API.`);
    }
}


export async function GetRecordings(session:string, jwt:string){
    const endpoint = url + '/v2/recordings/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error getting signed urls for recordings from the API.`);
    }
}

export async function GetRecordingsSignedURL(session:string, recording_id:string, extension:string, jwt:string){
    const endpoint = `${url}/v2/uploadRecordings/${session}/${recording_id}/${extension}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error creating partial backup signed URL from the API. Error: ${error instanceof Error ? error.message : error}`);
    }
}

export async function GetRecordingsPartialBackupSignedURL(session:string, recording_id:string, partial_id:string, extension:string, jwt:string){
    const endpoint = `${url}/v2/uploadRecordingsPartialBackup/${session}/${recording_id}/${partial_id}/${extension}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error creating partial backup signed URL from the API. Error: ${error instanceof Error ? error.message : error}`);
    }
}

export async function GetRecordingFullBackupSignedURL(session:string, recording_id:string, extension:string, jwt:string){
    const endpoint = `${url}/v2/uploadRecordingsFullBackup/${session}/${recording_id}/${extension}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error creating full backup signed URL from the API. Error: ${error instanceof Error ? error.message : error}`);
    }
}

export async function GetPreHistoryUploadURL(session:string, extension:string, index:string, jwt:string){
    const endpoint = `${url}/v2/uploadPreHistory/${session}/${extension}/${index}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error getting the signed URL for prehistory from the API. Error: ${error instanceof Error ? error.message : error}`);
    }
}
