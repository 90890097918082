import { Fragment, JSXElementConstructor, ReactElement, ReactNode, ReactPortal } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type IconType = string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined

export interface MoreMenuOption {
    name:string,
    action: () => void,
    icon: IconType
} 

interface MoreMenuProps{
    options: MoreMenuOption[]
    height: string | undefined
    moreText?: string
    moreIcon?: IconType
}

export default function MoreMenu(props:MoreMenuProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={`inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 ${props.height ?? "h-12"}`}>
          {props.moreText && <span className="hidden sm:block">{props.moreText}</span>}
          {props.moreIcon ?? <EllipsisVerticalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-sm ring-1 ring-gray-300 focus:outline-none">
            {props.options.map((option) => (
                <Menu.Item key={option.name}>
                    {({ active }) => (
                    <div
                      key={option.name}
                      onClick={() => option.action()}
                      className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-md cursor-pointer'
                      )}
                    >
                      {option.icon}
                      {option.name}
                    </div>
                    )}
                </Menu.Item>
            ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
