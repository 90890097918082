import { MicrophoneIcon} from '@heroicons/react/20/solid'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {processRecording, saveName, reprocessRecording } from './ServerActions'
import { ArrowRightCircleIcon, CheckBadgeIcon, ExclamationTriangleIcon, PlusCircleIcon, StopCircleIcon } from '@heroicons/react/24/solid'
import { Spinner } from './utils/Spinner';
import { TemplateObject } from './utils/TemplateObject';
import va from '@vercel/analytics';
import { useNavigate } from "react-router-dom"
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react"
import TemplatePicker from './components/templates/TemplatePicker';
import MicrophoneWave from './components/history/MicrophoneWave';
import { isMobileDevice } from './utils/deviceUtils';
import { formatTime,  } from './utils/RecordingUtils';
import NoSleep from 'nosleep.js';
import { useRecording, PermissionStatus, RecordingStatus } from './providers/RecordingProvider';
import PermissionsHelper from './components/recording/PermissionsHelper';
import MicrophonePicker from './components/history/MicrophonePicker';
import Remainder from './components/remainder';
import ErrorAlert from './components/Error';
import UserPicker from './components/UserPicker'
import ConsentDialog from './components/recording/ConsentDialog'
import { TemplateType } from './components/templates/TemplateUtils'

// Define the types for your props
interface ScribeProps {
    
}

export default function Scribe(props:ScribeProps) {
    // Session controllers
    const isMobile = useMemo(isMobileDevice, [])
    const [error, setError] = useState<string|undefined>(undefined)
    const [remainder, setRemainder] = useState<string|undefined>(undefined)
    const { getToken } = useAuth();
    const { user } = useUser()
    const  navigate = useNavigate()
    const startProcessingRef = useRef<HTMLButtonElement>(null);

    // Template Variables
    const [template, setTemplate] = useState<TemplateObject>()

    // Recording Variables
    const [processingContext, setProcessingContext] = useState<boolean>(false)
    const [buttonClick, setButtonClick] = useState<boolean>(false)
    const [reuploadClick, setReuploadClick] = useState<boolean>(false)
    const [canWeHearYou , setCanWeHearYou] = useState<boolean>(false)
    const {sessionId, recordingState, stopRecording, startRecording, recordingAllowed, anyRecording, name, updateName, addToRecording, seconds, mediaStream, consent, consentPending, updateRecordAs, membersList, recordAs, existingTemplateId, handleUpload, organizationAdmin, uploadPercentage} = useRecording()


    useEffect(() => {
        const handleBeforeUnload = async (e: BeforeUnloadEvent): Promise<void> => {
            if (recordingState === RecordingStatus.UPLOADING) {
                e.preventDefault();
                let properties =  {
                    date:(new Date()).toUTCString(),
                    sessionId: sessionId
                }
                va.track("Leaving before upload finished", properties)
            }
            else if (recordingState === RecordingStatus.RECORDING){
                e.preventDefault();
                let properties =  {
                    date:(new Date()).toUTCString(),
                    sessionId: sessionId
                }
                va.track("Leaving during recording", properties)
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        if(recordingState === RecordingStatus.FAILED_UPLOAD){
            setError("Failed to upload the recording. Please try again.")
        }else if (recordingState === RecordingStatus.FAILED_NAME){
            setError("Please enter a name for the session.")
        }else if (recordingState === RecordingStatus.STOPPED){
            setRemainder("Upload successful! Start processing.")
        }
        else if(recordingState === RecordingStatus.FAILED_NOT_FOUND){
            setError("Visit not found. Please try again.")
        }

        return (): void => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [recordingState]);

    const noSleep = useMemo(() => new NoSleep(), [])
    function toggleNoSleep(enable:boolean) {
        if(enable){
            try{
                noSleep.enable();
                //alert(`click and enable noSleep`);
            }catch(error){
                console.error('Failed to activate wake lock:', error);
                let properties =  {
                    date:(new Date()).toUTCString(),
                    ua:navigator.userAgent,
                    session:sessionId
                }
                va.track("WakeLock_Failed", properties)
            }
        } else {
            try{
                noSleep.disable();
            }catch(error){
                console.error('Failed to release wake lock:', error);
            }
        }
    }

    async function handleRecord(): Promise<void> {
        try{
            if(recordingState === RecordingStatus.NOTSTARTED || recordingState === RecordingStatus.FAILED_NAME || recordingState === RecordingStatus.FAILED){    
                setButtonClick(true)
                toggleNoSleep(true)
                await startRecording(undefined, undefined, true)
                //toggleWakeLock(true)
                let properties =  {
                    date:(new Date()).toUTCString(),
                    mediaRecorderState: "start",
                    sessionId: sessionId
                }
                va.track("Recording_Button_Clicked", properties)
                setButtonClick(false)
            }
            else if(recordingState === RecordingStatus.RECORDING){
                setButtonClick(true)
                toggleNoSleep(false)
                await stopRecording()
                setCanWeHearYou(false)
                let properties =  {
                    date:(new Date()).toUTCString(),
                    mediaRecorderState: "stop",
                    sessionId: sessionId
                }
                va.track("Recording_Button_Clicked", properties)
                setButtonClick(false)
            }
            else if(recordingState === RecordingStatus.STOPPED){
                setButtonClick(true)
                toggleNoSleep(true)
                await startRecording(undefined, undefined, false)
                //toggleWakeLock(true)
                let properties =  {
                    date:(new Date()).toUTCString(),
                    mediaRecorderState: "resume",
                    sessionId: sessionId
                }
                va.track("Recording_Button_Clicked", properties)
                setButtonClick(false)
            }
        } catch (error){
            setError(recordingState === RecordingStatus.RECORDING ? "Had an issue stopping the recording. Please try again." : "Had an issue starting recording. Please try again.")
            toggleNoSleep(false)
            let properties =  {
                date:(new Date()).toUTCString(),
                mediaRecorderState: recordingState,
                sessionId: sessionId,
                error: error instanceof Error ? error.toString() : ""
            }
            va.track("Recording_Button_Clicked_Failed", properties)

            setButtonClick(false)
        }
    }

    async function processContext(): Promise<void> {
        setButtonClick(true)
        let properties:any =  {
            date:(new Date()).toUTCString(),
            mediaRecorderState: "stopped",
            sessionId: sessionId
        }
        va.track("ScribePage_ProcessRecording_Click", properties)

        if(anyRecording && recordingState === RecordingStatus.STOPPED){
            try{
                if(addToRecording){
                    setProcessingContext(true)
                    let templateId = `${template?.organization}/${template?.id}`
                    await reprocessRecording(sessionId, name, await getToken({template:"supabase"}) ?? "", false, templateId, true)
                    setProcessingContext(false)
                    navigate('/history?session_id=' + sessionId + "&tab=notes")
                }
                else{
                    setProcessingContext(true)
                    let templateId = `${template?.organization}/${template?.id}`
                    await processRecording(sessionId, name, await getToken({template:"supabase"}) ?? "", false, templateId, true)
                    setProcessingContext(false)
                    navigate('/history?session_id=' + sessionId + "&tab=notes")
                }
            }
            catch(error) {
                setError("Couldn't start processing the session. Please try again.")
                return
            }
        }
        await saveName(sessionId, name, await getToken({template:"supabase"}) ?? "")    
    }

    async function handleReupload(): Promise<void> {
        setReuploadClick(true)
        setRemainder("Trying to reupload the recording. Please wait...")
        await handleUpload(true)
        setReuploadClick(false)

        let properties =  {
            date:(new Date()).toUTCString(),
            sessionId: sessionId,
        }
        va.track("Scribe_Reupload_Attempt", properties)
    }

    return(<>
        <div className="border-b border-gray-400 pb-5 mb-5 py-4 sm:pt-10 flex flex-row justify-between gap-x-10 items-center">
            <div>
                <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                    {addToRecording ? "Add to visit" : "Scribe"}
                </h2>
                <p className="mt-2 max-w-4xl text-sm text-gray-500 hidden sm:block">
                    VetRec Scribe listens to your consultation and automatically generates a transcript <u>and</u> notes that you can use for your records. If you would like to customize the notes template used head over to the <a href="/templates" className="underline text-gray-400">templates section</a>.
                </p>  
            </div>
            <a href='/scribe' className="inline-flex justify-center items-center gap-x-2 rounded-md bg-accent-button px-3.5 py-2.5 text-sm font-semibold text-accent-button-text shadow-md hover:bg-accent-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 min-w-36">
                New Visit
                <PlusCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </a>
        </div>
        <div>
            <div className='flex sm:flex-row flex-col w-full gap-x-4 gap-y-4'>
                <div className="relative grow">
                    <label htmlFor="name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker rounded-full">
                        Patient's Name
                    </label>
                    <input type="text" className={`block w-full h-10 sm:h-14 rounded-md border-0 py-1.5 text-main-text-darker shadow-sm ring-2 ring-inset ring-blue-400 placeholder:text-gray-400 ${recordingState === RecordingStatus.FAILED_NAME ? "ring-red-600 ring-4" : " ring-blue-400 ring-2 "} focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 ${(addToRecording || anyRecording) ? "bg-gray-300" : "bg-white"}`} placeholder="Mr.Wiggles" value={name} onChange={(event) => updateName(event.target.value)} disabled={addToRecording || anyRecording}
                    />
                </div>
                {membersList && membersList.length > 1 && organizationAdmin && <div className='relative'>
                    <UserPicker userList={membersList} userSearch={membersList.find((u) => u.identifier == recordAs)} setUserSearch={(value) => updateRecordAs(value?.identifier ?? "")} className="h-10 sm:h-14" text='Record as' disabled={recordingState !== RecordingStatus.NOTSTARTED}/>
                </div>}
                <TemplatePicker template={template} templateId_force={existingTemplateId} setTemplate={setTemplate} className='h-10 sm:h-14' templateBackground='bg-white' type={TemplateType.MEDICAL}/>
            </div>
            <div className='flex flex-col sm:flex-row pt-4 gap-x-8 gap-y-8'>
                <div className='shadow-md sm:w-[100%] w-full rounded-lg flex flex-col'>
                    <div className="border border-gray-400 rounded-t-lg bg-white px-4 py-5 sm:px-6">
                        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                            <div className="ml-4 mt-4">
                                <h3 className="text-base font-semibold leading-6 text-main-text-darker">Capture Recording</h3>
                            </div>  
                        </div>
                    </div>
                    <div className='flex flex-grow py-8 sm:py-12 border border-gray-300 rounded-b-lg justify-center'>
                        {recordingAllowed !== PermissionStatus.GRANTED && < PermissionsHelper />}
                        {recordingAllowed === PermissionStatus.GRANTED && <div className='flex flex-col gap-y-8 justify-center items-center'>
                            <button type="button" className={`rounded-full ${recordingState === RecordingStatus.RECORDING ? "bg-red-600 hover:bg-red-500": (recordingState == RecordingStatus.UPLOADING || recordingState === RecordingStatus.FAILED_UPLOAD || recordingState === RecordingStatus.PREPARING || recordingState === RecordingStatus.FAILED_NOT_FOUND) ? "bg-gray-400" : "bg-main-button hover:bg-main-button-hover"} px-4 py-2.5 text-md font-semibold text-main-button-text shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-64 w-64 flex flex-col justify-center justify-items-center items-center gap-y-4`} onClick={() => handleRecord()} disabled={buttonClick || recordingState == RecordingStatus.FAILED_UPLOAD || recordingState === RecordingStatus.PREPARING || recordingState === RecordingStatus.FAILED_NOT_FOUND} >
                                {/*Recording button text*/}
                                {recordingState == RecordingStatus.PREPARING && <span className='text-white text-xl font-semibold'>Preparing to Record</span>}
                                {recordingState == RecordingStatus.NOTSTARTED && <span className='text-white text-xl font-semibold'>Start Recording</span>}
                                {recordingState == RecordingStatus.FAILED && <span className='text-white text-xl font-semibold'>Start Recording</span>}
                                {recordingState == RecordingStatus.FAILED_NAME && <span className='text-white text-xl font-semibold'>Start Recording</span>}
                                {recordingState == RecordingStatus.RECORDING && <span className='text-white text-xl font-semibold'>Pause Recording</span>}
                                {recordingState == RecordingStatus.STOPPED && <span className='text-white text-xl font-semibold'>Resume Recording</span>}
                                {(recordingState == RecordingStatus.UPLOADING || recordingState == RecordingStatus.REUPLOADING) && <span className='text-white text-xl font-semibold'>Uploading recording</span>}
                                {recordingState == RecordingStatus.FAILED_NOT_FOUND && <span className='text-white text-xl font-semibold'>Visit not found</span>}
                                {recordingState == RecordingStatus.FAILED_UPLOAD && <span className='text-white text-xl font-semibold'>Upload Failed</span>}
                                {/*Timer*/}
                                {seconds > 0 && (recordingState === RecordingStatus.RECORDING || recordingState === RecordingStatus.STOPPED ) && <span className='text-white text-lg font-semibold'>{formatTime(seconds)}</span>}
                                {/*Recording button icon*/}
                                {(recordingState == RecordingStatus.UPLOADING || recordingState == RecordingStatus.REUPLOADING) && <div className='text-white text-xl font-semibold'>
                                    {uploadPercentage} %
                                </div>}
                                {recordingState == RecordingStatus.PREPARING && <Spinner size='h-10 w-10' timer={false}/>}
                                {recordingState == RecordingStatus.NOTSTARTED && <MicrophoneIcon className='h-10'/>}
                                {recordingState == RecordingStatus.FAILED && <MicrophoneIcon className='h-10'/>}
                                {recordingState == RecordingStatus.FAILED_NAME && <MicrophoneIcon className='h-10'/>}
                                {recordingState == RecordingStatus.RECORDING && <StopCircleIcon className='h-10'/>}
                                {recordingState == RecordingStatus.STOPPED && <MicrophoneIcon className='h-10'/>}
                            </button>
                            {recordingState === RecordingStatus.RECORDING && mediaStream && <div className='-mt-[16rem] z-[-1] mb-8'>
                                <MicrophoneWave width={isMobile ? '350' : '500'} mediaStream={mediaStream} canWeHearYou={canWeHearYou} setCanWeHearYou={setCanWeHearYou}/>
                            </div>}
                            <MicrophonePicker />
                            {canWeHearYou && <div className='flex flex-row gap-x-4 -m-4'>
                                <CheckBadgeIcon className='h-6 w-6 text-green-600' />
                                We can hear you!
                            </div>}
                            <div className='flex flex-col sm:flex-row shrink-0 grow justify-end gap-x-8 gap-y-4 items-center max-w-full'>
                                {(recordingState !== RecordingStatus.FAILED_UPLOAD) && <button
                                    ref={startProcessingRef}
                                    type="button"
                                    className={`inline-flex items-center justify-center gap-x-2 rounded-md ${((anyRecording && recordingState == RecordingStatus.STOPPED)) ? "bg-main-button hover:bg-main-button-hover text-main-button-text" : "bg-main-button-disabled text-main-button-disabled-text"} px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12 w-60 justify-center`}
                                    onClick={() => processContext()}
                                    disabled={!(anyRecording && recordingState == RecordingStatus.STOPPED) || buttonClick }
                                >
                                    {anyRecording && recordingState == RecordingStatus.STOPPED && !processingContext && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Start processing
                                        <ArrowRightCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                    </div>}
                                    {processingContext && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Processing...
                                        <Spinner size='h-5 w-5' timer={false}/>
                                    </div>}
                                    {(recordingState === RecordingStatus.UPLOADING || recordingState === RecordingStatus.REUPLOADING) && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Uploading...
                                        <Spinner size='h-5 w-5' timer={false}/>
                                    </div>}
                                    { recordingState === RecordingStatus.RECORDING && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Recording...
                                        <MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                    </div>}
                                    {recordingState === RecordingStatus.NOTSTARTED && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Start recording!
                                    </div>}
                                    {recordingState === RecordingStatus.PREPARING && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Preparing to record...
                                    </div>}
                                    {(recordingState === RecordingStatus.FAILED_NAME || recordingState === RecordingStatus.FAILED) && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Start recording!
                                    </div>}
                                    {recordingState ===  RecordingStatus.FAILED_NOT_FOUND && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Visit not found
                                    </div>}
                                </button>}
                                {(recordingState === RecordingStatus.FAILED_UPLOAD) && <button
                                    ref={startProcessingRef}
                                    type="button"
                                    className={`inline-flex items-center gap-x-2 rounded-md bg-main-button hover:bg-main-button-hover text-main-button-text px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12 w-60 justify-center`}
                                    onClick={() => handleReupload()}
                                    disabled={reuploadClick}
                                >
                                    <div className='flex flex-row justify-center items-center gap-x-2'>
                                        {!reuploadClick && <ExclamationTriangleIcon className='h-5 w-5 text-white-600'/>}
                                        {reuploadClick && <Spinner size='h-5 w-5' timer={false}/>}
                                        <div className='flex flex-col'>
                                        Error uploading recording. <span className='font-normal text-sm'>Click to try again.</span>
                                        </div>
                                    </div>
                                </button>}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div className='flex flex-col-reverse sm:flex-row justify-between items-center w-full pt-8 pb-8 sm:pr-20 gap-y-8 gap-x-10'>
                <div className='text-xs text-center sm:text-start w-full truncate shrink overflow-hidden whitespace-nowrap min-w-20'>
                    <span className='font-semibold'>Visit ID:</span> {sessionId}
                </div>
            </div>
        </div>
        {error && <ErrorAlert error={error} setError={setError}/>}
        {remainder && <Remainder show={remainder ?  true: false} title={remainder} text="" setShow={() => setRemainder(undefined)} />}
        {consentPending && !consent && <ConsentDialog />}
    </>)
}
