import { FC } from "react";
import { SectionType } from "../templates/TemplateUtils";
import { useTemplate } from "../templates/TemplateProvider";
import InputTooltip from "../templates/InputTooltip";

interface EzyVetConfigProps {
    items: SectionType[];
    open: (val: boolean) => void;
}

export const InstinctConfig: FC<EzyVetConfigProps> = ({ items, open }) => {
    const { mapping, updateEzyvetSectionName, saveMapping } = useTemplate();

    async function handleTemplateConfig() {
        await saveMapping();
        open(false);
    }

    return (
        <div className="flex flex-col gap-y-4 mt-4 max-h-[60vh] thin-scrollbar overflow-y-auto pr-2">
            <div>
                <div className="w-full flex flex-row items-center justify-between border-b border-gray-300 pb-1">
                    <div>Section Name</div>
                    <div className="self-start w-60">Name in Instinct</div>
                </div>
            </div>
            {mapping && items.map((item) => (<>
                <div key={item.name} className="rounded-md w-full flex flex-row items-center justify-between">
                <div>{item.name}</div>
                    <InputTooltip 
                        direction="right" 
                        content={<div className='flex flex-col'>
                            <div className='font-semibold'>For each section:</div>
                            <ul className='list-disc list-inside'>
                                <li>Navigate to your chart in Instinct</li>
                                <li>Copy the name of the equivalent section in Instinct. (e.g. Subjective or Physical Exam/Objective)</li>
                                <li>Make sure you copy exactly the name of the section.</li>
                                <li>Add the name to the Name in Instinct column</li>
                            </ul>
                        </div>}
                    >
                        <input 
                            value={mapping[item.name?.toLowerCase() ?? ""].ezyvet_section_name} 
                            className="rounded-md border-gray-300 h-8 w-60" 
                            onChange={(event) => updateEzyvetSectionName(item.name?.toLowerCase() ?? "", event.target.value)} 
                        />
                    </InputTooltip>
                </div>
            </>))}
            <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => handleTemplateConfig()}
                >
                    Save Configuration
                </button>
            </div>
        </div>
    );
};
