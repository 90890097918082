
// Consent Dialog

import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useRecording} from '../../providers/RecordingProvider'
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import dogmic from '../../assets/joey-mic.png'

export default function ConsentDialog() {
    const { updateConsent, consentPending } = useRecording()

    async function handleConsent(isConsent:boolean){
        await updateConsent(isConsent)
    }

    async function handleCancelRecording(): Promise<void>{
        await updateConsent(false)
    }

    return (<>
        <Transition.Root show={consentPending} as={Fragment}>
            <Dialog as="div" className="relative z-[99999] ml-72" onClose={() => handleCancelRecording()}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 lg:ml-72">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-2xl">
                        <div className='flex flex-row items-center justify-center gap-x-8 overflow-hidden'>
                            <div className='pb-4 pt-5 px-4 sm:p-6'>
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-300">
                                        <ExclamationTriangleIcon className="h-6 w-6 text-yellow-800" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-main-text-darker">
                                        Consent required
                                        </Dialog.Title>
                                        <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Recording requires acknowledgement of consent. Please ask everyone in the room for consent to be recorded and received verbal confirmation. <span className='font-semibold'>(Disclaimer: Confirmations are recorded.)</span>
                                        </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-main-text shadow-sm hover:bg-main-lighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                                        onClick={() => handleConsent(true)}
                                    >
                                        Consent
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={() => handleCancelRecording()}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div className='sm:block hidden h-full w-full'>
                                <img className="transform scale-[2] -ml-4 mt-20" src={dogmic} />
                            </div>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>)
}