import { SignIn } from "@clerk/clerk-react";
import { useEffect } from "react";

export default function SignInPage() {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      const button = document.querySelector('.cl-socialButtonsBlockButton__apple') as HTMLButtonElement;
      if (button) {
        button.style.display = 'none';
        observer.disconnect(); // Stop observing after the button is hidden
      }
    });

    // Start observing the body for changes in children
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);
  
  return <div className="flex h-screen w-screen items-center justify-center">
      <SignIn signUpUrl="/register" afterSignInUrl={'/scribe'}/>
  </div>
}