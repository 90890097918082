import React, { useEffect, useRef, useState } from 'react';
import 'tailwindcss/tailwind.css';

interface MicrophoneWaveProps{
    width:string
    mediaStream:MediaStream
    setCanWeHearYou: (arg0: boolean) => void
    canWeHearYou: boolean
}

function MicrophoneWave(props:MicrophoneWaveProps) {
  const audioRef = useRef<HTMLCanvasElement>(null);
  const dataArrayRef = useRef<Uint8Array>(); // Store the previous dataArray

  useEffect(() => {
    let audioContext: AudioContext | null = null; // Define audioContext outside the promise for broader scope

    if (props.mediaStream) {
        let stream = props.mediaStream;
        audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        // Create a BiquadFilterNode
        // const lowPassFilter = audioContext.createBiquadFilter();
        // lowPassFilter.type = 'lowpass';
        // lowPassFilter.frequency.value = 5000; // Example cutoff frequency at 5000 Hz

        // // Connect the microphone stream to the filter, then the filter to the analyser
        // microphone.connect(lowPassFilter);
        // lowPassFilter.connect(analyser);
        microphone.connect(analyser);
        analyser.fftSize = 512;
        analyser.smoothingTimeConstant = 0.5; // Adjusted for smoothing, but not 1 to avoid over-smoothing
        const bufferLength = analyser.frequencyBinCount;

        const canvas = audioRef.current;
        if (!canvas) return;
        const canvasContext = canvas.getContext('2d');
        if (!canvasContext) return;

        const draw = () => {
            requestAnimationFrame(draw);
    
            const canvas = audioRef.current;
            if (!canvas) return; // Exit if canvas is null
            const canvasContext = canvas.getContext('2d');
            if (!canvasContext) return;
    
            let dataArray = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteTimeDomainData(dataArray);
    
            // Update the canvas using lerpArray instead of dataArray directly
            canvasContext.fillStyle = 'rgb(255, 255, 255, 1)';
            canvasContext.fillRect(0, 0, canvas.width, canvas.height);

            // Create a linear gradient (from left to right)
            const gradient = canvasContext.createLinearGradient(0, 0, canvas.width, 0);
            // Add color stops for the gradient transition
            gradient.addColorStop(0, 'white'); // Start with white
            gradient.addColorStop(0.3, 'rgb(220, 38, 38, 0.5)'); // Transition to 'rgb(37, 99, 235, 1)'
            gradient.addColorStop(0.7, 'rgb(220, 38, 38, 0.5)'); // Transition to 'rgb(37, 99, 235, 1)'
            gradient.addColorStop(1, 'white'); // Back to white
    
            canvasContext.lineWidth = 3;
            canvasContext.strokeStyle = gradient;
            canvasContext.beginPath();
    
            let sliceWidth = canvas.width * 1.0 / dataArray.length;
            let x = 0;
            
            for (let i = 0; i < dataArray.length; i++) {
                let v = dataArray[i] / 128.0;
                let y = v * canvas.height / 2;

                if(!props.canWeHearYou && y > 105){
                    props.setCanWeHearYou(true)
                }

                if (y < 101 && y > 99) y=canvas.height / 2
        
                if (i === 0) {
                    canvasContext.moveTo(x, y);
                } else {
                    // Calculate the midpoint for the quadratic curve
                    let x_mid = (x + (x - sliceWidth)) / 2;
                    let y_mid = (y + (dataArray[i - 1] / 128.0 * canvas.height / 2)) / 2;
                    let cp_x = (x_mid + (x - sliceWidth)) / 2; // Control point x
                    let cp_y = (y_mid + (dataArray[i - 1] / 128.0 * canvas.height / 2)) / 2; // Control point y
            
                    // Adjust control points to smooth out the curve
                    if (i > 1) { // Adjust control point for a smoother transition
                        let previous_v = dataArray[i - 2] / 128.0;
                        let previous_y = previous_v * canvas.height / 2;
                        cp_y = y_mid + (y - previous_y) / 2;
                    }
            
                    canvasContext.quadraticCurveTo(cp_x, cp_y, x, y);
                }
        
                x += sliceWidth;
            }
    
            canvasContext.lineTo(canvas.width, canvas.height / 2);
            canvasContext.stroke();
    
            // Store the current dataArray for the next frame
            dataArrayRef.current = dataArray;
        };
    
        draw();
    }
    // Cleanup function
    return () => {
        if (audioContext) {
            audioContext.close(); // Close the AudioContext
        }
    };
  }, []);

  return (
    <div className="flex justify-center items-center">
      <canvas ref={audioRef} className="bg-gray-100" width={props.width} height="200"></canvas>
    </div>
  );
};

export default MicrophoneWave;

