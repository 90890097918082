export const pricing = {
    frequencies: [
      { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
      { value: 'annually', label: 'Annually', priceSuffix: '/year' },
    ],
    tiers: [
      {
        name: 'Essential',
        id: 'tier-small',
        href: 'https://vetworks.io/buy',
        price: { monthly: '$249', annually: '$2.5k' },
        description: 'Elevate your care with VetWorks,',
        features: [
          '2-week free trial',
          '20 sessions per day', 
          '5 Custom Templates', 
          '1 year of sessions storage',
          '24/7 support'],
        mostPopular: false,
      },
      {
        name: 'Advanced',
        id: 'tier-medium',
        href: 'https://vetworks.io/buy',
        price: { monthly: '$999', annually: '$10k' },
        description: 'Scale care with VetWorks.',
        features: [
          '2-week free trial',
          '100 sessions per day',
          'Unlimited Custom Templates',
          'Unlimited session storage',
          'Session and template sharing',
          '24/7 dedicated support',
        ],
        mostPopular: true,
      },
      {
        name: 'Enterprise',
        id: 'tier-large',
        href: 'mailto:support@vetrec.io',
        price: { monthly: 'Contact Us', annually: 'Contact Us' },
        description: 'Get started with VetWorks.',
        features: [
          'Unlimited sessions per day', 
          'Unlimited Cutstom Templates', 
          'Unlimited session storage',
          'Session and template sharing',
          'Branded experience',
          'Advanced insights',
          'Intake automation',
          '24/7 dedicated support',,   
        ],
        mostPopular: false,
      },
    ],
  }