import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { filterNotMentioned, filterNotMentionedHTML, revertPreprocessedText, revertPreprocessedTextNoStyling } from "../../utils/TextUtils";
import va from '@vercel/analytics';
import { GetDischargeSignedURL } from "../../supabase/supabaseProxy";
import { useSupabase } from "../../supabase/SupabaseContext";
import { ArrowDownCircleIcon, CheckIcon, ClipboardDocumentIcon, CloudIcon, DocumentTextIcon, HandThumbDownIcon, HandThumbUpIcon, LanguageIcon, MicrophoneIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { Spinner } from "../../utils/Spinner";
import MoreMenu from "../moreMenu";
import { Loading } from "../../utils/Loading";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'; // Import Quill styles"
import { useHistory } from "../../providers/HistoryProvider";
import { downloadDischargePDF } from "../../ServerActions";
import TemplatePicker from "../templates/TemplatePicker";
import { TemplateType } from "../templates/TemplateUtils";
import { WarningBanner } from "../common/WarningBanner";

interface DischargeProps {

}

export const Discharge: React.FC<DischargeProps> = ({ }) => {

    const { user } = useUser()
    const { getToken } = useAuth()
    const {organization} = useOrganization()
    const [savingDischarge, setSavingDischarge] = useState<boolean>(false)
    const [copySuccess, setCopySuccess] = useState<boolean>(false)
    const [dischargeChanged, setDischargedChanged] = useState<boolean>(false)
    const [didLastSaveFail, setDidLastSaveFail] = useState<boolean>(false)
    const { uploadToSupabaseSignedURL } = useSupabase()
    const {activeSession, displayActiveDischarge, activeDischarge, dischargeLoading, feedbackDischarge, loadingSessionInfo, dischargeProcessing, transcriptProcessing, notesProcessing, setDisplayActiveDischarge, setNotification, setNotificationTitle, handleMarkComplete, handleAddToSession, handleThumbsInput, handleLanguageChange, setError, handleDischargeTemplateChange, dischargeStreaming, activeNotes, generateDischarge, templateDischarge, setTemplateDischarge, notesStreaming, notesLoading, template, setTemplate, processTranscript, activeTranscript, activeSessionUpdated, getLoggingProperties } = useHistory()

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (copySuccess) {
            timer = setTimeout(() => {
                setCopySuccess(false);
            }, 1000); // 5000ms = 5 seconds
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [copySuccess]);

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (savingDischarge) {
            timer = setTimeout(() => {
                setSavingDischarge(false)
            }, 3000); // 5000ms = 5 seconds
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
        };
    }, [savingDischarge]);

    const copyDischargeToClipboard = async () => {
        try {
            setCopySuccess(true)
            let text_temp_rich = filterNotMentionedHTML(displayActiveDischarge) // rich text
            let text_temp_plain
            if((user && user.publicMetadata.hasOwnProperty("pims")) ){
                if(user.publicMetadata['pims'] == "cornerstone" || user.publicMetadata['pims'] == "avimark"){
                    text_temp_plain = filterNotMentioned(revertPreprocessedTextNoStyling(displayActiveDischarge))
                }
                else {
                    text_temp_plain = filterNotMentioned(revertPreprocessedText(displayActiveDischarge))
                
                }
            }
            else if ((organization?.publicMetadata.hasOwnProperty("pims"))){
                if(organization?.publicMetadata['pims'] == "cornerstone" || organization?.publicMetadata['pims'] == "avimark"){
                    text_temp_plain = filterNotMentioned(revertPreprocessedTextNoStyling(displayActiveDischarge))
                }
                else {
                    text_temp_plain = filterNotMentioned(revertPreprocessedText(displayActiveDischarge))
                
                }
            }
            else {
                text_temp_plain = filterNotMentioned(revertPreprocessedText(displayActiveDischarge))
            }

            let text = new ClipboardItem({
                'text/html': new Blob([text_temp_rich], { type: 'text/html' }),
                'text/plain': new Blob([text_temp_plain], { type: 'text/plain' })
            });

            await navigator.clipboard.write([text]);
            setNotification(true)
            setNotificationTitle("Notes copied to clipboard")
            va.track("HistoryPage_CopyDischarge", getLoggingProperties())
        } catch (err) {
            setNotification(false)
            setNotificationTitle(undefined)
            setError("There was an issue copying the communications document. Please try again.")
            va.track("HistoryPage_CopyDischarge_Failed", getLoggingProperties())
        }
    };
    
    const saveDischarge = async () => {
        if(activeSession){
            try {
                setDidLastSaveFail(false)
                setSavingDischarge(true)
                setNotification(true)
                setNotificationTitle("Saving discharge notes...")
                if (!didLastSaveFail) throw new TypeError()
                let fileName = activeSession?.owner + "/" + activeSession.id + "/" + activeSession.id +"_discharge.txt"
                const blob = new Blob([revertPreprocessedText(displayActiveDischarge)], {type: 'text/plain'})
                const file = new File([blob], `${activeSession.id}.json`, {type: 'text/plain'})
                let signedURL = await GetDischargeSignedURL(activeSession.id, await getToken({template:"supabase"}) ?? '')
                await uploadToSupabaseSignedURL(file, fileName, "discharge", signedURL.signed_url.token)  
                setNotificationTitle("Saved discharge notes!")
                va.track("History_SaveDischarge", getLoggingProperties())  
            } catch (err) {
                setNotification(false)
                setNotificationTitle(undefined)
                setError("There was an issue saving the communications document. Please try again.")
                setDidLastSaveFail(true)
                va.track("History_SaveDischarge_Failed", getLoggingProperties())
            }
        }
    }

    
    async function handleDownloadPDF(): Promise<void> {
        try {
            setNotification(true)
            setNotificationTitle("Downloading PDF...")
            let signed_url = await downloadDischargePDF(activeSession?.id ?? "", await getToken({template:"supabase"}) ?? "")
            if(signed_url){
                // Trigger the PDF download
                const link = document.createElement('a');
                link.href = signed_url['signed_url'];
                link.target = '_blank';
                link.setAttribute('download', `${activeSession?.name}_discharge.pdf`); // You can specify the file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                va.track("HistoryPage_DownloadDischargePDF", getLoggingProperties())
            }
            else {
                setNotification(false)
                setNotificationTitle(undefined)
                setError("There was an issue downloading the PDF. Please try again.")
                va.track("HistoryPage_DownloadDischargePDF_FailedNoSignedUrl", getLoggingProperties())
            }    
        } catch (err) {
            setNotification(false)
            setNotificationTitle(undefined)
            setError("There was an issue downloading the PDF. Please try again.")
            va.track("HistoryPage_DownloadDischargePDF_Failed", getLoggingProperties())
        }
    }


    return(<>
        {activeSession && <div>
            {(dischargeLoading || loadingSessionInfo) && !activeDischarge && !transcriptProcessing && !notesProcessing && !dischargeProcessing && <Loading text={dischargeLoading ?? loadingSessionInfo ?? ""}/>}
            {( notesProcessing || notesStreaming || (dischargeProcessing && !activeDischarge) || transcriptProcessing) && !dischargeStreaming && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-6 mb-8 font-semibold'>
                {transcriptProcessing && <div>Transcribing recording 🤖....</div>}
                {!dischargeProcessing && !transcriptProcessing && <div>Generating notes 🤖....</div>}
                {dischargeProcessing && !transcriptProcessing && <div>Generating client communications 🤖....</div>}
                <Spinner size="w-12 h-12" timer={true}/>
                <div className="text-sm text-center text-gray-700 font-normal">Notes will be ready within 60s.<br/>Feel free to navigate away if you need to.</div>
            </div>}
            {!activeSessionUpdated && activeTranscript && !activeNotes && !activeDischarge && !dischargeLoading && !dischargeProcessing && !dischargeStreaming && !loadingSessionInfo && !notesProcessing && !notesStreaming && !notesLoading && <div className="bg-blue-50 shadow-xl sm:rounded-lg p-4">
                <div className="px-4 py-5 sm:p-6 xl:flex xl:flex-row xl:justify-between xl:item-center">
                    <div>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Generate notes</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <p>Generate medical notes for the visit.</p>
                        </div>
                    </div>
                    <div className="xl:mt-0 mt-5 flex flex-col sm:flex-row md:items-center gap-x-4 gap-y-4">
                        <TemplatePicker template={template} templateId_force={activeSession.template_id} setTemplate={setTemplate} className="h-10" templateBackground='bg-transparent' type={TemplateType.MEDICAL}/>
                        <button
                            type="button"
                            className="inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center"
                            onClick={() => processTranscript(activeNotes ? true : false)}
                        >
                            Generate
                            <SparklesIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>}
            {!activeSessionUpdated && activeNotes && !activeDischarge && !dischargeLoading && !dischargeProcessing && !dischargeStreaming && !loadingSessionInfo && !notesProcessing && !notesStreaming && !notesLoading && <>
                <div className="bg-blue-50 sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6 xl:flex xl:flex-row xl:justify-between xl:item-center">
                        <div>
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Generate Client document</h3>
                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <p>Generate client communication documents for the visit.</p>
                            </div>
                        </div>
                        <div className="xl:mt-0 mt-5 flex flex-col sm:flex-row md:items-center gap-x-4 gap-y-4">
                            <TemplatePicker template={templateDischarge} setTemplate={setTemplateDischarge} className="h-10" templateBackground='bg-transparent' type={TemplateType.COMMUNICATIONS}/>
                            <button
                                type="button"
                                className="inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center"
                                onClick={() => generateDischarge(activeDischarge ? true : false)}
                            >
                                Generate
                                <SparklesIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </>}
            {activeDischarge && !dischargeLoading && !notesProcessing && !notesStreaming && !notesLoading && <div className="flex flex-col gap-y-4 text-sm text-gray-500">
                <div  className="flex xl:flex-row flex-col gap-y-4 justify-end gap-x-4 xl:items-center items-end w-full">
                    {didLastSaveFail && <WarningBanner warningText="There was an issue saving the communications document. Please try again." />}
                    {!didLastSaveFail && dischargeStreaming && <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
                        <div className="flex grow items-center justify-center">
                            <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                                <div className="flex flex-row gap-x-4 items-center">
                                    <div>
                                        <DocumentTextIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                    </div>
                                    <div className="text-sm text-blue-900 font-semibold">We are finishing your client notes. Feel free to navigate away, your notes will be ready within 60s or less.</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="flex flex-row gap-x-2 w-full sm:w-auto">
                        <button
                            type="button"
                            title={activeSession.status.general && activeSession.status.general == "Completed" ? "This session is completed" : "Mark this session as completed"}
                            className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-40 ${activeSession.status.general && activeSession.status.general == "Completed" ? 'bg-green-600 hover:bg-green-500 font-semibold text-white' : 'bg-white'}`}
                            onClick={() => handleMarkComplete()}
                        >
                            <CheckIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                            <span className="hidden sm:block">{activeSession.status.general && activeSession.status.general == "Completed" ?  "Completed" : "Mark completed"}</span>
                        </button>
                        <button
                            type="button"
                            title="Record additional information for this session which will be added to the notes."
                            className="whitespace-nowrap inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52"
                            onClick={() => handleAddToSession()}
                        >
                            <MicrophoneIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                            <span className="hidden sm:block">Add to visit</span>
                        </button>
                        <div className="sm:hidden flex flex-row gap-x-2">
                            <button
                                type="button"
                                title="Copy discharge notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => copyDischargeToClipboard()}
                            >
                                {!copySuccess && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />}
                                {copySuccess && <Spinner size="h-5 w-5" timer={false}/>}
                            </button>
                            {!feedbackDischarge && <button
                                type="button"
                                title="Provide positive feedback on notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => handleThumbsInput(true, "Discharge")}
                            >
                                <HandThumbUpIcon className="h-5 w-5 text-white"/>
                            </button>}
                            {!feedbackDischarge && <button
                                type="button"
                                title="Provide negative feedback on notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => handleThumbsInput(false, "Discharge")}
                            >
                                <HandThumbDownIcon className="h-5 w-5 text-white"/>
                            </button>}
                        </div>
                        <MoreMenu 
                            moreText="Actions" 
                            options={[{
                                name:"Generate Document",
                                action: () => handleDischargeTemplateChange(true),
                                icon: <DocumentTextIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
                            },{
                                name:"Change Language",
                                action: () => handleLanguageChange(true),
                                icon: <LanguageIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
                            },
                            {
                                name:"Download PDF",
                                action: () => handleDownloadPDF(),
                                icon: <ArrowDownCircleIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
                            }]} 
                            height="h-10 sm:h-12"/>
                    </div>
                </div>
                <div className="relative h-full">
                    <div className="flex flex-col sm:flex-row w-full">
                        <div className="flex flex-col items-end justify-end w-full">
                            {dischargeStreaming && <div className="absolute top-2 sm:top-2 right-4 sm:right-4 flex flex-col gap-y-2">
                                <div
                                    className="flex flex-row relative items-center gap-x-2 rounded-md bg-white px-2.5 py-2.5 text-sm font-semibold border border-gray-300 text-gray-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 h-10 overflow-hidden justify-center z-[2] shadow"
                                >
                                    <Spinner size="h-5 w-5" timer={false}/>
                                    Writing notes... 
                                </div>
                            </div>}
                            <ReactQuill
                                theme='snow'
                                placeholder='Mr.Wiggles is looking mighty well today...'
                                className='block w-full lg:h-[68vh] h-[65vh] rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
                                value={displayActiveDischarge}
                                modules={{toolbar: false}}
                                onChange={(value, d, s, editor) => {
                                    setDisplayActiveDischarge(value)
                                    setDischargedChanged(true)
                                }}
                                readOnly={dischargeStreaming}
                            />
                            {dischargeChanged && !dischargeStreaming && <button
                                type="button"
                                className="relative items-center mt-2 gap-x-2 rounded-md w-20 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2] flex flex-row items-center"
                                onClick={() => saveDischarge()}
                            >
                                Save
                                {savingDischarge ? <Spinner size="h-5 w-5" timer={false} /> : <CloudIcon className="h-5 w-5 text-white hover:text-blue-400"/>}
                            </button>}
                        </div>
                        {!dischargeStreaming && <div className="hidden sm:flex flex-col gap-y-2 pl-2 items-center">
                            <button
                                type="button"
                                title="Copy discharge notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => copyDischargeToClipboard()}
                            >
                                {!copySuccess && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />}
                                {copySuccess && <Spinner size="h-5 w-5" timer={false}/>}
                            </button>
                            {!feedbackDischarge && <button
                                type="button"
                                title="Provide positive feedback on notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => handleThumbsInput(true, "Discharge")}
                            >
                                <HandThumbUpIcon className="h-5 w-5 text-white hover:text-blue-400"/>
                            </button>}
                            {!feedbackDischarge && <button
                                type="button"
                                title="Provide negative feedback on notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => handleThumbsInput(false, "Discharge")}
                            >
                                <HandThumbDownIcon className="h-5 w-5 text-white hover:text-blue-400"/>
                            </button>}
                        </div>}
                    </div>
                </div>
            </div>}
        </div>}
    </>)
}