import { useState } from "react"
import { intakeMessage } from "./ServerActions"
import { v4 as uuidv4 } from 'uuid';
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Cog6ToothIcon, DocumentIcon } from "@heroicons/react/24/solid";
import {useSupabase} from './supabase/SupabaseContext'
import { GetQuestionaire } from "./supabase/supabaseProxy";
import { useAuth } from "@clerk/clerk-react";

// Define the types for your props
interface IntakeProps {
    
}

interface Message {
    user: string
    content: string
}

interface MessageResponse {
    session: string
    next_message: string
    done: boolean
}

export default function Intake(props:IntakeProps) {
    const [messages, setMessages] = useState<Message[]>([{"user":"Kevin", "content":"Hello! Ahead of your appointment, we need to collect some information. This helps us maximize the doctor's time with you pet. Let's start with why are you coming in to see the doctor?"}])
    const [input, setInput] = useState<string>()
    const [sessionId, setSessionId] = useState<string>(uuidv4().toString())
    const [questionnaire, setQuestionnaire] = useState<string>()
    const [showProgress, setShowProgress] = useState<boolean>(false)
    const [thinking, setThinking] = useState<boolean>(false)
    const { getToken} = useAuth();
    
    async function triggerProgress(): Promise<void>{
        try{
            let questionaire_response = await GetQuestionaire(sessionId, await getToken({template:"supabase"}) ?? '')
            let prettyJSON = JSON.stringify(JSON.parse(questionaire_response ?? "{}"), null, 3)
            const htmlContent = prettyJSON.replace(/\n/g, '<br>').replace(/ /g, '&nbsp;');
            setQuestionnaire(htmlContent)
            setShowProgress(true)
        } catch {
            setQuestionnaire("Answer some questions to see the answers.")
            setShowProgress(true)
        }
    }

    async function handleMessageSend(): Promise<void> {
        if(input){
            setThinking(true)
            let temp_messages = [...messages, {"user":"User", "content":input}]
            setMessages([...temp_messages, {"user":"Kevin", "content":"......"}])
            setInput("")
            let message_response: MessageResponse = await intakeMessage(sessionId, input, temp_messages.length < 2)
            setMessages([...temp_messages, {"user":"Kevin", "content":message_response.next_message}])
            setThinking(false)
            if (message_response.done == true){
                triggerProgress()
            }
        }
    }

    function handleInputChange(value: string): void {
        setInput(value)
    }

    return(<div className="overflow-hidden">
        <div className={`sticky top-0 z-40 flex items-center gap-x-6 bg-main px-4 py-4 shadow-sm sm:px-6`}>
            <div className="flex-1 text-md font-semibold leading-6 text-main-text">{"VetRec"}</div>
            <button className="text-sm text-main-text font-semibold hover:bg-main-lighter rounded-md p-1" onClick={() => triggerProgress()}>
                Show Progress
            </button>
        </div>
        <div className="flex flex-col justify-between h-[94vh] px-10 py-4 overflow-hidden">
            <div className="flex-grow max-h-[90vh] overflow-auto flex flex-col gap-y-4 thin-scrollbar px-2">
                {messages?.map((message, index) => (
                    <div key={index} className={`flex w-full ${message.user == "User" ? "justify-end" : "justify-start"}`}>
                        <div className="py-4 px-4 border border-gray-300 rounded-lg max-w-[50%]"><span className="font-semibold">{message.user}:</span> {message.content}</div>
                    </div>
                ))}
            </div>
            <div className="flex rounded-md shadow-sm mt-12">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <input
                        type="message"
                        name="message"
                        id="message"
                        value={input}
                        disabled={thinking}
                        className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 h-12"
                        placeholder="Send message..."
                        onChange={(e) => handleInputChange(e.target.value)}
                    />
                </div>
                <button
                    type="button"
                    disabled={thinking}
                    onClick={() => handleMessageSend()}
                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    Send
                </button>
            </div>
        </div>
        <Transition.Root show={showProgress} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setShowProgress}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                            <DocumentIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 flex flex-col justify-center items-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Questionnaire Answers
                            </Dialog.Title>
                            <div className="mt-2">
                            <p className="text-sm text-gray-500 max-w-[300px] break-words" dangerouslySetInnerHTML={{ __html: questionnaire ?? "" }}>
                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            onClick={() => setShowProgress(false)}
                        >
                            Back to conversation
                        </button>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
            </Transition.Root>
    </div>)
}