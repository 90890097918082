import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowPathIcon, DocumentIcon } from '@heroicons/react/24/solid'
import { SectionType } from './TemplateUtils'
import ReactQuill from 'react-quill'
import { MainButton } from '../MainButton'
import { generateTemplate } from '../../ServerActions'
import { useAuth, useUser } from '@clerk/clerk-react'
import { Loading } from '../../utils/Loading'
import { UserAction } from '../../utils/vercelAnaytics'

interface TemplateGenerateModalProps{
    open:boolean,
    setOpen: (value: boolean) => void
    items: SectionType[] 
    setItems: (array_sections: { [key: string]: any }[]) => void
}

export default function TemplateGenerateModal(props:TemplateGenerateModalProps) {

    const [generating, setGenerating] = useState<boolean>(false)
    const [sampleReport, setSampleReport] = useState<string>()
    const {getToken} = useAuth()
    const { user } = useUser()
    
    async function handleTemplateGenerate(){
        UserAction(user?.emailAddresses[0].emailAddress ?? "", "TemplateGenerate_Run")
        setGenerating(true)
        let template_config = await generateTemplate(sampleReport ?? "", await getToken({template:"supabase"}) ?? "")
        props.setItems(template_config['template_config']['sections'])
        setSampleReport(undefined)
        props.setOpen(false)
        setGenerating(false)
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={props.setOpen}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full xl:max-w-4xl lg:max-w-4xl md:max-w-2xl sm:max-w-2xl sm:p-6 lg:ml-72">
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <ArrowPathIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5 flex flex-col gap-y-4">
                                <div>
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Generate Template
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Provide us an existing set of notes or one of your templates and let us generate a VetRec ready template for you!
                                        </p>
                                    </div>
                                </div>
                                {!generating && <ReactQuill
                                    theme='snow'
                                    placeholder='Add in your notes or template here'
                                    className='mt-4 block w-full h-[60vh] rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
                                    value={sampleReport}
                                    modules={{toolbar: false}}
                                    onChange={(value, d, s, editor) => {
                                        setSampleReport(value)
                                    }}
                                />}
                                {!generating && <MainButton title="Generate the template using VetRec AI" text='Generate Template' action={handleTemplateGenerate} icon={<DocumentIcon className='h-5 w-5'/>} size={"h-10"}/>}
                                {generating && <Loading text=''/>}
                            </div>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}