import { Fragment, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { LanguageIcon } from '@heroicons/react/24/solid'
import LanguagePicker from '../LanguagePicker'
import { useHistory } from '../../providers/HistoryProvider'
import { isMobileDevice } from '../../utils/deviceUtils'

export default function TranslateModal() {

    const {language, changeLanguage, handleLanguageChange, setLanguage, translateDischarge} = useHistory()

    function handleLanguage(){
        handleLanguageChange(false)
        translateDischarge()
    }

    const languages = ["English", "Spanish", "French", "Portuguese", "Russian", "Hindi", "Chinese", "Cantonese", "Mandarin"]
    const isMobile = useMemo(isMobileDevice, [])

    return (
        <Transition.Root show={changeLanguage} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={handleLanguageChange}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                            <LanguageIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            Change Language
                            </Dialog.Title>
                            <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                Reprocess your discharge notes with a different language. Pick from the options below:
                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center">
                            <LanguagePicker language={language} setLanguage={setLanguage} className={""} labelBackground={"bg-white"} languages={languages} direction={isMobile ? 'above' : "below"}/>
                            <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={() => handleLanguage()}
                            >
                                Change
                            </button>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}