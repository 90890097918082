import { useState, type CSSProperties, type FC, useRef, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { ContentType, EntryType, SectionType, SectionTypes } from './TemplateUtils'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { Entry } from './Entry'
import { useTemplate } from './TemplateProvider'
import SectionTypePicker from './sectionTypePicker'
import InputTooltip from './InputTooltip'

interface SectionProps {
    sectionKey:string
    type: SectionTypes
    name: string|undefined
    entries:EntryType[]
    description?:string|undefined
    defaultValue?:string|undefined
    hardcodedValue?:string|undefined
}

export const AddEntryArea: FC = () => {
  const {handleEntryOver} = useTemplate()
  const ref = useRef<HTMLDivElement>(null)

  const [{isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.ENTRY, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.OWNER_DISCUSSION, ItemTypes.RENDERED_SECTION, ItemTypes.SECTION, ItemTypes.SECTION, ItemTypes.ASSESSMENT, ItemTypes.PLAN, ItemTypes.PHYSICAL_EXAM, ItemTypes.FEAR_FREE, ItemTypes.SUBJECTIVE, ItemTypes.VITALS],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item:any, monitor){
      handleEntryOver(undefined)
    }
  }))

  drop(ref)

  return (
  <div ref={ref} className='border border-dashed border-gray-300 rounded-md p-4 text-center'>
    Add Entry to section
  </div>
  )
}

export const Section: FC<SectionProps> = ({sectionKey, name, entries, type, description, defaultValue, hardcodedValue}) => {
  const {updateSectionName, updateSectionDescription, updateSectionDefaultValue, deleteSection, updateSectionType, swapSectionsByKeys, handleSectionOver, sectionHoverIndex,entryHoverIndex, sectionDragged, entryDragged, updateSectionHardcodedValue, handleSubSectionOver} = useTemplate()
  const ref = useRef<HTMLDivElement>(null)

  const [{isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.ENTRY, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.OWNER_DISCUSSION, ItemTypes.RENDERED_SECTION, ItemTypes.SECTION, ItemTypes.SECTION, ItemTypes.ASSESSMENT, ItemTypes.PLAN, ItemTypes.PHYSICAL_EXAM, ItemTypes.FEAR_FREE, ItemTypes.SUBJECTIVE, ItemTypes.VITALS],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item:any, monitor){
      if (!ref.current) {
        return
      }
      if (item['sectionKey'] === sectionKey) {
        return
      }

      handleSectionOver(sectionKey)   
      
      handleSubSectionOver(false)

      if(item['sectionKey'] && item['name'] == ItemTypes.RENDERED_SECTION){
        swapSectionsByKeys(sectionKey, item['sectionKey'])
      }
    }
  }))

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.RENDERED_SECTION,
    item: () => {
      return { sectionKey, "name":ItemTypes.RENDERED_SECTION}
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const isActive = isOver

  drag(drop(ref))

  return (<>
    {sectionHoverIndex === sectionKey && sectionDragged && <div className='w-full min-h-1 bg-blue-600 rounded-md' />}
    <div ref={ref} className={`w-full cursor-move ${isDragging ? "border-2 border-blue-600 rounded-md" : ""}`} data-testid="section text-gray-500">
      <div className={`flex flex-col gap-y-2 border border-gray-200 p-4 rounded-md ${isDragging ? "opacity-0" : "opacity-1"}`}>
        <div className='flex flex-row items-center justify-between'>
          <input className='nodrag border-t-0 border-l-0 border-r-0 border-gray-300 focus:rounded-md h-8 text-gray-900' placeholder='Section Name' value={name} onChange={(event) => updateSectionName(sectionKey, event.target.value)} draggable={true} onDragStart={event => event.preventDefault()}/>
          <div className='flex flex-row gap-x-2 items-center'>
            <div className="flex flex-row gap-x-1">
              <SectionTypePicker sectionType={type} setSectionType={(type) => updateSectionType(sectionKey, type)}/>
            </div>
            <div className="cursor-pointer" onClick={() => deleteSection(sectionKey)}>
              <XCircleIcon className='w-5 h-5 text-blue-600 hover:text-blue-500' />
            </div>
          </div>
        </div>
        {[SectionTypes.NUMBER, SectionTypes.PARAGRAPH, SectionTypes.SIMPLE_LIST].includes(type) && <div className='flex flex-col gap-y-2'>
            <div className='w-full flex flex-row gap-x-2 items-center justify-start'>
              Instructions:
              <InputTooltip direction="right" content={<div className='flex flex-col'>
                <div className='font-semibold'>Recommendations</div>
                <ul className='list-disc list-inside'>
                  <li>The simpler, the better</li>
                  <li>Be specific. Tell the system what exactly you want.</li>
                  <li>Avoid negative language. (e.g. Do not include). Use positive language. (e.g. Include)</li>
                  <li>Keep conditional statements to a minimum.</li>
                  <li>Add examples for correct behavior. (e.g. Format medications as...) Avoid wrong behavior examples. (e.g. Do not format as)</li>
                  <li>Use this format for examples: (e.g. Example for the entry)</li>
                  <li>Be consistent with names used and format.</li>
                </ul>
              </div>}>
                <textarea className="nodrag rounded-md border-gray-300 w-full text-gray-900 resize-none h-10 focus:h-32 transition-height duration-300 ease-in-out thin-scrollbar"placeholder='(Optional) Provide instructions to VetRec. e.g. Include dosage' value={description} onChange={(event) => updateSectionDescription(sectionKey, event.target.value)} draggable={true} onDragStart={event => event.preventDefault()}/>
              </InputTooltip>
            </div>
            <div className='flex flex-row gap-x-2 items-center justify-start'>
              Default:
              <InputTooltip direction="right" content={<div>
                <div className='font-semibold'>Default values</div>
                <ul className='list-disc list-inside'>
                  <li>Used when information for the entry is not provided in the session.</li>
                  <li>Format like you expect the information to be presented</li>
                </ul>
              </div>}>
                <textarea className="nodrag rounded-md border-gray-300 h-10 w-full text-gray-900 resize-none h-10 focus:h-32 transition-height duration-300 ease-in-out thin-scrollbar" placeholder='(Optional) Provide default values like normals' value={defaultValue} onChange={(event) => updateSectionDefaultValue(sectionKey, event.target.value)} draggable={true} onDragStart={event => event.preventDefault()}/>
              </InputTooltip>
            </div>
        </div>}
        {[SectionTypes.LIST, SectionTypes.NORMAL, SectionTypes.COLLECTION].includes(type) && <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                <span>Entries</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-blue-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-0 pb-2 pt-0 text-sm text-gray-500 flex flex-col gap-y-2">
                {entries.map((entry) => (
                  <Entry key={entry.entryKey} name={entry.name} description={entry.description} defaultValue={entry.defaultValue} entryKey={entry.entryKey} type={entry.type} sectionKey={sectionKey} subEntries={entry.subEntries} hardcodedValue={entry.hardcodedValue}/>
                ))}
                {sectionHoverIndex === sectionKey && entryHoverIndex === undefined && entryDragged && <div className='w-full min-h-1 bg-blue-600 rounded-md' />}
                {entries.length > 0 && sectionHoverIndex === sectionKey && entryDragged && <AddEntryArea />}
                {entries.length == 0 && <div className='flex flex-col items-center justify-center h-full mt-4'>
                  <div className='flex flex-col items-center gap-y-2'>
                    <div className='text-gray-500 text-md'>Drag and drop an entry</div>
                      <div className='text-gray-500 text-sm'>
                        Choose an empty entry or one of the pre-built options.
                      </div>
                    </div>
                  </div>
                }
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>}
        {type === SectionTypes.HARDCODED && <div className='flex flex-col gap-y-2'>
            <div className='w-full flex flex-row gap-x-2 items-center justify-start'>
              Value:
              <InputTooltip direction="right" content={<div className='flex flex-col'>
                <div className=''>This value will be added verbatim into the final set of notes.</div>
              </div>}>
                <textarea className="nodrag rounded-md border-gray-300 w-full text-gray-900 resize-none h-10 focus:h-32 transition-height duration-300 ease-in-out thin-scrollbar" placeholder='Value will be inserted into the final notes verbatim.' value={hardcodedValue} onChange={(event) => updateSectionHardcodedValue(sectionKey, event.target.value)} draggable={true} onDragStart={event => event.preventDefault()}/>
              </InputTooltip>
            </div>
          </div>}
      </div>
    </div>
  </>)
}
