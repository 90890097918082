import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { pricing } from "./utils/pricing";
import { CreateOrganization, OrganizationProfile, UserProfile, useAuth, useOrganization, useUser } from "@clerk/clerk-react"
import { Fragment, useEffect, useRef, useState } from "react";
import {  UsersIcon, UserIcon, LanguageIcon } from "@heroicons/react/24/solid";
import va from '@vercel/analytics';
import LanguagePicker from "./components/LanguagePicker";
import { updateLanguage } from "./ServerActions";
import Remainder from "./components/remainder";

// Define the types for your props
interface ConfigurationProps {
    
}

const tabs = [
    { name: 'Account', id: 'account', icon: UserIcon, current: true },
    { name: 'Team', id: 'team', icon: UsersIcon, current: true },
    // { name: 'Plan', id: 'plan', icon: CreditCardIcon, current: false },
  ]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Settings(props:ConfigurationProps) {

    const [frequency, setFrequency] = useState(pricing.frequencies[0])
    const [selectedTab, setSelectedTab] = useState<string>("team")
    const [upgrade, setUpgrade] = useState<boolean>(false)
    const [create, setCreate] = useState<boolean>(false)
    const { user } = useUser()
    const {organization} = useOrganization()
    const cancelButtonRef = useRef(null)
    const [inOrganization, setInOrganization] = useState<boolean>(false)
    const [saved, setSaved] = useState(false);

    const CustomPage = () => {
        const [language, setLanguage] = useState<string>("English");
        const languages = ["English", "Spanish"];
        const { user } = useUser();
        const { getToken} = useAuth();

        useEffect(() => {
            if (user && user.publicMetadata.hasOwnProperty("language")){
                setLanguage(user.publicMetadata.language as string)
            }
            else{
                setLanguage("English")
            }
        }, [user]);

        const handleSaveLanguage = async () => {
            setSaved(await updateLanguage(language, await getToken({template:"supabase"}) ?? ""));
            await user?.reload()
        };

        return (<>
            <div className="mb-20 flex flex-col gap-y-4">
                <h1>Configure the language for VetRec</h1>
                <div className="flex flex-row gap-x-4 ">
                    <LanguagePicker language={language} setLanguage={setLanguage} className="" labelBackground="bg-white" languages={languages}/>
                    <div className="flex w-full justify-start">
                        <button
                            type="button"
                            className="inline-flex w-20 justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            onClick={() => handleSaveLanguage()}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>);
    };

    function handleTabChange(value:string): void {
        setSelectedTab(value)
        let properties =  {
            date:(new Date()).toUTCString(),
            tab_destination: value
        }
        va.track("Settings_TabChange", properties)
    }

    function handleCreateClick(): void {
        setCreate(!create)
    }

    useEffect(() => {
        if(organization)
        {
            setInOrganization(true)
        }
    }, [])

    return(<>
        {/*Header*/}
        <div className="border-b border-gray-400 pb-5 mb-5 py-4 sm:pt-10  flex flex-row items-center justify-between">
            <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:text-3xl sm:tracking-tight">
                Settings
            </h2>
        </div>
        {/*Tabs*/}
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={tabs.find((tab) => tab.current)?.name}
                onChange={(event ) => handleTabChange(event.target.value)}
                >
                    {tabs.map((tab) => (
                        <option key={tab.id} value={tab.id}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                    <div
                        key={tab.name}
                        onClick={() => handleTabChange(tab.id)}
                        className={classNames(
                        selectedTab == tab.id
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                        )}
                        aria-current={selectedTab == tab.id ? 'page' : undefined}
                    >
                        <tab.icon
                        className={classNames(
                            selectedTab == tab.id ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />
                        <span>{tab.name}</span>
                    </div>
                    ))}
                </nav>
                </div>
            </div>
        </div>
        {/*Account*/}
        {selectedTab == "account" && <div className='flex flex-col sm:flex-row pt-5 gap-x-8 gap-y-8 pb-10'>
            <div className='w-full rounded-lg flex flex-col'>
                <div className={`bg-white py-5 sm:px-6 h-full`}>
                    <div className="flex flex-col sm:flex-row sm:items-center gap-y-4">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Your Account</h1>
                            <p className="mt-1 text-sm text-gray-500">
                                Manage your account settings
                            </p>
                        </div>
                    </div>
                    <div className="w-full h-full mt-8">
                        <UserProfile appearance={{
                            elements:{
                                rootBox:{
                                    width:"100%",
                                    minWidth:"100%",
                                    maxWidth: "100%"
                                },
                                card:{
                                    width:"100%",
                                    minWidth:"100%",
                                    maxWidth: "100%",
                                    margin: "0px"
                                }
                            }
                        }}>
                            <UserProfile.Page
                            label="Language"
                            labelIcon={<LanguageIcon className="text-gray-500"/>}
                            url="language"
                            >
                                <CustomPage />
                            </UserProfile.Page>
                        </UserProfile>
                    </div>
                </div>
            </div>
        </div>}
        {/*Team*/}
        {selectedTab == "team" && <div className='flex flex-col sm:flex-row pt-5 gap-x-8 gap-y-8 pb-10'>
            <div className='w-full rounded-lg flex flex-col'>
                <div className={`bg-white px-4 py-5 sm:px-6 h-full`}>
                    <div className="flex flex-row sm:items-center justify-between gap-y-4 gap-x-10">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Team Members</h1>
                            <p className="mt-1 text-sm text-gray-500">
                                Manage the users within your team
                            </p>
                        </div>
                        <div className="flex flex-row items-center justify-center gap-x-4">
                            {!inOrganization && <button className="text-sm d p-1 rounded-lg bg-main-button text-main-button-text hover:bg-main-button-hover w-24 text-center font-semibold h-12" onClick={() => handleCreateClick()}>Create</button>}
                            {inOrganization && user && <div className="flex items-center justify-center text-sm d p-1 rounded-lg bg-gradient-to-br from-blue-700 to-blue-400 text-gray-100 w-24 text-center font-semibold h-10 shadow-md">{user?.organizationMemberships[0].role.split(':')[1].charAt(0).toUpperCase() + user?.organizationMemberships[0].role.split(':')[1].slice(1)}</div>}
                        </div>
                    </div>
                    <div className="w-full h-full mt-8">
                        {inOrganization && <OrganizationProfile appearance={{
                            elements:{
                                rootBox:{
                                    width:"100%",
                                    minWidth:"100%",
                                    maxWidth: "100%"
                                },
                                card:{
                                    width:"100%",
                                    minWidth:"100%",
                                    maxWidth:"100%",
                                    margin: "0px"
                                }
                            }
                        }}/>}
                    </div>
                </div>
            </div>
        </div>}
        {/*Create Org*/}
        <Transition.Root show={create} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setCreate}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 sm:ml-72">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
                        <div className="bg-white">
                            <div className="flex items-center justify-center">
                                <CreateOrganization />
                            </div>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
        {saved && <Remainder show={saved} setShow={setSaved} title="Saved language preference" text=""/>}
    </>)
}