import { CheckCircleIcon, CheckIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';

interface CreateSessionButtonProps {
    handleCreatePatient: (value: string) => void;
}

const CreateSessionButton: React.FC<CreateSessionButtonProps> = ({handleCreatePatient}) => {
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const handleToggle = () => {
        setShowInput(!showInput);
    };

    const handleSubmit = () => {
        handleCreatePatient(inputValue);
        setInputValue('');
        setShowInput(false);
    }

    const handleClearName = () => {
        setInputValue('');
        setShowInput(false)
    }

    return (
        <div className="w-full">
            {!showInput && <button
                className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded-md w-full text-sm flex flex-row items-center justify-center gap-x-4"
                onClick={handleToggle}
            >
                <PlusCircleIcon className='h-4 w-4'/>
                Add Visit
            </button>}
            {showInput && (
                <div className="flex flex-row gap-x-2 items-center justify-center w-full">
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="border border-gray-300 rounded-lg text-black w-full placeholder:text-gray-400 sm:text-sm sm:leading-6 py-1.5"
                        placeholder="Name"
                    />
                    <button
                        className="bg-red-600 hover:bg-red-700 text-white font-bold px-2 py-2.5 rounded-lg"
                        onClick={() => handleClearName()}
                    >
                        <XMarkIcon className='h-4 w-4'/>
                    </button>
                    <button
                        className="bg-green-600 hover:bg-green-700 text-white font-bold px-2 py-2.5 rounded-lg"
                        onClick={() => handleSubmit()}
                    >
                        <CheckIcon className='h-4 w-4'/>
                    </button>
                </div>
            )}
        </div>
    );
};

export default CreateSessionButton;