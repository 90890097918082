import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { useRecording, PermissionStatus } from "../../providers/RecordingProvider"
import { Spinner } from "../../utils/Spinner"
import { BrowserAndPlatform, WhatBrowser, isMobileDevice } from '../../utils/deviceUtils';
import { useMemo } from "react";
import enableMic from '../../assets/enableMic.png'
import topLeftUrlIcon from '../../assets/top_left_urlicon.png'
import enableMicEdge from '../../assets/enableMic_edge.png'
import topLeftUrlIconEdge from '../../assets/top_left_urlicon_edge.png'
import allowMicIphone from '../../assets/allowMic_iphone.png'
import allowMicSafari from '../../assets/allowMic_Safari.png'

export default function PermissionsHelper() {

    const {sessionId, recordingState, microphones, stopRecording, startRecording, recordingAllowed, updateConsent, anyRecording, name, updateName, addToRecording, updateSelectedMicrophone, selectedMicrophone} = useRecording()
    const isMobile = useMemo(isMobileDevice, [])
    const typeDevice = useMemo(WhatBrowser, [])
    
    return(<>
        {recordingAllowed === PermissionStatus.FAILED_TIMEOUT && <div className="flex rounded-md bg-yellow-50 p-4">
            <div className="flex-shrink-0">
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">Please refresh the page to check for microphone permission. If this keeps happening, please email us at <a href="mailto:support@vetrec.io">support@vetrec.io</a></h3>
            </div>
        </div>}
        {recordingAllowed !== PermissionStatus.FAILED_TIMEOUT && <div>
                {( recordingAllowed === PermissionStatus.CHECKING || recordingAllowed === PermissionStatus.FETCHING) && <div className="flex flex-col justify-center items-center gap-y-6">
                    {recordingAllowed === PermissionStatus.CHECKING ? "Checking microphone permission" : "Fetching microphones available"}
                    <Spinner size='w-10 h-10' timer={false}/>
                </div>}
                {(recordingAllowed === PermissionStatus.DENIED || recordingAllowed === PermissionStatus.FAILED) && <div className="flex rounded-md bg-yellow-50 p-4">
                    <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Microphone not allowed </h3>
                        {!isMobile && <div className="mt-2 text-sm text-yellow-700">
                            {(typeDevice == BrowserAndPlatform.DesktopChrome || typeDevice == BrowserAndPlatform.Unknown) &&<div>
                                Enable your microphone in the top-left corner of the screen, where the URL bar is located:<br/>
                                <ul className="space-y-6 mt-2">
                                    <li className="relative flex gap-x-4">
                                        <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                        <div className="w-px bg-gray-200" />
                                        </div>
                                        <>
                                            <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                            </div>
                                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                <span className="font-medium text-yellow-700">Click on the top left icon</span>
                                                <img className='rounded-lg mt-2 shadow-md' src={topLeftUrlIcon} width={"50%"}/>
                                            </p>
                                        </>
                                    </li>
                                    <li className="relative flex gap-x-4">
                                        <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                        <div className="w-px bg-gray-200" />
                                        </div>
                                        <>
                                            <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                            </div>
                                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                <span className="font-medium text-yellow-700">Toggle the Microphone button on</span>
                                                <img className='rounded-lg mt-2 shadow-md' src={enableMic} width={"50%"}/>
                                            </p>
                                        </>
                                    </li>
                                    <li className="relative flex gap-x-4">
                                        <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                        <div className="w-px bg-gray-200 h-6" />
                                        </div>
                                        <>
                                            <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                            </div>
                                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                <span className="font-medium text-yellow-700">Reload the page to apply the changes!</span>
                                            </p>
                                        </>
                                    </li>
                                </ul>
                            </div>}
                            {typeDevice == BrowserAndPlatform.DesktopSafari &&<div>
                                Refresh the page to get prompted to access microphone<br/>
                                <ul className="space-y-6 mt-2">
                                    <li className="relative flex gap-x-4">
                                        <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                        <div className="w-px bg-gray-200" />
                                        </div>
                                        <>
                                            <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                            </div>
                                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                <span className="font-medium text-yellow-700">Select Allow</span>
                                                <img className='rounded-lg mt-2 shadow-md' src={allowMicSafari} width={"50%"}/>
                                            </p>
                                        </>
                                    </li>
                                </ul>
                            </div>}
                            {typeDevice == BrowserAndPlatform.DesktopEdge &&<div>
                                Enable your microphone in the top-left corner of the screen, where the URL bar is located:<br/>
                                <ul className="space-y-6 mt-2">
                                    <li className="relative flex gap-x-4">
                                        <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                        <div className="w-px bg-gray-200" />
                                        </div>
                                        <>
                                            <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                            </div>
                                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                <span className="font-medium text-yellow-700">Click on the top left icon</span>
                                                <img className='rounded-lg mt-2 shadow-md' src={topLeftUrlIconEdge} width={"50%"}/>
                                            </p>
                                        </>
                                    </li>
                                    <li className="relative flex gap-x-4">
                                        <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                        <div className="w-px bg-gray-200" />
                                        </div>
                                        <>
                                            <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                            </div>
                                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                <span className="font-medium text-yellow-700">Select "Allow" Microphone</span>
                                                <img className='rounded-lg mt-2 shadow-md' src={enableMicEdge} width={"50%"}/>
                                            </p>
                                        </>
                                    </li>
                                    <li className="relative flex gap-x-4">
                                        <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                        <div className="w-px bg-gray-200 h-6" />
                                        </div>
                                        <>
                                            <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                            </div>
                                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                <span className="font-medium text-yellow-700">Reload the page to apply the changes!</span>
                                            </p>
                                        </>
                                    </li>
                                </ul>
                            </div>}
                        </div>}
                        {isMobile && <div className="mt-2 text-sm text-yellow-700">
                            {typeDevice == BrowserAndPlatform.MobileChrome && <div>
                                Enable your microphone in the top-left corner of the screen, where the URL bar is located:<br/>
                                <ul className="space-y-6 mt-2">
                                    <li className="relative flex gap-x-4">
                                        <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                        <div className="w-px bg-gray-200" />
                                        </div>
                                        <>
                                            <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                            </div>
                                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                <span className="font-medium text-yellow-700">Click on the top left icon</span>
                                                <img className='rounded-lg mt-2 shadow-md w-48' src={topLeftUrlIcon}/>
                                            </p>
                                        </>
                                    </li>
                                    <li className="relative flex gap-x-4">
                                        <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                        <div className="w-px bg-gray-200" />
                                        </div>
                                        <>
                                            <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                            </div>
                                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                <span className="font-medium text-yellow-700">Toggle the Microphone button on</span>
                                                <img className='rounded-lg mt-2 shadow-md w-48' src={enableMic}/>
                                            </p>
                                        </>
                                    </li>
                                    <li className="relative flex gap-x-4">
                                        <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                        <div className="w-px bg-gray-200 h-6" />
                                        </div>
                                        <>
                                            <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                            </div>
                                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                <span className="font-medium text-yellow-700">Reload the page to apply the changes!</span>
                                            </p>
                                        </>
                                    </li>
                                </ul>
                            </div>}
                            {(typeDevice == BrowserAndPlatform.MobileSafari || typeDevice == BrowserAndPlatform.Unknown) && <div>
                                Reload the website and hit 'Allow' when prompted!<br/>
                                <img className='rounded-lg mt-2 shadow-md w-full max-w-60' src={allowMicIphone}/>
                            </div>}
                        </div>}
                    </div>
                </div>}
        </div>}
    </>)
}