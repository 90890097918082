import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { useState, type CSSProperties, type FC } from 'react'
import { MainTemplate } from './components/templates/MainTemplate'
import { EntryBox } from './components/templates/EntryBox'
import { SectionBox } from './components/templates/SectionBox'
import { TemplateProvider, useTemplate } from './components/templates/TemplateProvider'
import { ItemTypes } from './components/templates/ItemTypes'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import TemplateBuilderFTUE from './components/ftue/TemplateBuilderFTUE'
import { isMobileDevice } from './utils/deviceUtils'

export default function TemplateBuilder() {

  const isMobile = isMobileDevice()

  return (
    <>
      <main>
        <div className="border-b border-gray-400 pb-5 mb-5 flex flex-col justify-between gap-y-2 py-4 sm:pt-10">
            <div className='flex flex-row justify-between items-center'>
                <div>
                    <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                        Template Builder
                    </h2>
                    <p className="mt-2 text-sm text-gray-500">
                        Customize your notes to your style including sections, titles, default values and more. Provide custom instructions for VetRec to know how to best organize your notes.
                    </p>
                </div>
            </div>
        </div>
        {isMobile && 
          <div className='bg-accent w-full shadow-lg rounded-lg py-8 px-8 flex flex-col sm:flex-row justify-between items-center gap-x-8 overflow-hidden gap-y-8 max-h-72 sm:max-h-60'>
            <div className='flex flex-col items-center justify-between gap-y-6 h-full grow w-full sm:w-2/3'>
                <div className='text-center w-full sm:w-[600px]'>
                  <span className='font-semibold'>The VetRec Template Builder is not yet available on mobile devices.</span><br/><br/>To configure templates, please visit VetRec of a computer or contact us at <a className="underline" href="mailto:support@vetrec.io">support@vetrec.io</a>.
                </div>
            </div>
        </div>}
        {!isMobile && <TemplateProvider>
          <DndProvider backend={HTML5Backend}>
            <div className="max-w-full px-4 sm:px-4 lg:px-4">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 max-w-full lg:max-w-none">
                <div className="-mx-4 py-8 sm:mx-0 rounded-lg col-span-1 sm:col-span-2 xl:pt-8 h-[75vh] sm:h-[75vh] lg:h-[85vh]">
                  <MainTemplate />
                </div>
                <div className="-mx-4 sm:pl-4 py-4 border-l-2 border-gray-900/2 sm:mx-0 col-span-1 xl:pt-2 h-[75vh] sm:h-[75vh] lg:h-[85vh]">
                  <div className="flex flex-col gap-y-4 h-full"> 
                      <div className="h-[70vh] lg:h-[85vh] overflow-y-auto thin-scrollbar mt-4">
                        <div style={{clear: 'both' }} className='flex flex-col px-4 gap-y-4 h-full'>
                          <div>Drag and Drop</div>
                          <SectionBox name="Section" description="Top level sections for notes" type={ItemTypes.SECTION}/>
                          <EntryBox name="Entry" description='Data entry inside of a section' type={ItemTypes.ENTRY}/>
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                                  <span>Pre-built sections</span>
                                  <ChevronUpIcon
                                    className={`${
                                      open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-blue-500`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="pb-2 text-sm text-gray-500 flex flex-col gap-y-4">
                                  <SectionBox name="Subjective" description='Patient history' type={ItemTypes.SUBJECTIVE} />
                                  <SectionBox name="Vitals" description='Temperature, weight, BP, etc.' type={ItemTypes.VITALS} />
                                  <SectionBox name="Physical Exam" description='Body system assessments.' type={ItemTypes.PHYSICAL_EXAM} />
                                  <SectionBox name="Assessment" description='Problems and DDX.' type={ItemTypes.ASSESSMENT} />
                                  <SectionBox name="Plan" description='Tests, treatments, medication, etc.' type={ItemTypes.PLAN} />
                                  <SectionBox name="Fear Free" description='Entries for Fear Free related information.' type={ItemTypes.FEAR_FREE} />
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                                  <span>Pre-built entries</span>
                                  <ChevronUpIcon
                                    className={`${
                                      open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-blue-500`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="pb-2 text-sm text-gray-500 flex flex-col gap-y-4">
                                  <EntryBox name="Owner Discussion" description='' type={ItemTypes.OWNER_DISCUSSION} />
                                  <EntryBox name="Current Medication" description='' type={ItemTypes.CURRENT_MEDICATION} />
                                  <EntryBox name="Current Diet" description='' type={ItemTypes.CURRENT_DIET} />
                                  <EntryBox name="Current Preventatives" description='' type={ItemTypes.CURRENT_PREVENTATIVES} />
                                  <EntryBox name="Chronic Issues" description='' type={ItemTypes.CHRONIC_ISSUES} />
                                  <EntryBox name="Diagnostic Tests" description='' type={ItemTypes.DIAGNOSTIC_TESTS} />
                                  <EntryBox name="Treatments" description='' type={ItemTypes.TREATMENTs} />
                                  <EntryBox name="Vaccines" description='' type={ItemTypes.VACCINES} />
                                  <EntryBox name="Medication" description='' type={ItemTypes.MEDICATION} />
                                  <EntryBox name="Diet" description='' type={ItemTypes.DIET} />
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </DndProvider>
        </TemplateProvider>}
      </main>
    </>
  )
}
