import { useEffect, useState } from 'react';
import { ArrowDownCircleIcon, CheckIcon, ClipboardDocumentIcon, ExclamationCircleIcon, HandThumbDownIcon, HandThumbUpIcon, InformationCircleIcon, MicrophoneIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@clerk/clerk-react';
import { downloadPreHistoryPDF } from '../../ServerActions';
import { Loading } from '../../utils/Loading';
import va from '@vercel/analytics';
import { Spinner } from '../../utils/Spinner';
import { convertPreHistoryToString, uploadFilesAndStartRecap } from '../../utils/PreHistoryUtils';
import { useHistory } from '../../providers/HistoryProvider';
import { FileUploader } from '../recordsrecap/FileUploader';
import { useSupabase } from '../../supabase/SupabaseContext';
import { SummarizedHistoryView } from '../recordsrecap/SummarizedHistoryView';

interface PreHistoryProps{

}

export default function PreHistory({} : PreHistoryProps) {
    const { getToken} = useAuth();
    const {uploadToSupabaseSignedURL} = useSupabase();
    const [processing, setProcessing] = useState<boolean>(false)
    const [copySuccess, setCopySuccess] = useState<boolean>(false);
    const [pdfDownloading, setPdfDownloading] = useState<boolean>(false);
    const {activeSession, preHistoryLoading, preHistoryProcessing, preHistoryFailureMessage, preHistoryStreaming, activePreHistory, refreshActiveSession, handleAddToSession, handleMarkComplete, setNotification, setNotificationTitle, setError, handleThumbsInput} = useHistory()

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (copySuccess) {
            timer = setTimeout(() => {
                setCopySuccess(false);
            }, 1000); // 5000ms = 5 seconds
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [copySuccess]);

    const copyToClipboard = async () => {
        try {
            if(activePreHistory == undefined) return
            setCopySuccess(true)
            await navigator.clipboard.writeText(convertPreHistoryToString(activePreHistory));
        } catch (err) {
        }
    };

    async function handleSubmit(files: File[]) {
        if(activeSession == undefined){
            setError("There was an issue processing the pre-history. Please try again.")
            return
        }

        setProcessing(true)

        await uploadFilesAndStartRecap(
            activeSession,
            files,
            uploadToSupabaseSignedURL,
            (await getToken({ template: "supabase" })) ?? ""
        );

        await refreshActiveSession()

        setProcessing(false)
    }

    async function handleDownloadPDF(): Promise<void> {
        setPdfDownloading(true)
        setNotification(true)
        setNotificationTitle("Downloading PDF...")
        
        try {
            let signed_url = await downloadPreHistoryPDF(activeSession?.id ?? "", await getToken({template:"supabase"}) ?? "")
            if(signed_url){
                // Trigger the PDF download
                const link = document.createElement('a');
                link.href = signed_url['signed_url'];
                link.target = '_blank';
                link.setAttribute('download', `${activeSession?.name}_notes.pdf`); // You can specify the file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                let properties =  {
                    date:(new Date()).toUTCString(),
                    session:activeSession?.id ?? "No Session",
                }
                va.track("HistoryPage_DownloadPreHistoryPDF", properties)
            }
            else{
                setNotification(false)
                setNotificationTitle(undefined)
                setError("There was an issue downloading the PDF. Please try again.")
            }    
        } finally {
            setPdfDownloading(false)
        }     
    }

    const interactiveWidgets = (classes: string) => {
        return (<div className={classes}>
            <button
                type="button"
                title="Copy the recap"
                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                onClick={() => copyToClipboard()}
            >
                {!copySuccess && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />}
                {copySuccess && <Spinner size="h-5 w-5" timer={false}/>}
            </button>
            <button
                type="button"
                title="Download as PDF"
                disabled={pdfDownloading}
                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                onClick={() => handleDownloadPDF()}
            >
                {!pdfDownloading && <ArrowDownCircleIcon className="h-5 w-5 text-white hover:text-blue-400"/>}
                {pdfDownloading && <Spinner size="h-5 w-5" timer={false}/>}
            </button>
            <button
                type="button"
                title="Provide positive feedback on records recap"
                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                onClick={() => handleThumbsInput(true, "PreHistory")}
            >
                <HandThumbUpIcon className="h-5 w-5 text-white hover:text-blue-400"/>
            </button>
            <button
                type="button"
                title="Provide negative feedback on records recap"
                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                onClick={() => handleThumbsInput(false, "PreHistory")}
            >
                <HandThumbDownIcon className="h-5 w-5 text-white hover:text-blue-400"/>
            </button>
        </div>)
    } 

    function displayPreHistory(){
        return(<div className=''>
            <div className="flex xl:flex-row flex-col gap-y-4 justify-between gap-x-4 mb-4 xl:items-center items-end">
                <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
                    <div className="flex grow items-center justify-center">
                        <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                            <div className="flex flex-row gap-x-4 items-center">
                                <div>
                                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                </div>
                                <div className="text-sm text-blue-900">
                                    <span className="font-semibold">Records Recap</span> is still in beta. Send feedback to <a className="underline" href="mailto:support@vetrec.io">support@vetrec.io</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-x-2 w-full sm:w-auto">
                    <button
                        type="button"
                        title={activeSession && activeSession.status.general && activeSession.status.general == "Completed" ? "This session is completed" : "Mark this session as completed"}
                        className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52 ${activeSession && activeSession.status.general && activeSession.status.general == "Completed" ? 'bg-green-600 hover:bg-green-500 font-semibold text-white' : 'bg-white'}`}
                        onClick={() => handleMarkComplete()}
                    >
                        <CheckIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                        {activeSession && activeSession.status.general && <span className='hidden sm:block'>{activeSession.status.general == "Completed" ?  "Completed" : "Mark as completed"}</span>}
                    </button>
                    <button
                        type="button"
                        title="Record additional information for this session which will be added to the notes."
                        className="whitespace-nowrap inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52"
                        onClick={() => handleAddToSession()}
                    >
                        <MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        <span className='hidden sm:block'>Add to visit</span>
                    </button>
                    {interactiveWidgets("sm:hidden flex flex-row gap-x-2")}
                </div>
            </div>
            {activeSession && activePreHistory && <div className="flex flex-row gap-x-2 w-full sm:w-auto">
                <SummarizedHistoryView activePreHistory={activePreHistory} preHistoryStreaming={preHistoryStreaming} />
                {interactiveWidgets("hidden sm:flex sm:flex-col gap-y-2 justify-between items-end pl-2 h-full")}
            </div>}
        </div>)
    }

    return (<div className='mb-8 flex flex-col gap-y-4'>
        {preHistoryLoading && !activePreHistory && <Loading text={preHistoryLoading}/>}
        {!processing && preHistoryFailureMessage &&  <div className="px-4 py-5 sm:p-6 flex items-center bg-red-100 rounded-md text-center text-sm text-red-900">
            <ExclamationCircleIcon className="mr-3 h-6 w-6" />
            <p className="ml-3">{preHistoryFailureMessage}</p>
        </div>}
        {!preHistoryLoading && !activePreHistory && <>
            {!processing && !preHistoryProcessing && !preHistoryStreaming && 
                <FileUploader sessionId={activeSession?.id} onSubmitButtonClick={handleSubmit} />}
            {(processing || preHistoryProcessing || preHistoryStreaming) && <div className='mt-8 mb-8'>
                <Loading text="Processing prior records"/>
            </div>}
        </>}
        {activeSession && activePreHistory && displayPreHistory()}
    </div>)
}
