import { useEffect, useRef, useState, type CSSProperties, type FC } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { ContentType, EntryType, SectionType } from './TemplateUtils'
import ContentTypePicker from './contentTypePicker'
import { useTemplate } from './TemplateProvider'
import { ArrowDownCircleIcon, ArrowUpCircleIcon, ChevronUpIcon, XCircleIcon } from '@heroicons/react/24/solid'
import InputTooltip from './InputTooltip'
import { Disclosure } from '@headlessui/react'

interface EntryProps {
    sectionKey:string
    parentEntryKey?:string
    entryKey:string
    name:string|undefined
    description:string|undefined
    defaultValue:string|undefined
    type:ContentType
    hardcodedValue?:string|undefined
    subEntries?:EntryType[]
}

export const AddEntryArea: FC = () => {
  const {handleSubEntryOver} = useTemplate()
  const ref = useRef<HTMLDivElement>(null)

  const [{isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.ENTRY, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.OWNER_DISCUSSION, ItemTypes.RENDERED_SECTION, ItemTypes.SECTION, ItemTypes.SECTION, ItemTypes.ASSESSMENT, ItemTypes.PLAN, ItemTypes.PHYSICAL_EXAM, ItemTypes.SUBJECTIVE, ItemTypes.VITALS, ItemTypes.FEAR_FREE],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item:any, monitor){
      handleSubEntryOver(undefined)
    }
  }))

  drop(ref)

  return (
  <div ref={ref} className='border border-dashed border-gray-300 rounded-md p-4 text-center'>
    Add Entry to section
  </div>
  )
}

export const Entry: FC<EntryProps> = ({sectionKey, entryKey, name, description, defaultValue, type, hardcodedValue, subEntries, parentEntryKey}) => {
  const {deleteEntry, updateEntryDefaultValue, updateEntryDescription, updateEntryHardcodedValue, updateEntryName, updateEntryType, swapEntries, swapSubEntries, handleEntryOver, handleSectionOver, entryHoverIndex, sectionHoverIndex, handleSubSectionOver, handleSubEntryOver, subSectionHover, subEntryHoverIndex, entryDragged} = useTemplate()
  const ref = useRef<HTMLDivElement>(null)
  const typeRef = useRef<ContentType>(type)

  useEffect(() => {
    typeRef.current = type
  }, [type])

  const [{isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.RENDERED_ENTRY, ItemTypes.ENTRY, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.OWNER_DISCUSSION], 
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item:any, monitor){
      if (!ref.current) {
        return
      }
      if (item['entryKey'] === entryKey) {
        return
      }
      if ([ItemTypes.ENTRY, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.OWNER_DISCUSSION].includes(item['name'].toLowerCase().replace(" ", "_"))) {
        // Code to execute if condition is true
        handleSectionOver(sectionKey)
        if(parentEntryKey){
          handleEntryOver(parentEntryKey)
          handleSubEntryOver(entryKey)
        }
        else{
          if(typeRef.current === ContentType.SUBSECTION){
            handleSubSectionOver(true)
          }
          else{
            handleSubSectionOver(false)
          }
          handleEntryOver(entryKey)
        }
      }    
      if(item['entryKey'] && item['name'] == ItemTypes.RENDERED_ENTRY && parentEntryKey === undefined){
        swapEntries(sectionKey, entryKey, item['entryKey'])
      }
      else if(item['entryKey'] && item['name'] == ItemTypes.RENDERED_ENTRY && parentEntryKey !== undefined){
        swapSubEntries(sectionKey, parentEntryKey, entryKey, item['entryKey'])
      }
    }
  }))
  
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.RENDERED_ENTRY,
    item: () => {
      return { entryKey, "name":ItemTypes.RENDERED_ENTRY}
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <>
      {sectionHoverIndex === sectionKey && entryHoverIndex === entryKey && !subSectionHover && <div className='w-full min-h-1 bg-blue-600 rounded-md' />}
      {sectionHoverIndex === sectionKey && entryHoverIndex === parentEntryKey && subEntryHoverIndex == entryKey && subSectionHover && <div className='w-full h-1 bg-blue-600 rounded-md' />}
      <div ref={ref} className={`w-full cursor-move ${isDragging ? "border-2 border-blue-600 rounded-md" : ""}`} data-testid="section">
        <div className={`flex flex-col gap-y-2 border border-gray-200 px-4 py-2 rounded-md ${isDragging ? "opacity-0" : "opacity-1"}`}>
          <div className='flex flex-row items-center justify-between'>
            <div className='flex flex-row gap-x-2'>
              <input className='nodrag border-t-0 border-l-0 border-r-0 focus:rounded-md h-8 text-gray-900' placeholder='Entry Name' value={name} onChange={(event) => updateEntryName(sectionKey, entryKey, event.target.value, parentEntryKey)} draggable={true} onDragStart={event => event.preventDefault()}/>
              <ContentTypePicker contentType={type} setContentType={(type) => updateEntryType(sectionKey, entryKey, type, parentEntryKey)} subsection={parentEntryKey ? false : true}/>
            </div>
            <div className='flex flex-row gap-x-2'>
              <div className="cursor-pointer" onClick={() => deleteEntry(sectionKey, entryKey, parentEntryKey)}>
                <XCircleIcon className='w-5 h-5 text-blue-600 hover:text-blue-500' />
              </div>
            </div>
          </div>
          {[ContentType.LIST, ContentType.NUMBER, ContentType.PARAGRAPH].includes(type) && <div className='flex flex-col gap-y-2'>
            <div className='w-full flex flex-row gap-x-2 items-center justify-start'>
              Instructions:
              <InputTooltip direction="right" content={<div className='flex flex-col'>
                <div className='font-semibold'>Recommendations</div>
                <ul className='list-disc list-inside'>
                  <li>The simpler, the better</li>
                  <li>Be specific. Tell the system what exactly you want.</li>
                  <li>Avoid negative language. (e.g. Do not include). Use positive language. (e.g. Include)</li>
                  <li>Keep conditional statements to a minimum.</li>
                  <li>Add examples for correct behavior. (e.g. Format medications as...) Avoid wrong behavior examples. (e.g. Do not format as)</li>
                  <li>Use this format for examples: (e.g. Example for the entry)</li>
                  <li>Be consistent with names used and format.</li>
                </ul>
              </div>}>
                <textarea className="nodrag rounded-md border-gray-300 w-full text-gray-900 resize-none h-10 focus:h-32 transition-height duration-300 ease-in-out thin-scrollbar"placeholder='(Optional) Provide instructions to VetRec. e.g. Include dosage' value={description} onChange={(event) => updateEntryDescription(sectionKey, entryKey, event.target.value, parentEntryKey)} draggable={true} onDragStart={event => event.preventDefault()}/>
              </InputTooltip>
            </div>
            <div className='flex flex-row gap-x-2 items-center justify-start'>
              Default:
              <InputTooltip direction="right" content={<div>
                <div className='font-semibold'>Default values</div>
                <ul className='list-disc list-inside'>
                  <li>Used when information for the entry is not provided in the session.</li>
                  <li>Format like you expect the information to be presented</li>
                </ul>
              </div>}>
                <textarea className="nodrag rounded-md border-gray-300 h-10 w-full text-gray-900 resize-none h-10 focus:h-32 transition-height duration-300 ease-in-out thin-scrollbar" placeholder='(Optional) Provide default values like normals' value={defaultValue} onChange={(event) => updateEntryDefaultValue(sectionKey, entryKey, event.target.value, parentEntryKey)} draggable={true} onDragStart={event => event.preventDefault()}/>
              </InputTooltip>
            </div>
          </div>}
          {[ContentType.SUBSECTION].includes(type) && <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                <span>Sub-entries</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-blue-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-0 pb-2 pt-0 text-sm text-gray-500 flex flex-col gap-y-2">
                {subEntries && subEntries.map((entry) => (
                  <Entry key={entry.entryKey} name={entry.name} description={entry.description} defaultValue={entry.defaultValue} entryKey={entry.entryKey} type={entry.type} sectionKey={sectionKey} parentEntryKey={entryKey}/>
                ))}
                {sectionHoverIndex === sectionKey && entryHoverIndex == entryKey && subEntryHoverIndex === undefined && entryDragged && <div className='w-full min-h-1 bg-blue-600 rounded-md' />}
                {subEntries?.length != 0 && sectionHoverIndex === sectionKey && entryHoverIndex == entryKey && subSectionHover && <AddEntryArea />}
                {subEntries?.length == 0 && <div className='flex flex-col items-center justify-center h-full mt-4'>
                  <div className='flex flex-col items-center gap-y-2'>
                    <div className='text-gray-500 text-md'>Drag and drop an entry</div>
                      <div className='text-gray-500 text-sm'>
                        Choose an empty entry or one of the pre-built options.
                      </div>
                    </div>
                  </div>
                }
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>}
          {type === ContentType.HARDCODED && <div className='flex flex-col gap-y-2'>
            <div className='w-full flex flex-row gap-x-2 items-center justify-start'>
              Value:
              <InputTooltip direction="right" content={<div className='flex flex-col'>
                <div className=''>This value will be added verbatim into the final set of notes.</div>
              </div>}>
                <textarea className="nodrag rounded-md border-gray-300 w-full text-gray-900 resize-none h-10 focus:h-32 transition-height duration-300 ease-in-out thin-scrollbar" placeholder='Value will be inserted into the final notes verbatim.' value={hardcodedValue} onChange={(event) => updateEntryHardcodedValue(sectionKey, entryKey, event.target.value, parentEntryKey)} draggable={true} onDragStart={event => event.preventDefault()}/>
              </InputTooltip>
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}
