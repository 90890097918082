import { ArrowLeftCircleIcon, ArrowLeftIcon, ArrowRightCircleIcon, ArrowRightIcon, BuildingOfficeIcon, CheckIcon, InformationCircleIcon, MicrophoneIcon, SparklesIcon } from "@heroicons/react/24/solid"
import { ChevronRightIcon, PlusCircleIcon, ArrowLongLeftIcon } from '@heroicons/react/20/solid'
import { useNavigate } from "react-router-dom"
import { Spinner } from "./utils/Spinner"
import TemplatePicker from "./components/templates/TemplatePicker"
import PetOverview from "./PetOverview"
import { convertTimestampToDate } from "./utils/time"
import Error from "./components/Error"
import UserPicker from "./components/UserPicker"
import Remainder from "./components/remainder"
import CreateSessionButton from "./components/CreateSession"
import Feed from "./components/history/Feed"
import Transcript from "./components/history/Transcript"
import PreHistory from "./components/history/PreHistory"
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker"
import { Discharge } from "./components/history/Discharge"
import { Notes } from "./components/history/Notes"
import { useHistory } from "./providers/HistoryProvider"
import { HistoryTabs } from "./components/history/HisitoryTabs"
import { useUser } from "@clerk/clerk-react"
import TemplateModal from "./components/templates/templateModal"
import CommunicationsTemplateModal from "./components/templates/communicationsTemplateModal"
import FeedbackModal from "./components/history/FeedbackModal"
import TranslateModal from "./components/history/translateModal"
import { TemplateType } from "./components/templates/TemplateUtils"
import { useMemo } from "react"

interface HistoryProps {
    
}

export default function History(props:HistoryProps) {

    const {activeSession, handleCreatePatient, handleHistorySearch, searchDate, tab, setSearchDate, membersList, setUserSearch, userSearch, availableSession, listContainerRef, loadingSessions, filteredSessions, sessionFromParam, scrollToItemRef, selectSession, setActiveSession, activeRecordingBackUp, activeTranscript, transcriptProcessing, feedLoading, notesLoading, transcriptLoading, setTemplate, template, error, setError, notification, notificationText, notificationTitle, setNotification, processRecordings, debouncedQuery, recordingCheckLoading, activeRecordingExist, activeSessionUpdated, activeNotes} = useHistory()

    const { user } = useUser()
    const  navigate = useNavigate() 
    const isToday = useMemo(() => new Date(searchDate?.startDate ?? 0).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0), [searchDate])

    function oneLessDay(){
        if(!searchDate || !searchDate.startDate) return
        let date = new Date(searchDate.startDate)
        date.setDate(date.getDate() - 1)
        setSearchDate({startDate: date, endDate: date})
    }

    function oneMoreDay(){
        if(!searchDate || !searchDate.startDate) return
        let date = new Date(searchDate.startDate)
        date.setDate(date.getDate() + 1)
        setSearchDate({startDate: date, endDate: date })
    }

    return(
        <div className="overflow-hidden flex py-0 justify-center sm:-ml-4 lg:-ml-8">
            <div className={`${!activeSession ? 'w-screen' : 'hidden sm:block w-0 p-0'} min-w-72 sm:w-72 sm:border-r sm:border-gray-200 flex flex-col gap-y-1 mt-4 sm:px-3`} >
                <div className={`p-0 text-xl border-b border-gray-400 font-bold ${!activeSession ? 'block sm:hidden' : 'hidden'}`}>
                    <h2>All Visits</h2>
                </div>
                <div className="flex flex-col gap-y-4 mt-2">
                    <div className="flex justify-center w-full">
                        <CreateSessionButton handleCreatePatient={handleCreatePatient}/>
                    </div>
                    <div className="flex flex-row items-center gap-x-2">
                        <div onClick={() => oneLessDay()} className="cursor-pointer">
                            <ArrowLeftCircleIcon className="h-6 w-6 text-blue-600"/>
                        </div>
                        <div className="flex justify-center w-full relative z-[9999] items-center gap-x-2">
                            <label htmlFor="name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[99] rounded-md">
                                Date
                            </label>
                            <Datepicker 
                                inputClassName={`pl-2 h-10 rounded-lg w-full text-sm border-gray-300 border-1 placeholder:text-gray-400 text-center ${debouncedQuery ? "text-gray-400 bg-gray-100" : ""}`}
                                toggleClassName={"hidden"}
                                asSingle={true} 
                                useRange={false} 
                                value={searchDate} 
                                onChange={async (value) => {
                                    setSearchDate(value)
                                }} 
                                popoverDirection="down"
                                maxDate={new Date()}
                                displayFormat={"MM/DD/YYYY"}  
                                placeholder="All Dates"
                                disabled={debouncedQuery ?  true : false}
                            /> 
                        </div>
                        <div onClick={() => {oneMoreDay()}} className={`${isToday ? "pointer-events-none" : "cursor-pointer"}`} >
                            <ArrowRightCircleIcon className={`h-6 w-6 ${isToday ? "text-gray-400" : "text-blue-600"}`}/>   
                        </div>
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker">
                            Patient's Name
                        </label>
                        <input
                            type="search"
                            name="search"
                            id="search"
                            className="block w-full rounded-lg py-1.5 text-gray-900 border-1 border-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6 h-10"
                            placeholder="Search for patient"
                            onChange={(event) => handleHistorySearch(event.target.value)}
                        />
                    </div>
                    {membersList.length > 1 && <UserPicker userList={[{name: "All doctors", identifier: "All doctors"}, ...membersList]} userSearch={userSearch} setUserSearch={setUserSearch} className="pr-6 h-10" text="Doctor / Staff"/>}
                </div>
                {availableSession && membersList.length > 2 && <div className="sm:pr-2 pr-6 pb-2 flex justify-center items-center gap-x-2 text-gray-500 text-sm mt-3">
                    <BuildingOfficeIcon className="h-4 w-4 text-main-lighter" />= Colleague's
                </div>}
                <div className={`thin-scrollbar ${membersList.length + 1 > 2 ? "h-[62vh] sm:h-[62vh] lg:h-[69vh]" : "h-[57vh] sm:h-[72vh]  lg:h-[79vh]"} overflow-y-auto sm:pr-0 pr-6 mt-4`}>
                    <ul ref={listContainerRef} className="divide-y divide-gray-100 w-full sm:pr-4">
                        {loadingSessions && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-4'>
                            Loading visits 🥼...
                            <Spinner size="w-10 h-10" timer={false}/>
                        </div>}
                        {filteredSessions && !loadingSessions && filteredSessions.length > 0 && <div>
                            {filteredSessions.map((session) => (
                            <li ref={session.id === sessionFromParam ? scrollToItemRef : null} key={session.id} className={`flex items-center justify-between gap-x-6 py-5 ${activeSession && session.id === activeSession.id ? 'bg-selected rounded-md' : 'hover:bg-gray-300'} cursor-pointer hover:rounded-md border-b border-gray-200`} onClick={() => selectSession(session)}>
                                <div className="flex min-w-0 gap-x-4 pl-4 justify-center items-center">
                                    <div className={`sm:h-12 sm:w-12 h-7 w-7 flex rounded-full ${ session.status.general && session.status.general == "Completed" ? "bg-green-600" : "bg-main"} items-center justify-center shrink-0`}>
                                        <p className="text-center text-main-text font-semibold">
                                            {session.status.general && session.status.general == "Completed" ? 
                                            <CheckIcon className="h-5 w-5"/> : 
                                            ((session.display_owner && session.display_owner == user?.emailAddresses[0].emailAddress) ||  (!session.display_owner && session.owner == user?.emailAddresses[0].emailAddress)) ? 
                                            <span className="text-center text-main-text">{session.name === "" ?  "P" : session.name[0].toUpperCase()}</span> : <BuildingOfficeIcon className="h-5 w-5"/>}
                                        </p>
                                    </div>
                                    <div className="min-w-0 flex-auto self-center">
                                        <p className={`text-sm ${activeSession && session.id === activeSession.id ? 'font-bold' : 'font-semibold'} leading-6 text-main-text-darker overflow-hidden truncate`}>{session.name === "" ? "Pet" : session.name}</p>
                                        <p className="text-xs">{convertTimestampToDate(session.created_timestamp)}</p>
                                    </div>
                                </div>
                                <ChevronRightIcon className="h-5"/>
                            </li>
                            ))}
                        </div>}
                        {!loadingSessions && filteredSessions && filteredSessions.length === 0 && <li>
                            <div className="flex flex-col gap-y-8 px-4 text-center pt-10 items-center justify-center">
                                <div>
                                    No visits on this day. Change the filters or create a new visit.
                                </div>
                                <button
                                type="button"
                                className="relative -ml-px inline-flex items-center justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-main-text ring-1 ring-inset ring-gray-400 bg-main hover:bg-main-lighter h-12 w-40"
                                onClick={() => navigate('/scribe')}
                                >
                                    <PlusCircleIcon className="h-6 w-6 text-main-text"/>
                                    <span className="block font-semibold">Start a visit</span>
                                </button>
                            </div>
                        </li>}
                    </ul>
                </div>
            </div>
            <div className={`${!activeSession ? 'w-0 hidden' : 'w-full'} sm:w-full sm:block sm:pl-6 sm:pt-6 overflow-x-hidden`}>
                <div className="pt-8 pb-4 block sm:hidden sm:py-0">
                    <button
                    type="button"
                    className={`relative -ml-px inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-main-text ring-1 ring-inset ring-gray-400 bg-main hover:bg-main-lighter sm:hidden`}
                    onClick={() => setActiveSession(undefined)}
                    >
                        <ArrowLongLeftIcon className="-ml-0.5 h-5 w-5 text-main-text" aria-hidden="true" />
                        Change Session
                    </button>
                </div>            
                {!activeSession && <div className="w-full">
                    <div className="md:flex md:items-center md:justify-between w-full border-b border-gray-400 pb-4 gap-x-8">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                                Select a visit
                            </h2>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">
                                Pick a visit from the history and see the information associated.
                            </p>
                        </div>
                        {/* <div className="inline-flex justify-center items-center gap-x-2 rounded-md bg-accent-button px-3.5 py-2.5 text-sm font-semibold text-accent-button-text shadow-md hover:bg-accent-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 min-w-36 cursor-pointer"
                        onClick={handleAddPatient}>
                            Patient History
                            <PlusCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        </div> */}
                    </div>
                </div>}
                {activeSession && <div className="w-full mt-4 sm:mt-0">
                    <HistoryTabs />
                    <div className="flex flex-col mt-4 pt-4 h-[75vh] sm:h-[82vh] lg:h-[88vh] overflow-y-auto thin-scrollbar justify-between -mr-4 pr-4">
                        <div className="flex flex-col gap-y-4 w-full">
                            {(!activeTranscript) && !transcriptProcessing && (!transcriptLoading && !notesLoading && !recordingCheckLoading) && tab !== "prehistory" && <div className="bg-blue-50 sm:rounded-lg">
                                    {(activeRecordingExist || activeRecordingBackUp) && <div className="px-4 py-5 sm:p-6 sm:flex sm:flex-row sm:justify-between sm:items-center">
                                        <div>
                                            <h3 className="text-base font-semibold leading-6 text-gray-900">{activeSessionUpdated ? "Done updating?" : "Done recording?"}</h3>
                                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                <p>{activeSessionUpdated ? "Process updates to this visit" : "Process the recordings for this visit"}</p>
                                            </div>
                                        </div>
                                        <div className="sm:mt-0 mt-5 flex flex-col sm:flex-row sm:items-center gap-x-4 gap-y-4 sm:justify-center">
                                            <TemplatePicker template={template} templateId_force={activeSession.template_id} setTemplate={setTemplate} className="h-10" templateBackground='bg-transparent' type={TemplateType.MEDICAL}/>
                                            <button
                                                type="button"
                                                className="inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center"
                                                onClick={() => processRecordings(activeSessionUpdated)}
                                            >
                                                {activeSessionUpdated ? "Update" : "Process"}
                                                <SparklesIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>}
                                    {!activeRecordingExist && !activeRecordingBackUp && tab !== "prehistory" && <div className="px-4 py-5 sm:p-6 flex flex-col gap-y-6 items-center bg-blue-100 rounded-md text-center">
                                        <div className="flex items-center justify-start">
                                            <div className="ml-3 text-sm text-blue-900">
                                                <p>Looks like there are <span className="font-semibold">no recordings</span> for this visit.<br/>Create a recording by pressing the <span className="font-semibold">Add to visit</span> button.</p>
                                            </div>
                                        </div>
                                        {tab !== "overview" && <button
                                            type="button"
                                            title="Record additional information for this session which will be added to the notes."
                                            className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 overflow-hidden sm:w-48 w-full justify-center h-10 sm:h-12"
                                            onClick={() => navigate("/scribe?session=" + activeSession?.id)}
                                        >
                                            <MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                            Add to visit
                                        </button>}
                                    </div>}
                            </div>}
                            {tab == "overview" && <PetOverview />} 
                            {tab == "prehistory" && <PreHistory />}
                            {tab == "feed" && <Feed />}
                            {tab == "transcript" && <Transcript />}
                            {tab == "notes" && <Notes />}
                            {tab == "discharge" && <Discharge />}
                        </div>
                        <div className='text-xs text-center sm:text-start mb-4 mt-4'>
                            <span className='font-semibold'>Visit ID:</span> {activeSession.id}
                        </div>
                    </div>
                </div>}
            </div>
            <Error error={error} setError={setError}/>
            <Remainder show={notification} setShow={setNotification} title={notificationTitle ?? ""} text={notificationText ?? ""}/>
            <TemplateModal />
            <CommunicationsTemplateModal />
            <TranslateModal />
            <FeedbackModal />
        </div>
    )
}