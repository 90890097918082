import { FC } from "react";
import { EzyVetCategories, EzyVetVitalsCategories, SectionType } from "../templates/TemplateUtils";
import EnumPicker from "../EnumPicker";
import { useTemplate } from "../templates/TemplateProvider";
import InputTooltip from "../templates/InputTooltip";

interface EzyVetConfigProps {
    items: SectionType[];
    open: (val: boolean) => void;
}

export const EzyVetConfig: FC<EzyVetConfigProps> = ({ items, open }) => {
    const { mapping, updateEzyVetSectionCategory, updateEzyVetVitalCategory, updateEzyvetSectionName, saveMapping } = useTemplate();

    async function handleTemplateConfig() {
        await saveMapping();
        open(false);
    }

    return (
        <div className="flex flex-col gap-y-4 mt-4 justify-between h-[60vh] thin-scrollbar overflow-y-auto pr-2">
            <div className="flex flex-col gap-y-4">
                <div>
                    <div className="w-full flex flex-row items-center justify-between border-b border-gray-300 pb-1">
                        <div>Section Name</div>
                        <div className="flex flex-row gap-x-4">
                            <div className="w-40">Category</div>
                            <div className="self-start w-60">Name in EzyVet</div>
                        </div>
                    </div>
                </div>
                {mapping && items.map((item) => (<>
                    <div key={item.name} className="rounded-md w-full flex flex-row items-center justify-between">
                        <div>{item.name}</div>
                        <div className="flex flex-row gap-x-4 relative">
                            <EnumPicker 
                                enum={EzyVetCategories} 
                                selectedOption={mapping[item.name?.toLowerCase() ?? ""].category ?? EzyVetCategories.NO_MAPPING} 
                                setSelectedOption={(value) => {
                                    updateEzyVetSectionCategory(item.name?.toLowerCase() ?? "", EzyVetCategories[value.toUpperCase().replace(" ", "_") as keyof typeof EzyVetCategories])
                                }} 
                            />
                            <InputTooltip 
                                direction="right" 
                                content={<div className='flex flex-col'>
                                    <div className='font-semibold'>For each section:</div>
                                    <ul className='list-disc list-inside'>
                                        <li>Navigate to your chart in EzyVet</li>
                                        <li>Copy the name of the equivalent section in EzyVet. (e.g. Subjective or Physical Exam/Objective)</li>
                                        <li>Make sure you copy exactly the name of the section.</li>
                                        <li>Add the name to the Name in EzyVet column</li>
                                        <li>Categorize the type of entry.</li>
                                    </ul>
                                </div>}
                            >
                                <input 
                                    value={mapping[item.name?.toLowerCase() ?? ""].ezyvet_section_name} 
                                    className="rounded-md border-gray-300 h-8 w-60" 
                                    onChange={(event) => updateEzyvetSectionName(item.name?.toLowerCase() ?? "", event.target.value)} 
                                />
                            </InputTooltip>
                        </div>
                    </div>
                    {mapping[item.name?.toLowerCase() ?? ""].category == EzyVetCategories.VITALS && <div className="flex flex-col gap-y-4 mb-4">
                        <div className="ml-8 mr-8">
                            <div className="w-full flex flex-row items-center border-b border-gray-300 pb-1 justify-between gap-x-8">
                                <div className="shrink-0">Entry Name</div>
                                <div className="flex flex-row w-96">
                                    <div className="">Map to</div>
                                </div>
                            </div>
                        </div>
                        {item.entries.map((entry) => (
                            <div className="ml-8 mr-8">
                                <div className="w-full flex flex-row items-center justify-between gap-x-8">
                                    <div className="shrink-0">{entry.name}</div>
                                    <div className="flex flex-row w-96">
                                        <EnumPicker 
                                            width="w-60"
                                            enum={EzyVetVitalsCategories} 
                                            selectedOption={mapping[item.name?.toLowerCase() ?? ""].inputs[entry.name?.toLowerCase() ?? ""]?.inputName ?? EzyVetVitalsCategories.NO_MAPPING} 
                                            setSelectedOption={(value) => {
                                                updateEzyVetVitalCategory(item.name?.toLowerCase() ?? "", entry.name?.toLowerCase() ?? "", EzyVetVitalsCategories[value.toUpperCase().replace(" ", "") as keyof typeof EzyVetVitalsCategories])
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>}
                </>))}
            </div>
            <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => handleTemplateConfig()}
                >
                    Save Configuration
                </button>
            </div>
        </div>
    );
};
