import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react'
import { SupabaseProvider } from './supabase/SupabaseContext';
import { IntercomProvider } from 'react-use-intercom';
const INTERCOM_APP_ID = 'okpouicg';


const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_KEY;
// const PUBLISHABLE_KEY = "pk_test_c2tpbGxlZC1hcGhpZC02Ny5jbGVyay5hY2NvdW50cy5kZXYk"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY ?? ""} appearance={{layout: {
        termsPageUrl:"https://www.vetrec.io/terms-of-service", privacyPageUrl:"https://www.vetrec.io/privacy"
      }}}>
        <SupabaseProvider>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <App />
          </IntercomProvider >
        </SupabaseProvider>
      </ClerkProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
