import { useState, type CSSProperties, type FC, useRef, useEffect } from 'react'

interface MainButtonProps{
    action:any
    icon:any
    text:string
    size:string
    title:string
}

export const MainButton: FC<MainButtonProps> = ({text, icon, action, size, title}) => {
    return(
    <button
        title={title}
        type="button"
        className={`inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 overflow-hidden ${size} justify-center`}
        onClick={() => action()}
    >
        {text}
        {icon}
    </button>
    )
}