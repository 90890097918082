import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"

interface WarningBannerProps {
    warningText: string
}

export const WarningBanner: React.FC<WarningBannerProps> = (props) => {
    return <div className="rounded-lg bg-yellow-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
        <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
            <div className="flex flex-row gap-x-4 items-center">
                <div>
                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="text-sm text-yellow-900 font-semibold">{props.warningText}</div>
            </div>
        </div>
    </div>
}