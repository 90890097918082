import { PreHistoryV2Definition } from "../../utils/PreHistoryDefinition";
import { Spinner } from "../../utils/Spinner";

interface EntryViewProps {
  title: string;
  value: string | string[] | undefined;
}

const EntryView: React.FC<EntryViewProps> = ({ title, value }) => {
  return (
    <div className="flex flex-col gap-y-2 relative">
      <div className="text-base font-semibold leading-6 text-black">
        {title}
      </div>
      {!Array.isArray(value) && (
        <div className="w-full border border-gray-300 rounded-md p-2 text-sm text-gray-700">
          {value}
        </div>
      )}
      {Array.isArray(value) && (
        <div className="w-full border border-gray-300 rounded-md p-2 text-sm text-gray-700">
          {value && value.length > 0 && (
            <ul className="list-disc pl-5">
              {value?.map((exam, index) => (
                <li key={index}>{exam}</li>
              ))}
            </ul>
          )}
          {value.length === 0 && "Not provided"}
        </div>
      )}
    </div>
  );
};

interface SummarizedHistoryViewProps {
  activePreHistory: PreHistoryV2Definition;
  preHistoryStreaming: boolean;
}

export const SummarizedHistoryView: React.FC<SummarizedHistoryViewProps> = ({
  activePreHistory,
  preHistoryStreaming,
}) => {
  return (
    <div className="flex flex-col gap-y-4 relative">
      {preHistoryStreaming && (
        <div className="absolute top-0 sm:top-0 right-0 sm:right-0 flex flex-col gap-y-2">
          <div className="flex flex-row relative items-center gap-x-2 rounded-md bg-white px-2.5 py-2.5 text-sm font-semibold border border-gray-300 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 h-10 overflow-hidden justify-center z-[2] shadow">
            <Spinner size="h-5 w-5" timer={false} />
            Writing summary...
          </div>
        </div>
      )}
      <EntryView title="Referrers" value={activePreHistory.referrers} />
      <EntryView title="Summary" value={activePreHistory.summary} />
      <EntryView
        title="Physical Exams"
        value={activePreHistory.physical_exams}
      />
      <EntryView
        title="Prior Issues"
        value={activePreHistory.prior_issues}
      />
      <EntryView
        title="Prior Medications"
        value={activePreHistory.prior_medications}
      />
      <EntryView
        title="Prior Vaccines"
        value={activePreHistory.prior_vaccines}
      />
      <EntryView
        title="Prior Test Reports"
        value={activePreHistory.prior_test_reports}
      />
      <EntryView
        title="Prior treatments"
        value={activePreHistory.prior_treatments}
      />
      <EntryView
        title="Additional information"
        value={activePreHistory.additional_information}
      />
    </div>
  );
};
