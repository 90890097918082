import { Listbox, Transition } from "@headlessui/react";
import { RecordingStatus, useRecording } from "../../providers/RecordingProvider";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import va from '@vercel/analytics';
import { IMicrophone } from "../../utils/RecordingUtils";

interface MicrophonePickerProps{
}

export default function MicrophonePicker(props:MicrophonePickerProps) {
    const {recordingState, microphones, selectedMicrophone, updateSelectedMicrophone} = useRecording()

    const handleSelectedMic = (newMic:IMicrophone) => {
        updateSelectedMicrophone(newMic);
        let properties =  {
            date:(new Date()).toUTCString(),
            sessionId: "home",
            micLabel:newMic?.label ?? "undefined"
        }
        va.track("Selected_Microphone", properties)
    };
    
    return(<div className="shrink-0 sm:w-60 w-60 h-12 relative">
        <label
            htmlFor="name"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[1]"
        >
            Select microphone
        </label>
        <Listbox value={selectedMicrophone} onChange={handleSelectedMic} disabled={recordingState === RecordingStatus.RECORDING}>
            <div className="relative z-[0]">
                <Listbox.Button className={`relative w-full ${recordingState === RecordingStatus.RECORDING ? "":"cursor-pointer"} rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm h-12`}>
                    <span className="block truncate">{selectedMicrophone.label}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute mt-1 max-h-36 w-full overflow-auto thin-scrollbar rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm cursor-pointer z-[1]">
                    {microphones.map((microphone) => (
                        <Listbox.Option
                        key={microphone.deviceId}
                        className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                            active ? 'bg-accent  text-main-text-darker' : 'text-main-text-darker'
                            }`
                        }
                        value={microphone}
                        >
                        {({ selected }) => (
                            <>
                            <span
                                className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                                }`}
                            >
                                {microphone.label}
                            </span>
                            {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            ) : null}
                            </>
                        )}
                        </Listbox.Option>
                    ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    </div>)
}