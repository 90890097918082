import { colorConsentMapping, iconConsentMapping } from './utils/Categories';
import { Confirmation } from './utils/ConsentsUtils';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface PetFeedProps{
  activeConsents:Confirmation[]|undefined
}

function getIcon(category: string) {
    if (category in iconConsentMapping) {
      return iconConsentMapping[category as keyof typeof iconConsentMapping];
    }
    // Return a default icon or null if the category is not found
    return <div>Default Icon</div>; // Replace this with your actual default icon
}

function getColor(category: string) {
    if (category in colorConsentMapping) {
      return colorConsentMapping[category as keyof typeof colorConsentMapping];
    }
    // Return a default icon or null if the category is not found
    return "bg-gray-300"; // Replace this with your actual default icon
}

export default function PetFeed(props:PetFeedProps) {
  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {props.activeConsents && props.activeConsents.map((consent, consentIdx) => (
          <li key={consentIdx}>
            <div className="relative pb-8">
              {props.activeConsents && consentIdx !== props.activeConsents.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3 items-center justify-center">
                <div>
                  <span
                    className={classNames(
                        getColor(consent.category),
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                    )}
                  >
                    {getIcon(consent.category)}
                  </span>
                </div>
                <div className="flex flex-col gap-y-2 gap-x-2 min-w-0 flex-1 justify-start pt-1.5">
                  {consent.sub_category && <div className="text-xs text-gray-400">
                      {consent.sub_category}
                  </div>}
                  <div>
                    <p className="text-sm text-gray-500 font-semibold">
                      {consent.question}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">
                      {consent.answer}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
