export const isMobileDevice = () => {
    const ua = navigator.userAgent;
    const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isMobileSize = window.innerWidth <= 800 && window.innerHeight <= 600;
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua) ||
      (isMobileSize && isTouchScreen)
    );
};

export enum BrowserAndPlatform {
  DesktopChrome = "Desktop Chrome",
  DesktopSafari = "Desktop Safari",
  DesktopEdge = "Desktop Edge",
  MobileChrome = "Mobile Chrome",
  MobileSafari = "Mobile Safari",
  Unknown = "Unknown",
}

export const WhatBrowser = (): BrowserAndPlatform => {
  const ua = navigator.userAgent;
  const isChrome = /Chrome/.test(ua) && !/Edg/.test(ua) && /Google Inc/.test(navigator.vendor);
  const isSafari = /Safari/.test(ua) && /Apple Computer/.test(navigator.vendor);
  const isEdge = /Edg/.test(ua);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);

  if (isChrome && !isMobile) return BrowserAndPlatform.DesktopChrome;
  if (isSafari && !isMobile) return BrowserAndPlatform.DesktopSafari;
  if (isEdge && !isMobile) return BrowserAndPlatform.DesktopEdge;
  if (isChrome && isMobile) return BrowserAndPlatform.MobileChrome;
  if (isSafari && isMobile) return BrowserAndPlatform.MobileSafari;

  return BrowserAndPlatform.Unknown;
};
