import { processPDFSummary } from "../ServerActions";
import { GetPreHistoryUploadURL } from "../supabase/supabaseProxy";
import { PreHistoryV2Definition } from "./PreHistoryDefinition";
import { SessionObject } from "./SessionUtils";

function listToBulletPoints(listObj: string[]): string {
  return listObj.map(o => `• ${o}`).join("\n")
}

export function convertPreHistoryToString(preHistory: PreHistoryV2Definition): string {
    const lines: string[] = [];
    if (preHistory.summary) {
        lines.push(`Summary: ${preHistory.summary}`);
    }
    if (preHistory.referrers) {
        lines.push(`Referrer:\n${listToBulletPoints(preHistory.referrers)}`); // as bullet points
    }
    if (preHistory.physical_exams) {
        lines.push(`Last Physical Exam:\n${listToBulletPoints(preHistory.referrers)}`); // as bullet points
    }
    if (preHistory.prior_issues.length > 0) {
        lines.push(`Existing Issues:\n${listToBulletPoints(preHistory.prior_issues)}`); // as bullet points
    }
    if (preHistory.prior_medications.length > 0) {
        lines.push(`Existing Medications:\n${listToBulletPoints(preHistory.prior_medications)}`); // as bullet points
    }
    if (preHistory.prior_vaccines.length > 0) {
        lines.push(`Existing Vaccines:\n${listToBulletPoints(preHistory.prior_vaccines)}`); // as bullet points
    }
    if (preHistory.prior_test_reports.length > 0) {
        lines.push(`Previous Reports:\n${listToBulletPoints(preHistory.prior_test_reports)}`); // as bullet points
    }
    if (preHistory.prior_treatments.length > 0) {
        lines.push(`Previous Treatments:\n${listToBulletPoints(preHistory.prior_treatments)}`); // as bullet points
    }
    if (preHistory.additional_information) {
        lines.push(`Additional Information: ${preHistory.additional_information}`);
    }
    return lines.join("\n\n");
}

export async function uploadFilesAndStartRecap(
    sessionForRecap: SessionObject,
    files: File[],
    uploadToSupabaseSignedURL: (file: any, fileName: string, bucket: string, token: string) => Promise<void>,
    supabaseToken: string
  ) {
    const new_session = sessionForRecap.id;
    for (let i = 0; i < files.length; i++) {
      let extension = files[i].name.split(".").pop();
      const filename =
        sessionForRecap.owner +
        "/" +
        new_session +
        "/" +
        new_session +
        "_" +
        i +
        "_prehistory." +
        extension;
      let signedURL = await GetPreHistoryUploadURL(
        new_session,
        extension ?? "",
        i.toString(),
        supabaseToken
      );

      await uploadToSupabaseSignedURL(
        files[i],
        filename,
        "pre_history",
        signedURL.signed_url.token
      );
    }

    await processPDFSummary(new_session, supabaseToken);
  }