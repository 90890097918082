export function convertTimestampToDate(created_timestamp:number) {
    // Convert timestamp from seconds to milliseconds
    const date = new Date(created_timestamp * 1000);

    // Format the date to a human-readable format
    const humanReadableDate = date.toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    return humanReadableDate;
}