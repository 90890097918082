import React, { useEffect } from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    ClockIcon,
    PencilSquareIcon,
    NewspaperIcon,
    Cog6ToothIcon, 
    XMarkIcon,
    Bars3Icon,
    RocketLaunchIcon,
    BoltIcon,
} from '@heroicons/react/24/outline'
import config from './config.json'
import Scribe from './Scribe';
import Settings from './Settings';
import History from './History';
import Templates from './Templates';
import Home from './Home';
import { useAuth, useUser, useOrganizationList, useOrganization, OrganizationSwitcher } from "@clerk/clerk-react"
import { DevicePhoneMobileIcon, LifebuoyIcon, LinkIcon, StarIcon } from '@heroicons/react/24/solid';
import { fetchUsagePerUser } from './ServerActions';
import Integrations from './Integrations';
import TemplateBuilder from './TemplateBuilder';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import PayWall from './components/Paywall';
import logo from './assets/Logo.svg'
import { RecordingProvider } from './providers/RecordingProvider';
import { HistoryProvider } from './providers/HistoryProvider';
import RecordsRecapPage from './RecordsRecapPage';
import { RecordsRecapProvider } from './providers/RecordsRecapProvider';

const tus = require('tus-js-client')

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

// Define the types for your props
interface DashboardProps {
    capability:string
}

export default function Dashboard(props:DashboardProps) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
  const [trialExpired, setTrialExpired] = useState<boolean>(false)
  const [suspend, setSuspend] = useState<boolean>(false)
  const [errorTimer, setErrorTimer] = useState<number>(0);
  const capability = props.capability
  const { getToken, signOut, orgId } = useAuth();
  const { user } = useUser()
  const {organization} = useOrganization()
  const { setActive } = useOrganizationList()

  const navigation = [
    { name: 'Scribe', href: '/scribe', icon: PencilSquareIcon, current: capability == "Scribe", openInNewTab: false },
    { name: "Records Recap", href: "/prehistory", icon: BoltIcon, current: capability == "PreHistory", openInNewTab: false },
    { name: 'Active Visits', href: '/active', icon: RocketLaunchIcon, current: capability == "Active", openInNewTab: false },
    { name: 'All Visits', href: '/history', icon: ClockIcon, current:  capability == "History", openInNewTab: false },
    // { name: 'Insights', href: '#', icon: LightBulbIcon, current:  capability == "" },
  ]
  
  const configurations = [
    { name: 'Integrations', href: '/integrations', icon: LinkIcon, current: capability == "Integrations", openInNewTab: false },
    { name: 'Templates', href: '/templates', icon: NewspaperIcon, current: capability == "Templates", openInNewTab: false },
    { name: 'Help Center', href: 'https://help.vetrec.io/', icon: LifebuoyIcon, current: capability == "Help Center", openInNewTab: true },
    { name: 'Settings', href: '/settings', icon: Cog6ToothIcon, current: capability == "Settings", openInNewTab: false },
  ]

  useEffect(() => {
    if(!orgId){
      if(setActive && user?.organizationMemberships && user?.organizationMemberships.length > 0 && capability != "Settings")
        {
            setActive(user?.organizationMemberships[0])
        }
    }
  }, [])

  useEffect(() => {
    const getTierAndUsage = async ():Promise<void> => {
      if ((user && user.publicMetadata.hasOwnProperty("tier")) || organization?.publicMetadata.hasOwnProperty("tier")){
        //has tier
      }
      else{
        // Don't have tier which means they are free users, then check sessions
        let num_sessions = (await fetchUsagePerUser(await getToken({template:"supabase"}) ?? "")).num_sessions
        if(num_sessions >= 15 && num_sessions <= 20){
          //They have no more sessions left.
          setTrialExpired(true)
        }
        else if(num_sessions >= 20){
          setTrialExpired(true)
          setSuspend(true)
        }
        else{
          //They have session left
        }
      }
    }
    getTierAndUsage()
  }, [user])


  return (
    <>
      <div className='overflow-hidden'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden z-[9999]" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-main-text" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className={`flex grow flex-col gap-y-5 overflow-y-hidden bg-main px-6 pt-2 pb-2`}>
                    <div className="text-2xl text-main-text font-bold h-10 flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Company logo"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? `bg-main-darker text-main-text`
                                      : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                                    'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? `text-main-text` : `text-accent-text group-hover:text-main-text`,
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                  {item.name == "Insights" && <div className='text-accent-button-text rounded-lg px-2 shadow-md border border-accent'>
                                    Coming Soon
                                  </div>}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className={`text-xs font-semibold leading-6 text-accent-text`}>Configurations</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {configurations.map((config_item) => (
                              <li key={config_item.name}>
                                <a
                                  href={config_item.href}
                                  target={config_item.openInNewTab ? '_blank' : '' }
                                  className={classNames(
                                    config_item.current
                                      ? `bg-main-darker text-main-text`
                                      : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                                    'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                <config_item.icon
                                    className={classNames(
                                      config_item.current ? 'text-main-text' : `text-accent-text group-hover:text-main-text`,
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                />
                                <span className="truncate">{config_item.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        {<a className='py-2 rounded-md items-center flex flex-row text-white font-semibold gap-x-3 bg-[#0066ff] text-center ring ring-[#56ffd6]  text-sm hover:ring-4 transition-all duration-300 hover:shadow-lg hover:shadow-[#56ffd6]/50' href='https://help.vetrec.io/en/collections/10367770-downloads' target='_blank'>
                          <DevicePhoneMobileIcon className='h-6 w-6'/>
                          Download Mobile Apps
                        </a>}
                        <li className="mt-auto w-full">
                          <div className="relative w-full">
                            <div className="flex flex-col gap-y-4 items-center gap-x-4 py-3 text-sm font-semibold leading-6 w-full">
                              {organization &&<div className='rounded-md flex items-center pt-2 w-full justify-center overflow-x-hidden'>
                                  <OrganizationSwitcher hidePersonal={true} appearance={{
                                    elements: {
                                      organizationSwitcherTrigger:{
                                        maxWidth: "200px",
                                        color: "white",
                                        "&:hover":{
                                          backgroundColor: "rgba(255,255,255,0.3)"
                                        }
                                      },
                                      organizationSwitcherTriggerIcon:{
                                        color: "white",
                                        opacity: 1,
                                      },
                                      organizationPreviewAvatarBox :{
                                        backgroundColor: "white",
                                      },
                                      zIndex: 9999,
                                    }
                                  }}/>
                              </div>}
                              <button onClick={() => signOut()} className='flex justify-end bg-accent-button text-accent-button-text font-normal border-accent-button border rounded-md text-md p-1 hover:bg-accent-button-hover w-full items-center justify-center h-12 font-semibold'>
                                Sign out
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className={`flex grow flex-col gap-y-5 overflow-y-hidden bg-main px-6`}>
            <div className="flex h-16 shrink-0 items-center pt-8">
              <img
                className="h-8 w-auto"
                src={logo}
                alt="Company logo"
              />
            </div>
            <nav className="flex flex-1 flex-col mt-4">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? `bg-main-darker text-main-text`
                              : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                            'group flex gap-x-3 items-center justify-start rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-main-text' : `text-accent-text group-hover:text-main-text`,
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className={`text-xs font-semibold leading-6 text-accent-text`}>Configurations</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {configurations.map((config_item) => (
                      <li key={config_item.name}>
                        <a
                          href={config_item.href}
                          target={config_item.openInNewTab ? '_blank' : '' }
                          className={classNames(
                            config_item.current
                              ? `bg-main-darker text-main-text`
                              : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                        <config_item.icon
                            className={classNames(
                              config_item.current ? 'text-main-text' : `text-accent-text group-hover:text-main-text`,
                                'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                        />
                        <span className="truncate">{config_item.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                {<a className='py-2 rounded-md items-center flex flex-row text-white font-semibold gap-x-3 bg-[#0066ff] text-center ring ring-[#56ffd6]  text-sm hover:ring-4 transition-all duration-300 hover:shadow-lg hover:shadow-[#56ffd6]/50' href='https://help.vetrec.io/en/collections/10367770-downloads' target='_blank'>
                  <DevicePhoneMobileIcon className='h-6 w-6'/>
                  Download Mobile Apps
                </a>}
                <li className="mt-auto w-full">
                  <div className="relative w-full">
                    <div className="flex flex-col items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-main-text w-full gap-y-4 justify-center">
                      <div className='flex flex-row items-center justify-center w-full gap-x-4'>
                        <img
                          className={`h-8 w-8 rounded-full bg-main-darker`}
                          src={user?.imageUrl}
                          alt=""
                        />
                        <span className="hidden lg:flex lg:items-center text-main-text font-semibold">
                          <span aria-hidden="true">{user?.fullName}</span>
                        </span>
                      </div>
                      {organization && user?.organizationMemberships && user?.organizationMemberships.length > 1 && <div className='rounded-md flex items-center pt-2 w-full justify-center overflow-x-hidden'>
                          <OrganizationSwitcher hidePersonal={true} appearance={{
                            elements: {
                              organizationSwitcherTrigger:{
                                maxWidth: "200px",
                                color: "white",
                                "&:hover":{
                                  backgroundColor: "rgba(255,255,255,0.3)"
                                }
                              },
                              organizationSwitcherTriggerIcon:{
                                color: "white",
                                opacity: 1,
                              },
                              organizationPreviewAvatarBox :{
                                backgroundColor: "white",
                              },
                              zIndex: 9999,
                            }
                          }}/>
                      </div>}
                      <button onClick={() => signOut()} className='shadow-md flex justify-center items-center text-accent-text font-normal bg-accent-button rounded-md text-sm p-1 hover:bg-accent-button-hover font-semibold w-full h-12'>
                        Sign out
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className={`sticky top-0 z-40 flex items-center gap-x-6 bg-main px-4 py-4 shadow-sm sm:px-6 lg:hidden`}>
          <button type="button" className={`-m-2.5 p-2.5 text-accent-text lg:hidden`} onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-main-text">{config.BRANDED ? config.NAME : "VetRec"}</div>
          <div className='flex flex-row items-center gap-x-4'>
            <span className="lg:flex lg:items-center text-main-text text-sm">
              <span aria-hidden="true"><span className='font-semibold'>{user?.firstName}</span></span>
            </span>
            <img
              className={`h-8 w-8 rounded-full bg-main-darker`}
              src={user?.imageUrl}
              alt=""
            />
          </div>
        </div>
        <main className="lg:pl-72 overflow-hidden">
          <div className="px-4 sm:px-4 lg:px-8 overflow-hidden">{
            <IntercomProvider appId='okpouicg' autoBoot>
              <PayWall trialExpired={trialExpired} setTrialExpired={setTrialExpired} suspend={suspend}/>
              {capability == "Active" && <RecordingProvider>
                <Home />
              </RecordingProvider>}
              {capability == "Scribe" && <RecordingProvider>
                <Scribe />
              </RecordingProvider>}
              {capability == "History" && <HistoryProvider>
                <History />
              </HistoryProvider>}
              {capability == "PreHistory" && <RecordsRecapProvider>
                <RecordsRecapPage />
              </RecordsRecapProvider>}
              {capability == "Templates" && <Templates/>}
              {capability == "TemplateBuilder" && <TemplateBuilder/>}
              {capability == "Integrations" && <Integrations/>}
              {capability == "Settings" && <Settings />}
            </IntercomProvider>
          }</div>
        </main>
      </div>
    </>
  )
}
