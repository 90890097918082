import { CheckIcon, HandThumbUpIcon, UserIcon } from '@heroicons/react/20/solid'
import { BeakerIcon, CakeIcon, HandThumbDownIcon, MicrophoneIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import HospitalIcon from '../icons/Hosptital'
import Syringe from '../icons/Syringe'
import MedicalBadge from '../icons/MedicalBadge'

type ConsentType = 'SURGERY' | 'MEDICATION' | 'VACCINATION' | 'FOOD' | 'GENERAL_HEALTH' | 'RECORDING_CONSENT' | 'APPROVE' | 'DECLINE' | 'OTHER';

export const iconConsentMapping = {
    SURGERY: <MedicalBadge className="h-5 w-5 text-white" aria-hidden="true"/>,
    MEDICATION: <BeakerIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VACCINATION: <Syringe className="h-5 w-5 text-white" aria-hidden="true"/>,
    FOOD: <CakeIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    GENERAL_HEALTH: <HospitalIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    RECORDING_CONSENT: <MicrophoneIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    APPROVE: <HandThumbUpIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    DECLINE: <HandThumbDownIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    OTHER: <QuestionMarkCircleIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
} as const

export const colorConsentMapping = {
    SURGERY: "bg-main-lighter",
    MEDICATION: "bg-main-lighter",
    VACCINATION: "bg-main-lighter",
    FOOD: "bg-gray-500",
    GENERAL_HEALTH: "bg-gray-500",
    RECORDING_CONSENT: "bg-gray-500",
    APPROVE: "bg-green-500",
    DECLINE: "bg-red-500",
    OTHER: "bg-gray-500",
} as const

interface ConsentIconWithNameComponentProps {
    consentType: ConsentType;
}
  
const ConsentIconWithNameComponent: React.FC<ConsentIconWithNameComponentProps> = ({ consentType }) => {
    const Icon = iconConsentMapping[consentType] || iconConsentMapping.OTHER;

    // Converts consentType to "Word Word" format
    const formatConsentTypeName = (consentType: ConsentType): string => {
        return consentType
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/\b(\w)/g, char => char.toUpperCase()); // Capitalize first letter of each word
    };

    return (
        <div className="flex items-center space-x-2">
            <div className='h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-500'>
                {Icon}
            </div>
            <span>{formatConsentTypeName(consentType)}</span>
        </div>
    );
};

// Main component to display all consent types
export const AllConsentTypes: React.FC = () => {
    return (
      <div className='grid grid-cols-2 sm:grid-cols-1 gap-y-3'>
        {Object.keys(iconConsentMapping).map((consentType) => (
          <ConsentIconWithNameComponent key={consentType} consentType={consentType as ConsentType} />
        ))}
      </div>
    );
  };