import { fetchTempalteById } from "../ServerActions";
import { TemplateObject } from "./TemplateObject";

export async function getTemplateName(templateId: string, jwt: string) {
  let template = await fetchTempalteById(templateId, jwt);
  return template["template"]["name"];
}

// queryTemplateId is expected to be the "template_id" field from the sessinos object: format `{organization}/{id}`
export function getTemplateFromListById(
  templates: TemplateObject[],
  queryTemplateId: string | undefined
) {
  if (!queryTemplateId) return undefined

  const queryTemplateIdSplit = queryTemplateId.split("/");
  const organization = queryTemplateIdSplit[0];
  const id = queryTemplateIdSplit[1];
  return templates.find((t) => t.organization === organization && t.id === id);
}
